@import "site-ie.1.css";
@media only screen and (min-width:992px) {
  .gallery_block.clicked .gallery_block_media:after,
  .gallery_block.toggle_active .gallery_block_media:after,
  .no-touchevents .gallery_block:focus .gallery_block_media:after,
  .no-touchevents .gallery_block:hover .gallery_block_media:after {
    opacity: 0;
    visibility: hidden;
  }
}

.gallery_block_bg_fallback {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  filter: url(#svg_blur);
}

.gallery_block_background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gallery_block_content {
  position: relative;
  padding: 70px 15px;
}

@media only screen and (max-width:767px) {
  .gallery_block_content {
    max-width: 400px;
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .gallery_block_content {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width:992px) {
  .gallery_block_content {
    opacity: 1;
    padding: 120px 25px;
    transition: opacity .5s,visibility .5s;
    visibility: visible;
  }
}

@media only screen and (min-width:1200px) {
  .gallery_block_content {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    padding: 200px 56px;
  }
}

@media only screen and (min-width:992px) {
  .gallery_block.clicked .gallery_block_content,
  .gallery_block.toggle_active .gallery_block_content,
  .no-touchevents .gallery_block:focus .gallery_block_content,
  .no-touchevents .gallery_block:hover .gallery_block_content {
    opacity: 0;
    visibility: hidden;
  }
}

.gallery_block_meta {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
}

@media only screen and (max-width:767px) {
  .gallery_block_meta {
    text-transform: uppercase;
  }
}

@media only screen and (min-width:768px) {
  .gallery_block_meta {
    font-size: 16px;
    font-size: 1rem;
  }
}

.no-js .gallery_block_meta {
  display: none!important;
  visibility: hidden;
}

.gallery_block_count {
  display: inline-block;
  padding: 2px 20px 2px 16px;
}

.gallery_block_count:first-child {
  padding-left: 0;
}

.gallery_block_count:not(:last-child) {
  border-right: 1px solid #fff;
}

.gallery_block_count .icon {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.gallery_block_count .symbol {
  fill: #fff;
}

.gallery_block_count.image .symbol_image {
  width: 14px;
  height: 11px;
}

.gallery_block_count.video .symbol_video {
  width: 14px;
  height: 9px;
}

.gallery_block_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 6px 0 10px;
}

@media only screen and (min-width:768px) {
  .gallery_block_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

@media only screen and (max-width:767px) {
  .gallery_block_title {
    font-size: 32px;
    font-size: 2rem;
  }
}

.gallery_block_description {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  margin: 10px 0 20px;
}

@media only screen and (min-width:768px) {
  .gallery_block_description {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

@media only screen and (max-width:767px) {
  .gallery_block_description {
    display: none;
  }
}

@media only screen and (max-width:767px) {
  .no-js .gallery_block_description {
    display: block;
  }
}

.gallery_block_description p {
  margin: 0;
}

@media only screen and (min-width:992px) {
  .no-touchevents .gallery_block_footer {
    display: none;
  }
}

@media (pointer:coarse) {
  .no-touchevents .gallery_block_footer {
    display: block;
  }
}

.no-js .gallery_block_footer {
  display: none!important;
  visibility: hidden;
}

.gallery_block_button {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color .25s,color .25s;
}

@media only screen and (max-width:767px) {
  .gallery_block_button {
    display: block;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-width:768px) {
  .gallery_block_button {
    display: inline-block;
  }
}

.gallery_block_button:focus,
.gallery_block_button:hover {
  color: #fff;
}

.gallery_block_more {
  position: relative;
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  display: block;
  cursor: pointer;
  overflow: visible;
  text-align: center;
  transition: background .25s,color .25s,opacity .5s,visibility .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  background-color: #fff;
  color: #333;
  opacity: 0;
  visibility: hidden;
}

.gallery_block_more:focus .icon:before,
.gallery_block_more:hover .icon:before {
  animation: pulse .8s forwards;
}

@media only screen and (max-width:991px) {
  .gallery_block_more {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:992px) {
  .touchevents .gallery_block_more {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:992px) {
  .no-touchevents .gallery_block_more {
    opacity: 0;
    pointer-events: none;
    visibility: visible;
  }
}

@media only screen and (min-width:992px) {
  .gallery_block.clicked .gallery_block_more,
  .gallery_block.toggle_active .gallery_block_more,
  .no-touchevents .gallery_block:focus .gallery_block_more,
  .no-touchevents .gallery_block:hover .gallery_block_more {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
}

.gallery_block_more:focus,
.gallery_block_more:hover {
  background-color: #C8102E;
  color: #fff;
}

.gallery_block_more_inner .icon {
  position: absolute;
  width: 36px;
  height: 36px;
  display: block;
  top: -18px;
  left: -26px;
  border-radius: 100%;
  opacity: 1;
  -ms-transform: scale(1);
  transform: scale(1);
  transition: opacity .25s,transform .25s;
}

.gallery_block_more_inner .icon:before {
  width: 36px;
  height: 36px;
  display: block;
  border-radius: 100%;
  content: "";
  opacity: 1;
  -ms-transform: scale(1);
  transform: scale(1);
  transition: opacity .25s,transform .25s;
}

.gallery_block_more_inner .symbol_plus {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 18px;
  height: 18px;
}

.gallery_block_more_inner .icon {
  background-color: #C8102E;
}

.gallery_block_more_inner .icon:before {
  background-color: #C8102E;
}

.gallery_block_more_inner .symbol_plus {
  fill: #fff;
}

.gallery_block:nth-child(1) .gallery_block_media:after {
  background-color: #C8102E;
}

.gallery_block:nth-child(1) .gallery_block_button:focus,
.gallery_block:nth-child(1) .gallery_block_button:hover {
  background-color: #264db6;
}

.gallery_block:nth-child(2) .gallery_block_media:after {
  background-color: #072f4f;
}

.gallery_block:nth-child(2) .gallery_block_button:focus,
.gallery_block:nth-child(2) .gallery_block_button:hover {
  background-color: #C8102E;
}

.gallery_block:nth-child(3) .gallery_block_media:after {
  background-color: #264db6;
}

.gallery_block:nth-child(3) .gallery_block_button:focus,
.gallery_block:nth-child(3) .gallery_block_button:hover {
  background-color: #072f4f;
}

body.lightbox_lock,
html.lightbox_lock {
  overflow: hidden!important;
}

.gallery_lightbox {
  position: fixed;
  display: block;
  min-height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  background-color: #fff;
  opacity: 0;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: none;
  transition: opacity .5s,visibility .5s;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
}

[data-whatinput=mouse] .gallery_lightbox:focus,
[data-whatinput=touch] .gallery_lightbox:focus {
  outline: 0;
}

.lightbox_lock .gallery_lightbox {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.gallery_lightbox_close {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 40px;
  z-index: 10;
  background-color: #C8102E;
  border-radius: 100%;
  cursor: pointer;
  transition: background .25s;
}

.gallery_lightbox_close:focus,
.gallery_lightbox_close:hover {
  background-color: #C8102E;
}

.gallery_lightbox_close .symbol {
  width: 20px;
  height: 20px;
}

.gallery_lightbox_close .symbol_close {
  fill: #fff;
}

.gallery_lightbox_inner {
  min-height: calc(100vh - 15px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  pointer-events: none;
}

.lightbox_lock .gallery_lightbox_inner {
  pointer-events: auto;
}

.gallery_lightbox_content {
  opacity: 1;
  pointer-events: none;
  transition: opacity .25s,visibility .25s;
  visibility: visible;
}

.gallery_lightbox_content.loading {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.lightbox_lock .gallery_lightbox_content {
  pointer-events: auto;
}

.gallery_lightbox_header {
  margin: 20px 0;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_header {
    margin-bottom: 35px;
  }
}

.gallery_lightbox_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 0 0 10px;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_title {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.gallery_lightbox_meta {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_meta {
    display: inline-block;
  }
}

.gallery_lightbox_count {
  position: relative;
  padding: 2px 14px 2px 10px;
  display: inline-block;
}

.gallery_lightbox_count:first-child {
  padding-left: 0;
}

.gallery_lightbox_count:last-child {
  padding-right: 0;
}

.gallery_lightbox_count:not(:last-child):after {
  position: absolute;
  display: block;
  width: 1px;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  content: "";
  opacity: .3;
}

.gallery_lightbox_count .icon {
  position: relative;
  top: -2px;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_count .icon {
    margin-right: 4px;
  }
}

.gallery_lightbox_count .symbol {
  fill: #072f4f;
}

.gallery_lightbox_count .symbol_image {
  width: 14px;
  height: 11px;
}

.gallery_lightbox_count .symbol_video {
  width: 14px;
  height: 9px;
}

.gallery_lightbox_item {
  margin-bottom: 30px;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_item {
    margin-bottom: 55px;
  }
}

.gallery_lightbox_item.orientation_portrait {
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width:992px) {
  .gallery_lightbox_item.orientation_portrait {
    max-width: 75%;
  }
}

.gallery_lightbox_figure {
  display: block;
}

.gallery_lightbox_figure picture {
  display: block;
}

.gallery_lightbox_figure img {
  display: block;
  width: 100%;
}

.gallery_lightbox_video {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
}

.gallery_lightbox_video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery_lightbox_image {
  border-radius: 5px;
}

.gallery_lightbox_caption {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  margin-top: 10px;
}

.gallery_lightbox_caption p {
  margin: 0;
}

.gallery_lightbox_controls {
  margin: 30px 0;
}

.gallery_lightbox_controls:after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_controls {
    margin: 50px 0;
  }
}

.gallery_lightbox_control {
  cursor: pointer;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_control {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
  }
}

.gallery_lightbox_control .icon {
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  background-color: #C8102E;
  border-radius: 5px;
  transition: background .25s;
}

@media only screen and (min-width:768px) {
  .gallery_lightbox_control .icon {
    width: 80px;
    height: 80px;
  }
}

.gallery_lightbox_control:focus .icon,
.gallery_lightbox_control:hover .icon {
  background-color: #C8102E;
}

.gallery_lightbox_control .symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 16px;
  height: 24px;
  fill: #fff;
}

.gallery_lightbox_control .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  display: inline-block;
  color: #333;
  padding: 20px;
}

@media only screen and (max-width:767px) {
  .gallery_lightbox_control .label {
    width: 1px;
    height: 1px;
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
  }

  .gallery_lightbox_control .label.focusable:active,
  .gallery_lightbox_control .label.focusable:focus {
    width: auto;
    height: auto;
    position: static;
    clip: auto;
    margin: 0;
    overflow: visible;
  }

  .gallery_lightbox_control .label.focusable:active,
  .gallery_lightbox_control .label.focusable:focus {
    width: auto;
    height: auto;
    position: static;
    clip: auto;
    margin: 0;
    overflow: visible;
  }
}

.gallery_lightbox_control.previous {
  float: left;
}

.gallery_lightbox_control.previous .icon {
  float: left;
}

.gallery_lightbox_control.next {
  float: right;
}

.gallery_lightbox_control.next .icon {
  float: right;
}

.gallery_lightbox_return {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.gallery_lightbox_return.focusable:active,
.gallery_lightbox_return.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.gallery_lightbox_return.focusable:active,
.gallery_lightbox_return.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.featured_news_block {
  padding: 40px 0;
  position: relative;
  background: #fff;
}

@media only screen and (min-width:768px) {
  .featured_news_block {
    padding: 55px 0;
  }
}

@media only screen and (min-width:1200px) {
  .featured_news_block {
    padding: 75px 0;
  }
}

@supports (display:flex) {
@media only screen and (min-width:768px) {
    .featured_news_block.size_full_height {
      height: 100px;
      min-height: 100vh;
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
    }
}

@media screen and (min-width:500px) and (max-height:799px) {
    .featured_news_block.size_full_height {
      height: auto;
    }
}

@media only screen and (max-width:1199px) {
    .featured_news_block.size_full_height {
      max-height: 1000px;
    }
}
}

.featured_news_header {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 24px;
  padding-bottom: 0;
}

@media print {
  .featured_news_header {
    padding-bottom: 0;
  }
}

.featured_news_header:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #999;
  background-color: rgba(153,153,153,.3);
  content: "";
}

@media only screen and (min-width:768px) {
  .featured_news_header:after {
    display: none!important;
    visibility: hidden;
  }
}

.featured_news_header:after {
  display: none;
}

.featured_news_header_group {
  text-align: center;
}

@media only screen and (min-width:768px) {
  .featured_news_header_group {
    overflow: hidden;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width:768px) {
  .featured_news_header_group {
    text-align: left;
  }
}

.featured_news_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .featured_news_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) {
  .featured_news_title {
    font-size: 48px;
    line-height: 1;
  }
}

.featured_news_all {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
  line-height: 1.3;
  margin: 10px 0;
}

@media print {
  .featured_news_all {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .featured_news_all {
    font-size: 18px;
    font-size: 1.125rem;
    float: right;
    margin: 0;
  }
}

@media only screen and (min-width:768px) {
  .featured_news_all {
    float: none;
    margin-top: 27px;
  }
}

.featured_news_all_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  display: none;
  background: #C8102E;
  color: #fff;
  margin: 0;
  transition: background-color .25s ease;
}

.featured_news_all_mobile .featured_news_all_link {
  display: inline-block;
  margin-top: 18px;
}

@media only screen and (min-width:768px) {
  .featured_news_all_mobile .featured_news_all_link {
    display: none;
  }
}

@media only screen and (min-width:768px) {
  .featured_news_all_link {
    display: inline-block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media only screen and (min-width:768px) {
  .featured_news_all_link:focus,
  .featured_news_all_link:hover {
    background: #333;
  }
}

.featured_news_all_mobile {
  text-align: center;
}

.news_card_wrap {
  margin-bottom: 25px;
}

.news_card {
  overflow: hidden;
  position: relative;
  background-color: #C8102E;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
}

@media print {
  .news_card {
    border: 1px solid;
  }
}

@media print and (min-width:1200px),only screen and (min-width:1200px) {
  .news_card {
    margin-right: 70px;
  }
}

@media print and (min-width:992px),only screen and (min-width:992px) {
  .news_card_inner {
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.news_card_figure {
  display: block;
  min-height: 150px;
  background-color: #000;
}

.news_card_figure picture {
  display: block;
}

.news_card_figure img {
  display: block;
  width: 100%;
}

@media print and (min-width:992px),only screen and (min-width:992px) {
  .news_card_figure {
    float: left;
    width: 100%;
  }
}

.news_card_content {
  padding: 24px 15px 30px 15px;
}

@media print and (min-width:992px),only screen and (min-width:992px) {
  .news_card_content {
    float: right;
    width: 100%;
    padding: 30px 50px;
  }
}

@media print and (min-width:1200px),only screen and (min-width:1200px) {
  .news_card_content {
    padding: 40px;
  }
}

.news_card_label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  display: block;
  color: #fff;
}

@media print {
  .news_card_label {
    padding: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:992px) {
  .news_card_label {
    display: inline-block;
    margin-bottom: 0;
  }
}

.news_card_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 10px 0 0;
}

@media only screen and (min-width:768px) {
  .news_card_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.news_card_description {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  margin: 4px 0;
}

@media only screen and (min-width:768px) {
  .news_card_description {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.news_card_description p {
  margin: 0;
}

.news_card_links {
  margin-top: 12px;
}

@media only screen and (min-width:768px) {
  .news_card_links {
    margin-top: 24px;
  }
}

@media print {
  .news_card_links {
    display: none!important;
    visibility: hidden;
  }
}

.news_card_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  background-color: #fff;
  color: #333;
  transition: background-color .25s;
}

@media only screen and (min-width:768px) {
  .news_card_link {
    display: inline-block;
  }
}

.news_card_link:focus,
.news_card_link:hover {
  background-color: rgba(255,255,255,.6);
}

.news_list_items {
  width: 95%;
  margin: 0;
}

@media screen and (min-width:500px) {
  .news_list_items {
    width: 100%;
  }
}

.news_list_items .carousel_count {
  width: 105%;
  margin-top: 30px;
}

@media screen and (min-width:500px) {
  .news_list_items .carousel_count {
    width: 100%;
  }
}

.news_list_items_inner .fs-carousel-viewport {
  max-width: 400px;
}

@media only screen and (max-width:767px) {
  .news_list_items_inner .fs-carousel-item {
    opacity: .5;
    transition: opacity .5s;
  }
}

.news_list_items_inner .fs-carousel-item:not(:last-child) {
  margin-right: 0;
  padding-right: 20px;
}

.news_list_items_inner .fs-carousel-visible {
  opacity: 1;
}

@media print {
  .news_item {
    width: auto!important;
    height: auto!important;
  }
}

@media print,only screen and (min-width:768px) {
  .news_item {
    border-bottom: 1px solid #a7a8aa;
    padding: 24px 0;
  }
}

@media print,only screen and (min-width:768px) {
  .news_item:first-child {
    padding-top: 0;
  }
}

.no-js .news_item {
  border-bottom: 1px solid #a7a8aa;
  padding: 24px 0;
}

.no-js .news_item:first-child {
  padding-top: 0;
}

.no-js .news_item_inner {
  max-width: 400px;
}

.news_item_label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  background-color: transparent!important;
  color: #62707B!important;
  margin-bottom: 16px;
}

@media print {
  .news_item_label {
    padding: 0;
  }
}

.news_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.33333333;
  margin: 0 0 16px;
}

@media only screen and (min-width:768px) {
  .news_item_title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.14285714;
  }
}

@media print {
  .news_item_title {
    margin: 0;
  }
}

@media print {
  .news_item_links {
    display: none!important;
    visibility: hidden;
  }
}

.news_item_link {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  color: #333;
}

.news_item_link .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .news_item_link .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.news_item_link .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.news_item_link .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.news_item_link .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.news_item_link:focus .icon,
.news_item_link:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.news_item_link .symbol {
  fill: #C8102E;
}

@media only screen and (max-width:767px) {
  .featured_news_block {
    overflow: hidden;
  }
}

.news_card.theme_red_brand {
  background-color: #C8102E;
  color: #fff;
}

.news_card.theme_red_brand .news_card_label {
  color: #C8102E;
}

.news_card.theme_red_light {
  background-color: #930a28;
  color: #fff;
}

.news_card.theme_red_light .news_card_label {
  color: #930a28;
}

.news_card.theme_blue_dark {
  background-color: #072f4f;
  color: #fff;
}

.news_card.theme_blue_dark .news_card_label {
  color: #333;
}

.news_card.theme_blue_light {
  background-color: #264db6;
  color: #fff;
}

.news_card.theme_blue_light .news_card_label {
  color: #264db6;
}

.news_card.theme_green_dark {
  background-color: #02a6ab;
  color: #333;
}

.news_card.theme_green_dark .news_card_label {
  color: #333;
}

.news_card.theme_green_light {
  background-color: #5fc64d;
  color: #333;
}

.news_card.theme_green_light .news_card_label {
  background-color: #072f4f;
  color: #5fc64d;
}

.news_card.theme_pink {
  background-color: #991e66;
  color: #fff;
}

.news_card.theme_pink .news_card_label {
  background-color: #fff;
  color: #991e66;
}

.news_card.theme_yellow {
  background-color: #f4da40;
  color: #333;
}

.news_card.theme_yellow .news_card_label {
  background-color: #072f4f;
  color: #f4da40;
}

.news_card.theme_orange {
  background-color: #ef562d;
  color: #333;
}

.news_card.theme_orange .news_card_label {
  background-color: #072f4f;
  color: #ef562d;
}

.news_card.theme_gray_dark {
  background-color: #333;
  color: #fff;
}

.news_card.theme_gray_dark .news_card_label {
  color: #333;
}

.news_card.theme_gray_medium {
  background-color: #a7a8aa;
  color: #333;
}

.news_card.theme_gray_medium .news_card_label {
  color: #333;
}

.news_card.theme_orange_bright {
  background-color: #ef562d;
  color: #333;
}

.news_card.theme_orange_bright .news_card_label {
  color: #333;
}

.news_card.theme_teal_accent {
  background-color: #02A6AB;
  color: #333;
}

.news_card.theme_teal_accent .news_card_label {
  color: #333;
}

.news_card.theme_red_dark {
  background-color: #930a28;
  color: #fff;
}

.news_card.theme_red_dark .news_card_label {
  color: #930a28;
}

.news_item.theme_red_brand .news_item_label {
  background-color: #C8102E;
  color: #fff;
}

.news_item.theme_red_light .news_item_label {
  background-color: #930a28;
  color: #fff;
}

.news_item.theme_blue_dark .news_item_label {
  background-color: #072f4f;
  color: #fff;
}

.news_item.theme_blue_light .news_item_label {
  background-color: #264db6;
  color: #fff;
}

.news_item.theme_green_dark .news_item_label {
  background-color: #02a6ab;
  color: #333;
}

.news_item.theme_green_light .news_item_label {
  background-color: #5fc64d;
  color: #333;
}

.news_item.theme_pink .news_item_label {
  background-color: #991e66;
  color: #fff;
}

.news_item.theme_yellow .news_item_label {
  background-color: #f4da40;
  color: #333;
}

.news_item.theme_orange .news_item_label {
  background-color: #ef562d;
  color: #333;
}

.news_item.theme_gray_dark .news_item_label {
  background-color: #333;
  color: #fff;
}

.news_item.theme_gray_medium .news_item_label {
  background-color: #a7a8aa;
  color: #333;
}

.news_item.theme_orange_bright .news_item_label {
  background-color: #ef562d;
  color: #333;
}

.news_item.theme_teal_accent .news_item_label {
  background-color: #02A6AB;
  color: #333;
}

.news_item.theme_red_dark .news_item_label {
  background-color: #930a28;
  color: #fff;
}

.home_cta {
  position: relative;
}

.home_cta.theme_blue {
  background: #264db6;
}

.home_cta.theme_accent_pink {
  background: #991e66;
}

.home_cta.theme_maroon {
  background: #930a28;
}

.home_cta.theme_red {
  background: #C8102E;
}

.home_cta.theme_dark_blue {
  background: #072f4f;
}

.home_cta.theme_grey {
  background: #333;
}

.home_cta_inner {
  color: #fff;
  padding: 25px 0;
}

@media screen and (min-width:600px) {
  .home_cta_inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.home_cta_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 1.33333333;
}

@media screen and (min-width:600px) {
  .home_cta_title {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 20px;
  }
}

.home_cta_description {
  display: block;
  line-height: 1.5;
  margin-top: 6px;
}

@media screen and (min-width:600px) {
  .home_cta_description {
    margin-right: 20px;
    margin-top: 0;
  }
}

.home_cta_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #fff;
  color: #333;
  margin: 15px 0 0;
  transition: background-color .25s ease,color .25s ease;
}

@media screen and (min-width:600px) {
  .home_cta_link {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-top: 0;
  }
}

@media only screen and (min-width:768px) {
  .home_cta_link:focus,
  .home_cta_link:hover {
    background: #333;
    color: #fff;
  }
}

.home_programs_v1 {
  position: relative;
  overflow: hidden;
}

.home_programs_v1.theme_blue {
  background: #0B263B;
  color: #fff;
}

.home_programs_v1.theme_red {
  background: #C8102E;
  color: #fff;
}

.home_programs_v1_figure {
  width: 100%;
}

.home_programs_v1_figure img {
  width: 100%;
}

@media only screen and (min-width:992px) {
  .home_programs_v1_figure {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: translateX(40px);
    transform: translateX(40px);
    z-index: 2;
  }
}

.home_programs_v1_background {
  padding-top: 56.25%;
}

@media only screen and (min-width:992px) {
  .home_programs_v1_background {
    width: auto;
    height: 100%;
    padding-top: 0;
  }
}

.home_programs_v1_header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 38px 0 46px;
}

@media screen and (min-width:600px) {
  .home_programs_v1_header {
    padding: 45px 0 46px;
  }
}

@media only screen and (min-width:992px) {
  .home_programs_v1_header {
    padding: 70px 0 46px;
  }
}

@media only screen and (min-width:1200px) {
  .home_programs_v1_header {
    padding: 90px 0 46px;
  }
}

.home_programs_v1_header_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 12px 0 12px;
  -ms-flex-order: 1;
  order: 1;
}

@media only screen and (min-width:768px) {
  .home_programs_v1_header_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) {
  .home_programs_v1_header_title {
    font-size: 48px;
  }
}

.home_programs_v1_header_subhead {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-size: .875rem;
  letter-spacing: 1px;
  line-height: 1;
  -ms-flex-order: 0;
  order: 0;
  text-transform: uppercase;
}

.home_programs_v1_header_description {
  line-height: 1.77777778;
  -ms-flex-order: 2;
  order: 2;
}

@media only screen and (min-width:768px) {
  .home_programs_v1_header_description {
    font-size: 18px;
  }
}

.home_programs_v1_header_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  -ms-flex-order: 3;
  order: 3;
  margin: 26px 0 0;
  transition: background-color .25s ease,color .25s ease;
}

.home_programs_v1.theme_blue .home_programs_v1_header_link {
  background: #C8102E;
  color: #fff;
}

@media only screen and (min-width:768px) {
  .home_programs_v1.theme_blue .home_programs_v1_header_link:focus,
  .home_programs_v1.theme_blue .home_programs_v1_header_link:hover {
    background: #fff;
    color: #333;
  }
}

.home_programs_v1.theme_red .home_programs_v1_header_link {
  background: #fff;
  color: #C8102E;
}

@media only screen and (min-width:768px) {
  .home_programs_v1.theme_red .home_programs_v1_header_link:focus,
  .home_programs_v1.theme_red .home_programs_v1_header_link:hover {
    background: #333;
    color: #fff;
  }
}

.home_programs_v1_body_heading {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-size: .875rem;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
}

.home_programs_v1_areas {
  list-style: none;
  margin: 0;
  padding: 18px 0 48px;
}

@media screen and (min-width:600px) {
  .home_programs_v1_areas {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width:1200px) {
  .home_programs_v1_areas {
    padding-bottom: 55px;
  }
}

.home_programs_v1_area_item {
  border-bottom: 1px solid rgba(203,196,188,.2);
  padding: 12px 0;
}

.home_programs_v1_area_item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

@media screen and (min-width:600px) {
  .home_programs_v1_area_item:last-child {
    border-bottom: 1px solid rgba(203,196,188,.2);
    padding: 12px 0;
  }
}

@media screen and (min-width:600px) {
  .home_programs_v1_area_item {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .home_programs_v1_area_item:nth-of-type(2n) {
    margin-right: 0;
  }
}

.home_programs_v1_area_item_link {
  color: #fff;
  font-size: 16px;
}

@media only screen and (min-width:768px) {
  .home_programs_v1_area_item_link:focus .home_programs_v1_area_item_link_icon,
  .home_programs_v1_area_item_link:hover .home_programs_v1_area_item_link_icon {
    margin-left: 10px;
  }
}

.home_programs_v1_area_item_link_icon {
  margin-left: 6px;
  transition: margin-left .25s ease;
}

.home_programs_v1_area_item_link_icon .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.home_programs_v1.theme_blue .home_programs_v1_area_item_link_icon .symbol_arrow_right {
  fill: #C8102E;
}

.home_programs_v1.theme_red .home_programs_v1_area_item_link_icon .symbol_arrow_right {
  fill: #fff;
}

.home_programs_v1_explore {
  position: relative;
  padding: 35px 0;
}

@media screen and (min-width:600px) {
  .home_programs_v1_explore {
    padding: 35px 0 27px;
  }
}

.home_programs_v1_explore:before {
  width: 200vw;
  height: 100%;
  display: block;
  position: absolute;
  left: -100vw;
  top: 0;
  background: rgba(0,0,0,.2);
  content: "";
}

.home_programs_v1_explore_heading {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-size: .875rem;
  position: relative;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  z-index: 1;
}

.home_programs_v1_explore_links {
  position: relative;
  list-style: none;
  margin: 16px 0 0;
  padding: 0;
  z-index: 1;
}

@media screen and (min-width:600px) {
  .home_programs_v1_explore_links {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}

.home_programs_v1_explore_item {
  margin-bottom: 8px;
}

@media screen and (min-width:600px) {
  .home_programs_v1_explore_item {
    margin-right: 12px;
  }
}

.home_programs_v1_explore_item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width:600px) {
  .home_programs_v1_explore_item:last-child {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.home_programs_v1_explore_item_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
  margin: 0;
  transition: background-color .25s ease,color .25s ease;
}

.home_programs_v1.theme_blue .home_programs_v1_explore_item_link {
  background: #C8102E;
  color: #fff;
}

@media only screen and (min-width:768px) {
  .home_programs_v1.theme_blue .home_programs_v1_explore_item_link:focus,
  .home_programs_v1.theme_blue .home_programs_v1_explore_item_link:hover {
    background: #fff;
    color: #333;
  }
}

.home_programs_v1.theme_red .home_programs_v1_explore_item_link {
  background: #fff;
  color: #C8102E;
}

@media only screen and (min-width:768px) {
  .home_programs_v1.theme_red .home_programs_v1_explore_item_link:focus,
  .home_programs_v1.theme_red .home_programs_v1_explore_item_link:hover {
    background: #333;
    color: #fff;
  }
}

.home_programs_v2 {
  position: relative;
  color: #fff;
  padding: 40px 0;
}

@media only screen and (min-width:768px) {
  .home_programs_v2 {
    padding: 60px 0;
  }
}

@media only screen and (min-width:992px) {
  .home_programs_v2 {
    padding: 95px 0;
  }

  .home_programs_v2:after {
    width: 334px;
    height: 100%;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    content: "";
  }

@supports ((-webkit-clip-path: polygon(100% 0,100% 100%,0 100%,40% 30%,0 0)) or (clip-path: polygon(100% 0,100% 100%,0 100%,40% 30%,0 0))) {
    .home_programs_v2:after {
      display: block;
      -webkit-clip-path: polygon(100% 0,100% 100%,0 100%,40% 30%,0 0);
      clip-path: polygon(100% 0,100% 100%,0 100%,40% 30%,0 0);
    }
}
}

.home_programs_v2.theme_red {
  background: #c8102e;
}

@media only screen and (min-width:992px) {
  .home_programs_v2.theme_red:after {
    background: rgba(0,0,0,0) url(/_resources/images/programs_pattern.png);
    background-size: auto 100%;
  }
}

.home_programs_v2.theme_blue {
  background: #0b263b;
}

@media only screen and (min-width:992px) {
  .home_programs_v2.theme_blue:after {
    background: rgba(0,0,0,.6) url(/_resources/images/programs_pattern.png);
    background-size: auto 100%;
  }
}

.home_programs_v2_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .home_programs_v2_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) {
  .home_programs_v2_title {
    font-size: 48px;
  }
}

.home_programs_v2_description {
  display: block;
  line-height: 1.77777778;
  margin-top: 12px;
}

@media only screen and (min-width:768px) {
  .home_programs_v2_description {
    font-size: 18px;
  }
}

@media only screen and (min-width:992px) {
  .home_programs_v2_description {
    max-width: 620px;
  }
}

@media only screen and (min-width:1200px) {
  .home_programs_v2_description {
    max-width: 830px;
  }
}

.home_programs_v2_body {
  margin-top: 24px;
}

@media only screen and (min-width:992px) {
  .home_programs_v2_body {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 34px;
    z-index: 2;
  }
}

.home_programs_v2_body_item {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.home_programs_v2_body_item:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width:992px) {
  .home_programs_v2_body_item {
    width: calc(50% - 12px);
    margin-bottom: 0;
    margin-right: 24px;
  }

  .home_programs_v2_body_item:nth-of-type(2n) {
    margin-right: 0;
  }
}

.home_programs_v2_body_item_background {
  border-radius: 5px;
  padding-top: 56.25%;
}

.home_programs_v2_body_item_wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px;
}

.home_programs_v2_body_item_wrapper:after {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 0,#000 100%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  content: "";
}

@media screen and (min-width:400px) {
  .home_programs_v2_body_item_wrapper {
    padding: 20px;
  }
}

@media only screen and (min-width:768px) {
  .home_programs_v2_body_item_wrapper {
    padding: 40px;
  }
}

.home_programs_v2_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width:768px) {
  .home_programs_v2_item_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

.home_programs_v2_item_links {
  position: relative;
  margin-top: 6px;
  z-index: 1;
}

@media only screen and (min-width:768px) {
  .home_programs_v2_item_links {
    margin-top: 16px;
  }
}

.home_programs_v2_item_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #C8102E;
  color: #fff;
  margin-bottom: 6px;
  transition: background-color .25s ease,color .25s ease;
}

.home_programs_v2_item_link:last-child {
  margin-bottom: 0;
}

@media screen and (min-width:400px) {
  .home_programs_v2_item_link {
    margin-bottom: 0;
  }
}

@media only screen and (min-width:768px) {
  .home_programs_v2_item_link:focus,
  .home_programs_v2_item_link:hover {
    background: #fff;
    color: #333;
  }
}

.load_more {
  margin: 10px 0 40px;
}

@media only screen and (min-width:768px) {
  .load_more {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width:1200px) {
  .load_more {
    margin-bottom: 90px;
  }
}

@media print {
  .load_more {
    display: none!important;
    visibility: hidden;
  }
}

.no-js .load_more {
  display: none!important;
  visibility: hidden;
}

.load_more_button {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  display: block;
  text-align: center;
  width: 100%;
  background-color: #C8102E;
  cursor: pointer;
  color: #fff;
  transition: background-color .25s;
}

@media only screen and (min-width:992px) {
  .load_more_button {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.load_more_button .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .load_more_button .label {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.load_more_button:focus,
.load_more_button:hover {
  background-color: #333;
}

.media_gallery {
  position: relative;
  background: #072f4f;
  color: #fff;
  overflow: hidden;
  padding: 40px 0;
}

.media_gallery.theme_blue {
  background: #264db6;
}

.media_gallery.theme_accent_pink {
  background: #991e66;
}

.media_gallery.theme_maroon {
  background: #930a28;
}

.media_gallery.theme_red {
  background: #C8102E;
}

.media_gallery.theme_dark_blue {
  background: #072f4f;
}

.media_gallery.theme_grey {
  background: #333;
}

@media only screen and (min-width:768px) {
  .media_gallery {
    padding: 60px 0;
  }
}

@media only screen and (min-width:992px) {
  .media_gallery {
    padding: 82px 0;
  }
}

.media_gallery .carousel_count {
  margin-top: 20px;
}

.media_gallery_header_text {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0;
  text-align: center;
}

@media only screen and (min-width:768px) {
  .media_gallery_header_text {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) {
  .media_gallery_header_text {
    font-size: 48px;
    line-height: 1.20833333;
    text-align: left;
  }
}

.media_gallery_items {
  margin-top: 22px;
}

@media only screen and (min-width:768px) {
  .media_gallery_items {
    margin-top: 37px;
  }
}

@media only screen and (min-width:992px) {
  .media_gallery_items {
    margin-top: 48px;
  }
}

.media_gallery_item {
  margin-right: 5px;
  transition: opacity .25s ease;
}

.media_gallery_item.fs-carousel-item_next,
.media_gallery_item.fs-carousel-item_previous {
  opacity: .5;
}

.media_gallery_item.fs-carousel-item_next .media_gallery_item_caption,
.media_gallery_item.fs-carousel-item_previous .media_gallery_item_caption {
  opacity: 0;
}

@media only screen and (min-width:768px) {
  .media_gallery_item {
    margin-right: 20px;
  }
}

.media_gallery_item_figure {
  position: relative;
}

.media_gallery_video_item_trigger {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.6);
  border-radius: 5px;
  transition: background-color .25s ease;
}

.fs-carousel-item_previous .media_gallery_video_item_trigger,
.media_gallery_video_item_trigger .fs-carousel-item_next {
  pointer-events: none;
}

@media only screen and (min-width:768px) {
  .media_gallery_video_item_trigger:focus,
  .media_gallery_video_item_trigger:hover {
    background: rgba(0,0,0,.3);
  }
}

.media_gallery_video_item_play {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.media_gallery_video_item_play_icon .symbol_play {
  width: 32px;
  height: 32px;
  fill: #fff;
}

@media only screen and (min-width:768px) {
  .media_gallery_video_item_play_icon .symbol_play {
    width: 40px;
    height: 40px;
  }
}

.media_gallery_video_item_play_label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.media_gallery_video_item_play_label.focusable:active,
.media_gallery_video_item_play_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.media_gallery_video_item_play_label.focusable:active,
.media_gallery_video_item_play_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.video_item_iframe_wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity .25s ease;
  z-index: 1;
}

.video_item_iframe_wrapper.loaded-media {
  opacity: 1;
}

.video_item_iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video_item_close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
}

.media_gallery_item_image {
  width: 100%;
  border-radius: 5px;
}

.media_gallery_item_caption {
  display: block;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 14px;
  transition: opacity .5s ease;
}

.media_gallery_item_caption a {
  color: #fff;
  text-decoration: underline;
}

@media only screen and (min-width:768px) {
  .media_gallery_item_caption a:focus,
  .media_gallery_item_caption a:hover {
    text-decoration: underline;
  }
}

.media_gallery_controls {
  pointer-events: none;
}

.media_gallery_controls.fs-carousel-controls_custom.fs-carousel-visible {
  display: none;
}

@media only screen and (min-width:768px) {
  .media_gallery_controls {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-transform: translateY(40%);
    transform: translateY(40%);
  }

  .media_gallery_controls.fs-carousel-controls_custom.fs-carousel-visible {
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width:992px) {
  .media_gallery_controls {
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}

@media only screen and (min-width:1200px) {
  .media_gallery_controls {
    -ms-transform: translateY(10%);
    transform: translateY(10%);
  }
}

.media_gallery_controls_prev {
  width: 60px;
  height: 60px;
  background: #D31243;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease;
}

@media only screen and (min-width:992px) {
  .media_gallery_controls_prev {
    width: 80px;
    height: 80px;
  }
}

.media_gallery_controls_prev .media_gallery_control_label {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.media_gallery_controls_prev .icon .symbol {
  width: 12px;
  height: 18px;
  color: #fff;
}

@media only screen and (min-width:992px) {
  .media_gallery_controls_prev .icon .symbol {
    width: 16px;
    height: 24px;
  }
}

.media_gallery_controls_prev:focus,
.media_gallery_controls_prev:hover {
  background: #333;
}

.media_gallery_controls_prev.fs-carousel-visible {
  opacity: 1;
  pointer-events: auto;
}

.media_gallery_controls_next {
  width: 60px;
  height: 60px;
  background: #D31243;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease;
}

@media only screen and (min-width:992px) {
  .media_gallery_controls_next {
    width: 80px;
    height: 80px;
  }
}

.media_gallery_controls_next .media_gallery_control_label {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.media_gallery_controls_next .icon .symbol {
  width: 12px;
  height: 18px;
  color: #fff;
}

@media only screen and (min-width:992px) {
  .media_gallery_controls_next .icon .symbol {
    width: 16px;
    height: 24px;
  }
}

.media_gallery_controls_next:focus,
.media_gallery_controls_next:hover {
  background: #333;
}

.media_gallery_controls_next.fs-carousel-visible {
  opacity: 1;
  pointer-events: auto;
}

.news_row {
  padding: 20px 0;
}

@media only screen and (min-width:768px) {
  .news_row {
    padding: 40px 0;
  }
}

@media only screen and (min-width:1200px) {
  .news_row {
    padding: 50px 0;
  }
}

.news_row:not(:last-child) {
  border-bottom: 1px solid #a7a8aa;
}

.news_row:first-child {
  padding-top: 0;
}

.news_figure {
  display: block;
  float: right;
  overflow: hidden;
  width: 80px;
  border-radius: 5px;
  margin: 0 0 20px 20px;
}

.news_figure picture {
  display: block;
}

.news_figure img {
  display: block;
  width: 100%;
}

@media screen and (min-width:500px) {
  .news_figure {
    width: 100px;
  }
}

@media print,only screen and (min-width:768px) {
  .news_figure {
    width: 30%;
    margin-top: 10px;
  }
}

@media only screen and (min-width:992px) {
  .news_figure {
    width: 23%;
  }
}

@media only screen and (min-width:768px) {
  .news_wrapper {
    max-width: 74.5%;
  }
}

@media print,only screen and (min-width:768px) {
  .news_meta {
    margin-bottom: 20px;
  }
}

.news_label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  display: block;
  background-color: #C8102E;
  border-radius: 2px;
  color: #fff;
  margin: 0;
  padding: 7px 10px 6px;
}

@media print {
  .news_label {
    border: 1px solid #000;
  }
}

@media screen and (max-width:499px) {
  .news_label {
    float: left;
    margin: 0 0 10px;
  }
}

@media print,screen and (min-width:500px) {
  .news_label {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 16px;
  }
}

.news_date {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  margin: 0;
}

@media screen and (max-width:499px) {
  .news_date {
    clear: left;
    margin: 10px 0;
  }
}

@media print,screen and (min-width:500px) {
  .news_date {
    display: inline-block;
  }
}

.news_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 10px 0;
}

@media only screen and (min-width:768px) {
  .news_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.news_title a {
  color: inherit;
}

.news_title a:focus,
.news_title a:hover {
  color: #C8102E;
}

.news_description {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  margin: 14px 0;
}

@media only screen and (min-width:768px) {
  .news_description {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.news_description p {
  margin: 0;
}

@media print {
  .news_links {
    display: none!important;
    visibility: hidden;
  }
}

.news_link {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #333;
}

.news_link .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .news_link .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.news_link .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.news_link .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.news_link .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.news_link:focus .icon,
.news_link:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.news_link .symbol {
  fill: #C8102E;
}

.news_link:focus,
.news_link:hover {
  color: #C8102E;
}

.news_row.theme_blue_light .news_label {
  background-color: #264db6;
  color: #fff;
}

.news_row.theme_blue_dark .news_label {
  background-color: #072f4f;
  color: #fff;
}

.news_row.theme_red_brand .news_label {
  background-color: #C8102E;
  color: #fff;
}

.news_row.theme_red_light .news_label {
  background-color: #C8102E;
  color: #fff;
}

.news_row.theme_green_light .news_label {
  background-color: #5fc64d;
  color: #333;
}

.news_row.theme_green_dark .news_label {
  background-color: #02a6ab;
  color: #333;
}

.news_row.theme_pink .news_label {
  background-color: #991e66;
  color: #fff;
}

.news_row.theme_yellow .news_label {
  background-color: #f4da40;
  color: #333;
}

.news_row.theme_orange .news_label {
  background-color: #ef562d;
  color: #333;
}

.news_row.theme_gray_dark .news_label {
  background-color: #333;
  color: #fff;
}

.news_row.theme_gray_medium .news_label {
  background-color: #a7a8aa;
  color: #333;
}

.pathways_list {
  position: relative;
  background: #fff;
  color: #333;
  padding: 40px 0;
}

@media only screen and (min-width:768px) {
  .pathways_list {
    padding: 60px 0;
  }
}

@media only screen and (min-width:992px) {
  .pathways_list {
    padding: 82px 0;
  }
}

.pathways_list_header {
  margin-bottom: 14px;
}

@media only screen and (min-width:768px) {
  .pathways_list_header {
    margin-bottom: 26px;
  }
}

.pathways_list_header_text {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .pathways_list_header_text {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) {
  .pathways_list_header_text {
    font-size: 48px;
  }
}

@media only screen and (min-width:992px) {
  .pathways_list_items {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pathways_list_item {
  margin-bottom: 28px;
}

@media only screen and (min-width:992px) {
  .pathways_list_item {
    width: calc(33% - 22px);
    margin-bottom: 0;
    margin-right: 32px;
  }

  .pathways_list_item:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width:1200px) {
  .pathways_list_item {
    width: calc(33% - 52px);
    margin-right: 77px;
  }
}

.pathways_list_item_header {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  display: block;
  margin-bottom: 15px;
}

@media only screen and (min-width:768px) {
  .pathways_list_item_header {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.pathways_list_item_figure {
  display: none;
}

@media only screen and (min-width:992px) {
  .pathways_list_item_figure {
    display: block;
  }
}

.pathways_list_item_figure img {
  width: 100%;
  border-radius: 5px;
}

.pathways_list_links {
  border-top: 2px solid rgba(7,47,79,.2);
  list-style: none;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width:768px) {
  .pathways_list_links {
    margin: 14px 0 0;
  }
}

@media only screen and (min-width:992px) {
  .pathways_list_links {
    border-top: none;
  }
}

.pathways_list_links_item {
  border-bottom: 1px solid rgba(203,196,188,.2);
  padding: 14px 0;
}

.pathways_list_links_item_link {
  color: #000;
  font-size: 16px;
}

@media only screen and (min-width:768px) {
  .pathways_list_links_item_link:focus .pathways_list_links_item_link_icon,
  .pathways_list_links_item_link:hover .pathways_list_links_item_link_icon {
    margin-left: 10px;
  }
}

.pathways_list_links_item_link_icon {
  margin-left: 6px;
  transition: margin-left .25s ease;
}

.pathways_list_links_item_link_icon .symbol_arrow_right {
  width: 12px;
  height: 12px;
  fill: #C8102E;
}

.profiles_gallery {
  position: relative;
  background: #fff;
  overflow: hidden;
  padding: 40px 0;
}

@media only screen and (min-width:768px) {
  .profiles_gallery {
    padding: 60px 0;
  }
}

@media only screen and (min-width:992px) {
  .profiles_gallery {
    padding: 82px 0;
  }
}

.profiles_gallery .carousel_count {
  margin-top: 20px;
}

.profiles_gallery_header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.profiles_gallery_header.has_link {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_header.has_link {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (min-width:768px) {
  .profiles_gallery_header {
    margin-bottom: 45px;
  }
}

.profiles_gallery_header_text {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0;
  text-align: center;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_header_text {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) {
  .profiles_gallery_header_text {
    font-size: 48px;
    line-height: 1;
    text-align: left;
  }
}

@media only screen and (min-width:1200px) {
  .profiles_gallery_header_text {
    max-width: 725px;
  }
}

.profiles_gallery_header_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  display: none;
  background: #C8102E;
  color: #fff;
  margin: 0;
  transition: background-color .25s ease;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_header_link {
    display: block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media only screen and (min-width:768px) {
  .profiles_gallery_header_link:focus,
  .profiles_gallery_header_link:hover {
    background: #333;
  }
}

.profiles_gallery_item {
  margin-right: 5px;
  transition: opacity .25s ease;
}

.fs-carousel-enabled .profiles_gallery_item.fs-carousel-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profiles_gallery_item.fs-carousel-item_next,
.profiles_gallery_item.fs-carousel-item_previous {
  opacity: .5;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item {
    margin-right: 20px;
  }
}

@media only screen and (min-width:992px) {
  .profiles_gallery_item {
    margin-right: 40px;
  }
}

@media only screen and (min-width:1200px) {
  .profiles_gallery_item {
    margin-right: 50px;
  }
}

.profiles_gallery_item_picture img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.profiles_gallery_item_wrapper {
  background: #072f4f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  -ms-flex: 1;
  flex: 1;
  padding: 30px 15px;
}

.profiles_gallery_item_wrapper.theme_blue {
  background: #264db6;
}

.profiles_gallery_item_wrapper.theme_accent_pink {
  background: #991e66;
}

.profiles_gallery_item_wrapper.theme_maroon {
  background: #930a28;
}

.profiles_gallery_item_wrapper.theme_red {
  background: #C8102E;
}

.profiles_gallery_item_wrapper.theme_dark_blue {
  background: #072f4f;
}

.profiles_gallery_item_wrapper.theme_grey {
  background: #333;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_wrapper {
    padding: 45px;
  }
}

@media only screen and (min-width:992px) {
  .profiles_gallery_item_wrapper {
    padding: 45px 70px;
  }
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_wrapper.has_related_programs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.profiles_gallery_item_content {
  width: 100%;
  border-bottom: 1px solid rgba(204,204,204,.2);
  margin-bottom: 23px;
  padding-bottom: 23px;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_content {
    width: calc(100% - 160px);
    border-bottom: none;
    border-right: 1px solid rgba(204,204,204,.2);
    margin-bottom: 0;
    margin-right: 40px;
    padding-bottom: 0;
  }
}

@media only screen and (min-width:992px) {
  .profiles_gallery_item_content {
    width: calc(100% - 210px);
  }
}

.profiles_gallery_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  display: block;
  font-size: 24px;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_title {
    font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 1.2;
  }
}

.profiles_gallery_item_description {
  display: block;
  font-size: 16px;
  line-height: 1.625;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_description {
    font-size: 21px;
    line-height: 1.52380952;
    margin-top: 8px;
  }
}

.profiles_gallery_item_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #fff;
  color: #333;
  margin-bottom: 0;
  margin-top: 15px;
  transition: background-color .25s ease,color .25s ease;
}

.fs-carousel-item_next .profiles_gallery_item_link,
.fs-carousel-item_previous .profiles_gallery_item_link {
  pointer-events: none;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_link:focus,
  .profiles_gallery_item_link:hover {
    background: #C8102E;
    color: #fff;
  }

  .profiles_gallery_item_wrapper.theme_maroon .profiles_gallery_item_link:focus,
  .profiles_gallery_item_wrapper.theme_maroon .profiles_gallery_item_link:hover,
  .profiles_gallery_item_wrapper.theme_red .profiles_gallery_item_link:focus,
  .profiles_gallery_item_wrapper.theme_red .profiles_gallery_item_link:hover {
    background: #333;
  }
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_link {
    margin-top: 20px;
  }
}

.profiles_gallery_item_related_programs {
  width: 100%;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_related_programs {
    width: 200px;
  }
}

@media only screen and (min-width:992px) {
  .profiles_gallery_item_related_programs {
    width: 250px;
  }
}

.profiles_gallery_item_related_programs_label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.profiles_gallery_item_related_programs_list {
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_related_programs_list {
    margin-top: 20px;
  }
}

.profiles_gallery_item_related_programs_list_item {
  margin-bottom: 5px;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_related_programs_list_item {
    margin-bottom: 10px;
  }
}

.profiles_gallery_item_related_program_link {
  color: #fff;
}

.fs-carousel-item_next .profiles_gallery_item_related_program_link,
.fs-carousel-item_previous .profiles_gallery_item_related_program_link {
  pointer-events: none;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_item_related_program_link:focus .profiles_gallery_item_related_link_icon,
  .profiles_gallery_item_related_program_link:hover .profiles_gallery_item_related_link_icon {
    margin-left: 10px;
  }
}

.profiles_gallery_item_related_link_icon {
  margin-left: 6px;
  transition: margin-left .25s ease;
}

.profiles_gallery_item_related_link_icon .symbol_arrow_right {
  width: 12px;
  height: 12px;
  fill: #C8102E;
}

.profiles_gallery_item_wrapper.theme_maroon .profiles_gallery_item_related_link_icon .symbol_arrow_right,
.profiles_gallery_item_wrapper.theme_red .profiles_gallery_item_related_link_icon .symbol_arrow_right {
  fill: #fff;
}

.profiles_gallery_controls.fs-carousel-controls_custom.fs-carousel-visible {
  display: none;
  pointer-events: none;
}

@media only screen and (min-width:768px) {
  .profiles_gallery_controls {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }

  .profiles_gallery_controls.fs-carousel-controls_custom.fs-carousel-visible {
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width:992px) {
  .profiles_gallery_controls {
    -ms-transform: translateY(25%);
    transform: translateY(25%);
  }
}

.profiles_gallery_controls_prev {
  width: 60px;
  height: 60px;
  background: #D31243;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease;
}

@media only screen and (min-width:992px) {
  .profiles_gallery_controls_prev {
    width: 80px;
    height: 80px;
  }
}

.profiles_gallery_controls_prev .profiles_gallery_control_label {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.profiles_gallery_controls_prev .icon .symbol {
  width: 12px;
  height: 18px;
  color: #fff;
}

@media only screen and (min-width:992px) {
  .profiles_gallery_controls_prev .icon .symbol {
    width: 16px;
    height: 24px;
  }
}

.profiles_gallery_controls_prev:focus,
.profiles_gallery_controls_prev:hover {
  background: #333;
}

.profiles_gallery_controls_prev.fs-carousel-visible {
  opacity: 1;
  pointer-events: auto;
}

.profiles_gallery_controls_next {
  width: 60px;
  height: 60px;
  background: #D31243;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease;
}

@media only screen and (min-width:992px) {
  .profiles_gallery_controls_next {
    width: 80px;
    height: 80px;
  }
}

.profiles_gallery_controls_next .profiles_gallery_control_label {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.profiles_gallery_controls_next .icon .symbol {
  width: 12px;
  height: 18px;
  color: #fff;
}

@media only screen and (min-width:992px) {
  .profiles_gallery_controls_next .icon .symbol {
    width: 16px;
    height: 24px;
  }
}

.profiles_gallery_controls_next:focus,
.profiles_gallery_controls_next:hover {
  background: #333;
  background-size: 16px 24px;
  background-position: center center;
}

.profiles_gallery_controls_next.fs-carousel-visible {
  opacity: 1;
  pointer-events: auto;
}

.profiles_block {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.profiles_block .loading_spinner {
  z-index: 3;
  box-shadow: inset 0 0 0 3px rgba(38,77,182,.3);
  opacity: 0;
  transition: opacity .3s .3s,visibility .3s .3s;
  visibility: visible;
}

.profiles_block .loading_line:before {
  box-shadow: inset 0 0 0 3px rgba(38,77,182,.7);
}

.profiles_block.loading .loading_spinner {
  opacity: 1;
}

.profiles_inner {
  position: relative;
}

.profiles_background {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40%;
  width: calc(100vh * .668);
  filter: saturate(0);
  opacity: .1;
}

@media only screen and (max-width:767px) {
  .profiles_background {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:1200px) {
  .profiles_background {
    width: 50%;
    width: 50vw;
  }
}

.profiles_items {
  padding: 40px 0;
}

@media only screen and (min-width:768px) {
  .profiles_items {
    height: 100px;
    min-height: 100vh;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 55px 0;
  }
}

@media screen and (min-width:500px) and (max-height:799px) {
  .profiles_items {
    height: auto;
  }
}

@media only screen and (max-width:1199px) {
  .profiles_items {
    max-height: 1000px;
  }
}

@media only screen and (min-width:1200px) {
  .profiles_items {
    padding: 75px 0;
  }
}

.profiles_items .carousel_count {
  margin-top: 18px;
}

.js .profiles_items_inner:after {
  clear: both;
  content: "";
  display: table;
}

.no-js .profiles_items_inner {
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.profiles_items_inner .fs-carousel-viewport {
  max-width: 1016px;
  position: static;
}

.profiles_items_inner .fs-carousel-wrapper {
  position: static;
}

.profiles_items_inner .fs-carousel-canister {
  overflow: visible;
}

@media print {
  .profiles_items_inner .fs-carousel-canister:after {
    clear: both;
    content: "";
    display: table;
  }
}

.profiles_items_inner .fs-carousel-item {
  margin: 0;
  opacity: .5;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: opacity .5s,transform .5s;
}

@media print {
  .profiles_items_inner .fs-carousel-item {
    opacity: 1;
  }
}

@media only screen and (min-width:1200px) {
  .profiles_items_inner .fs-carousel-item {
    transition: opacity .5s,transform .8s;
  }
}

.profiles_items_inner .fs-carousel-item:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item:not(:last-child) {
    margin-right: 80px;
  }
}

@media only screen and (min-width:992px) {
  .profiles_items_inner .fs-carousel-item:not(:last-child) {
    margin-right: 100px;
  }
}

@media only screen and (min-width:1200px) {
  .profiles_items_inner .fs-carousel-item:not(:last-child) {
    margin-right: 130px;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item .profile_item_footer_links,
  .profiles_items_inner .fs-carousel-item .profile_item_footer_title,
  .profiles_items_inner .fs-carousel-item .profile_item_group,
  .profiles_items_inner .fs-carousel-item .profile_item_header {
    opacity: 0;
    transition: opacity .5s;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item .profile_item_group,
  .profiles_items_inner .fs-carousel-item .profile_item_header {
    transition-delay: .25s;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item .profile_item_link .icon {
    -ms-transform: scale(0);
    transform: scale(0);
    transition: transform .5s;
    transition-delay: .35s;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item .profile_item_footer_links,
  .profiles_items_inner .fs-carousel-item .profile_item_footer_title {
    transition-delay: .45s;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item .profile_item_footer:before {
    width: 0;
    transition: width .5s;
    transition-delay: .35s;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-item_previous {
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}

@media only screen and (min-width:1200px) {
  .profiles_items_inner .fs-carousel-item_previous {
    -ms-transform: translateX(-500px);
    transform: translateX(-500px);
  }
}

.profiles_items_inner .fs-carousel-visible {
  opacity: 1;
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-visible .profile_item_footer_links,
  .profiles_items_inner .fs-carousel-visible .profile_item_footer_title,
  .profiles_items_inner .fs-carousel-visible .profile_item_group,
  .profiles_items_inner .fs-carousel-visible .profile_item_header {
    opacity: 1;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-visible .profile_item_footer:before {
    width: 100%;
  }
}

@media only screen and (min-width:768px) {
  .profiles_items_inner .fs-carousel-visible .profile_item_link .icon {
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (max-width:767px) {
  .profiles_items_inner .fs-carousel-controls {
    display: none!important;
    visibility: hidden;
  }
}

.profile_item {
  overflow: hidden;
  max-width: 310px;
  margin: 10px auto;
}

[data-whatinput=mouse] .profile_item:focus,
[data-whatinput=touch] .profile_item:focus {
  outline: 0;
}

@media print {
  .profile_item {
    width: calc(50% - 10px)!important;
    height: auto!important;
    max-width: none;
    margin-bottom: 10px!important;
  }
}

@media screen and (min-width:500px) {
  .profile_item {
    max-width: 392px;
  }
}

@media only screen and (max-width:767px) {
  .profile_item {
    background-color: #072f4f;
    border-radius: 5px;
    color: #fff;
  }
}

@media only screen and (min-width:768px) {
  .profile_item {
    max-width: none;
  }
}

.no-js .profile_item {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  white-space: normal;
}

@media only screen and (min-width:768px) {
  .no-js .profile_item {
    width: 720px;
  }
}

@media only screen and (min-width:992px) {
  .no-js .profile_item {
    width: 940px;
  }
}

@media only screen and (min-width:1200px) {
  .no-js .profile_item {
    width: 1016px;
  }
}

@media print {
  .profile_item:nth-child(odd) {
    float: left;
    clear: both;
  }
}

@media print {
  .profile_item:nth-child(even) {
    float: right;
    margin-right: 0!important;
  }
}

.no-js .profile_item:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (min-width:768px) {
  .no-js .profile_item:not(:last-child) {
    margin-right: 80px;
  }
}

@media only screen and (min-width:992px) {
  .no-js .profile_item:not(:last-child) {
    margin-right: 100px;
  }
}

@media only screen and (min-width:1200px) {
  .no-js .profile_item:not(:last-child) {
    margin-right: 130px;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_inner {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
}

@media only screen and (max-width:767px) {
  .profile_item_attachment {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_attachment {
    overflow: hidden;
    position: absolute;
    width: 49%;
    width: calc(50% - 5px);
    height: 100%;
    border-radius: 5px;
  }
}

@media only screen and (min-width:768px) {
  .no-js .profile_item_attachment {
    background-color: #000;
  }
}

.profile_item_attachment_link {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media print {
  .profile_item_attachment_link:after {
    display: none!important;
    visibility: hidden;
  }
}

.profile_item_attachment_link .icon {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  z-index: 1;
  background-color: #02a6ab;
  border-radius: 100%;
  -ms-transform: scale(0);
  transform: scale(0);
  transition: transform .25s;
}

.no-js .profile_item_attachment_link .icon {
  display: none!important;
  visibility: hidden;
}

.profile_item_attachment_link .symbol_plus {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 40px;
  height: 40px;
  fill: #fff;
}

.profile_item_attachment_link:focus .icon,
.profile_item_attachment_link:hover .icon {
  -ms-transform: scale(1);
  transform: scale(1);
}

.profile_item_background_fallback {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile_item_background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.profile_item_background:after {
  height: 102%;
  width: 102%;
  position: absolute;
  top: -1%;
  right: -1%;
  bottom: -1%;
  left: -1%;
  display: block;
  background-color: #072f4f;
  content: "";
  opacity: 0;
  transition: opacity .25s;
}

.profile_item_attachment_link:focus .profile_item_background:after,
.profile_item_attachment_link:hover .profile_item_background:after {
  opacity: .3;
}

.profile_item_figure {
  display: block;
}

.profile_item_figure picture {
  display: block;
}

.profile_item_figure img {
  display: block;
  width: 100%;
}

@media only screen and (min-width:768px) {
  .profile_item_figure {
    display: none;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_details {
    float: right;
    overflow: hidden;
    width: 49%;
    width: calc(50% - 5px);
    background-color: #072f4f;
    border-radius: 5px;
    color: #fff;
  }
}

.profile_item_content {
  padding: 15px;
}

@media print {
  .profile_item_content {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_content {
    height: 100%;
    padding: 20px;
  }
}

@media only screen and (min-width:992px) {
  .profile_item_content {
    padding: 40px;
  }
}

.profile_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0 0 10px;
}

@media only screen and (min-width:768px) {
  .profile_item_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .profile_item_title {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

@media only screen and (min-width:992px) {
  .profile_item_title {
    margin-bottom: 25px;
  }
}

.profile_item_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin-bottom: 24px;
}

@media only screen and (min-width:768px) {
  .profile_item_description {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.52380952;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_description {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (min-width:992px) {
  .profile_item_description {
    margin-bottom: 35px;
  }
}

.profile_item_description p {
  margin: 0;
}

.profile_item_links {
  padding-bottom: 24px;
}

@media print {
  .profile_item_links {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_links {
    padding-bottom: 35px;
  }
}

.profile_item_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  display: block;
  position: relative;
  background-color: #fff;
  color: #333;
  overflow: visible;
  text-align: center;
  transition: background .25s,color .25s;
}

@media only screen and (min-width:768px) {
  .profile_item_link {
    display: inline-block;
  }
}

@media only screen and (max-width:767px) {
  .profile_item_link .icon {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_link .icon {
    position: absolute;
    width: 26px;
    height: 26px;
    display: block;
    top: -10px;
    left: -10px;
    background-color: #02a6ab;
    border-radius: 100%;
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
    transition: opacity .25s,transform .25s;
  }
}

@media only screen and (min-width:992px) {
  .profile_item_link .icon {
    width: 36px;
    height: 36px;
    top: -18px;
    left: -18px;
  }
}

.no-js .profile_item_link .icon {
  display: none!important;
  visibility: hidden;
}

@media only screen and (max-width:767px) {
  .profile_item_link .icon:before {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_link .icon:before {
    width: 26px;
    height: 26px;
    display: block;
    background-color: #02a6ab;
    border-radius: 100%;
    content: "";
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
    transition: opacity .25s,transform .25s;
  }
}

@media only screen and (min-width:992px) {
  .profile_item_link .icon:before {
    width: 36px;
    height: 36px;
  }
}

.profile_item_link .symbol_plus {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 12px;
  height: 12px;
  fill: #fff;
}

@media only screen and (min-width:992px) {
  .profile_item_link .symbol_plus {
    width: 18px;
    height: 18px;
  }
}

.profile_item_link:focus,
.profile_item_link:hover {
  background-color: #02a6ab;
  color: #fff;
}

@media only screen and (min-width:768px) {
  .profile_item_link:focus .icon:before,
  .profile_item_link:hover .icon:before {
    animation: pulse .8s forwards;
  }
}

@media only screen and (max-width:767px) {
  .profile_item_footer {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .profile_item_footer {
    position: relative;
    padding-top: 18px;
  }
}

.profile_item_footer:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,.2);
  content: "";
}

.profile_item_footer_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  margin: 0 0 6px;
}

.profile_item_footer_links {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media only screen and (min-width:768px) {
  .profile_item_footer_links {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.profile_item_footer_link {
  color: inherit;
  transition: color .25s;
}

.profile_item_footer_link:focus,
.profile_item_footer_link:hover {
  color: #02a6ab;
}

@media only screen and (max-width:767px) {
  .profile_item:nth-child(6n+2) {
    background-color: #264db6;
  }
}

.profile_item:nth-child(6n+2) .profile_item_attachment_link .icon {
  background-color: #5fc64d;
}

@media only screen and (min-width:768px) {
  .profile_item:nth-child(6n+2) .profile_item_details {
    background-color: #264db6;
  }
}

.profile_item:nth-child(6n+2) .profile_item_link .icon {
  background-color: #5fc64d;
}

.profile_item:nth-child(6n+2) .profile_item_link .icon:before {
  background-color: #5fc64d;
}

.profile_item:nth-child(6n+2) .profile_item_link:focus,
.profile_item:nth-child(6n+2) .profile_item_link:hover {
  background-color: #5fc64d;
}

.profile_item:nth-child(6n+2) .profile_item_footer_link:focus,
.profile_item:nth-child(6n+2) .profile_item_footer_link:hover {
  color: #5fc64d;
}

@media only screen and (max-width:767px) {
  .profile_item:nth-child(6n+3) {
    background-color: #C8102E;
  }
}

.profile_item:nth-child(6n+3) .profile_item_attachment_link .icon {
  background-color: #072f4f;
}

@media only screen and (min-width:768px) {
  .profile_item:nth-child(6n+3) .profile_item_details {
    background-color: #C8102E;
  }
}

.profile_item:nth-child(6n+3) .profile_item_link .icon {
  background-color: #072f4f;
}

.profile_item:nth-child(6n+3) .profile_item_link .icon:before {
  background-color: #072f4f;
}

.profile_item:nth-child(6n+3) .profile_item_link:focus,
.profile_item:nth-child(6n+3) .profile_item_link:hover {
  background-color: #072f4f;
}

.profile_item:nth-child(6n+3) .profile_item_footer_link:focus,
.profile_item:nth-child(6n+3) .profile_item_footer_link:hover {
  color: #072f4f;
}

@media only screen and (max-width:767px) {
  .profile_item:nth-child(6n+4) {
    background-color: #333;
  }
}

.profile_item:nth-child(6n+4) .profile_item_attachment_link .icon {
  background-color: #5fc64d;
}

@media only screen and (min-width:768px) {
  .profile_item:nth-child(6n+4) .profile_item_details {
    background-color: #333;
  }
}

.profile_item:nth-child(6n+4) .profile_item_link .icon {
  background-color: #5fc64d;
}

.profile_item:nth-child(6n+4) .profile_item_link .icon:before {
  background-color: #5fc64d;
}

.profile_item:nth-child(6n+4) .profile_item_link:focus,
.profile_item:nth-child(6n+4) .profile_item_link:hover {
  background-color: #5fc64d;
}

.profile_item:nth-child(6n+4) .profile_item_footer_link:focus,
.profile_item:nth-child(6n+4) .profile_item_footer_link:hover {
  color: #5fc64d;
}

@media only screen and (max-width:767px) {
  .profile_item:nth-child(6n+5) {
    background-color: #02a6ab;
  }
}

.profile_item:nth-child(6n+5) .profile_item_attachment_link .icon {
  background-color: #072f4f;
}

@media only screen and (min-width:768px) {
  .profile_item:nth-child(6n+5) .profile_item_details {
    background-color: #02a6ab;
  }
}

.profile_item:nth-child(6n+5) .profile_item_link .icon {
  background-color: #072f4f;
}

.profile_item:nth-child(6n+5) .profile_item_link .icon:before {
  background-color: #072f4f;
}

.profile_item:nth-child(6n+5) .profile_item_link:focus,
.profile_item:nth-child(6n+5) .profile_item_link:hover {
  background-color: #072f4f;
}

.profile_item:nth-child(6n+5) .profile_item_footer_link:focus,
.profile_item:nth-child(6n+5) .profile_item_footer_link:hover {
  color: #072f4f;
}

body.profile_lock,
html.profile_lock {
  overflow: hidden!important;
}

.profile_overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  opacity: 0;
  overflow: auto;
  overflow-x: hidden;
  pointer-events: none;
  transition: opacity .8s,visibility .8s;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width:1199px) {
  .profile_overlay {
    padding-bottom: 0!important;
  }
}

.touchevents .profile_overlay {
  padding-bottom: 0!important;
}

.profile_lock .profile_overlay {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.profile_overlay_close {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 15px;
  right: 25px;
  z-index: 1;
  background-color: #264db6;
  border-radius: 100%;
  cursor: pointer;
  -ms-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: background .25s,transform .55s .55s,opacity .25s;
}

.csstransforms3d .profile_overlay_close {
  transform: translate3d(-50vw,0,0);
}

.profile_overlay_close:focus,
.profile_overlay_close:hover {
  background-color: #C8102E;
}

.profile_lock .profile_overlay_close {
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.profile_lock .csstransforms3d .profile_overlay_close {
  transform: translate3d(0,0,0);
}

@media only screen and (min-width:992px) {
  .profile_overlay.unpin .profile_overlay_close {
    opacity: 0;
    transition: background .25s,opacity .25s;
  }
}

.touchevents .profile_overlay.unpin .profile_overlay_close {
  opacity: 0;
}

.profile_overlay_close .symbol {
  width: 20px;
  height: 20px;
}

.profile_overlay_close .symbol_close {
  fill: #fff;
}

.profile_overlay_inner {
  min-height: 100vh;
  position: relative;
}

@media only screen and (max-width:1199px) {
  .profile_overlay_inner {
    background-color: #072f4f;
  }
}

.profile_overlay_figure {
  display: block;
  opacity: 0;
  pointer-events: none;
  -ms-transform: translateY(600px);
  transform: translateY(600px);
  visibility: hidden;
  transition: opacity .35s,transform .35s,visibility .35s;
  transition-delay: .55s;
  transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}

.profile_overlay_figure picture {
  display: block;
}

.profile_overlay_figure img {
  display: block;
  width: 100%;
}

@media only screen and (min-width:1200px) {
  .profile_overlay_figure {
    display: none!important;
    visibility: hidden;
  }
}

.profile_lock .profile_overlay_figure {
  opacity: 1;
  pointer-events: auto;
  -ms-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

@media only screen and (max-width:1199px) {
  .profile_overlay_background {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:1200px) {
  .profile_overlay_background {
    width: 50%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: translateZ(0);
  }
}

.profile_overlay.unpin .profile_overlay_background {
  position: absolute;
  height: 100vh;
  width: 50%;
  width: 50vw;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

.profile_overlay_item {
  opacity: 0;
  pointer-events: none;
  -ms-transform: translateY(600px);
  transform: translateY(600px);
  visibility: hidden;
  transition: opacity .35s,transform .35s,visibility .35s;
  transition-delay: .95s;
}

@media only screen and (min-width:1200px) {
  .profile_overlay_item {
    transition-delay: 1.15s;
  }
}

.profile_lock .profile_overlay_item {
  opacity: 1;
  pointer-events: auto;
  -ms-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

.profile_overlay_details_wrap {
  background-color: #072f4f;
  color: #fff;
}

@media only screen and (min-width:1200px) {
  .profile_overlay_details_wrap {
    padding-left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: transform .55s;
    transition-delay: .55s;
  }
}

@media only screen and (min-width:1200px) {
  .csstransforms3d .profile_overlay_details_wrap {
    transform: translate3d(-50%,0,0);
  }
}

@media only screen and (min-width:1200px) {
  .profile_lock .profile_overlay_details_wrap {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (min-width:1200px) {
  .csstransforms3d .profile_lock .profile_overlay_details_wrap {
    transform: translate3d(0,0,0);
  }
}

.profile_overlay_details {
  padding: 15px;
}

@media only screen and (min-width:768px) {
  .profile_overlay_details {
    max-width: 600px;
    margin: 0 auto;
    padding: 25px 20px;
  }
}

@media only screen and (min-width:992px) {
  .profile_overlay_details {
    padding: 175px 20px 25px;
  }
}

@media only screen and (min-width:1200px) {
  .profile_overlay_details {
    min-height: 100vh;
  }
}

.profile_overlay_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0 0 20px;
}

@media only screen and (min-width:768px) {
  .profile_overlay_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

.profile_overlay_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin: 20px 0 30px;
}

@media only screen and (min-width:768px) {
  .profile_overlay_description {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.52380952;
  }
}

.profile_overlay_description p {
  margin: 0;
}

.profile_card {
  margin-bottom: 50px;
}

@media only screen and (min-width:992px) {
  .profile_card {
    margin-bottom: 70px;
  }
}

.profile_card:nth-child(1) {
  transition-delay: 1.1s;
}

@media only screen and (min-width:1200px) {
  .profile_card:nth-child(1) {
    transition-delay: 1.3s;
  }
}

.profile_card:nth-child(2) {
  transition-delay: 1.25s;
}

@media only screen and (min-width:1200px) {
  .profile_card:nth-child(2) {
    transition-delay: 1.45s;
  }
}

.profile_card:nth-child(3) {
  transition-delay: 1.4s;
}

@media only screen and (min-width:1200px) {
  .profile_card:nth-child(3) {
    transition-delay: 1.7s;
  }
}

.profile_card:nth-child(4) {
  transition-delay: 1.55s;
}

@media only screen and (min-width:1200px) {
  .profile_card:nth-child(4) {
    transition-delay: 1.85s;
  }
}

.program_tabs {
  position: relative;
  -ms-transform: translate(0,-1px);
  transform: translate(0,-1px);
}

.program_tabs_list {
  background-color: #333;
}

.program_tabs_items {
  display: -ms-flexbox;
  display: flex;
}

.program_tabs_item {
  background-color: #00b2a9;
  border-top: 2px solid #099992;
  color: #000;
  -ms-flex: 1;
  flex: 1;
}

.program_tabs_item+.program_tabs_item {
  margin-left: 7px;
}

.program_tabs_item.is_active {
  background-color: #fff;
  color: #099992;
}

.program_tabs_item_btn {
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: currentColor;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding: 18px 18px;
  text-align: center;
}

@media only screen and (min-width:992px) {
  .program_tabs_item_btn {
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 25px 50px;
    text-align: left;
  }
}

.program_tabs_item_icon {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 6px;
}

@media only screen and (min-width:992px) {
  .program_tabs_item_icon {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.program_tabs_item_icon svg.symbol_clipboard {
  width: 38px;
  height: 52px;
}

.program_tabs_item_icon svg.symbol_search_circle {
  width: 53px;
  height: 53px;
  color: #333;
}

.program_tabs_item.is_active .program_tabs_item_icon svg.symbol_search_circle {
  color: #02A6AB;
}

.program_tabs_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:992px) {
  .program_tabs_item_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.program_tabs_item_subtitle {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  color: #000;
  display: none;
  line-height: 1.33333333;
  margin: 5px 0 0;
}

@media only screen and (min-width:768px) {
  .program_tabs_item_subtitle {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

@media only screen and (min-width:992px) {
  .program_tabs_item_subtitle {
    display: block;
    line-height: 1.33333333;
  }
}

.program_tabs_content {
  margin-top: 50px;
}

.program_finder_guide {
  padding-bottom: 50px;
}

.program_finder_guide_content {
  margin-top: 40px;
}

.program_finder_guide_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .program_finder_guide_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.program_finder_guide_subtitle {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  display: none;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .program_finder_guide_subtitle {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

@media only screen and (min-width:992px) {
  .program_finder_guide_subtitle {
    display: block;
  }
}

.program_finder_pills {
  position: relative;
  margin-top: 15px;
  margin-bottom: 30px;
}

@media only screen and (min-width:992px) {
  .program_finder_pills {
    margin-bottom: 45px;
  }
}

.program_finder_pills_items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -4px -17px;
}

.program_finder_pills_item {
  margin-bottom: 17px;
  padding: 0 4px;
  outline: 0;
}

.program_finder_pills_item:focus,
.program_finder_pills_item:hover {
  outline: 0;
}

.program_finder_pills_item_input {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.program_finder_pills_item_input.focusable:active,
.program_finder_pills_item_input.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.program_finder_pills_item_input.focusable:active,
.program_finder_pills_item_input.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.program_finder_pills_item_input:checked~span {
  background-color: #02a6ab;
}

.program_finder_pills_item_input[disabled]~span {
  cursor: not-allowed;
  opacity: .5;
}

.program_finder_pills_item_text {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  background-color: #f3f4f5;
  border: 1px solid rgba(167,168,170,.52);
  border-radius: 15px;
  cursor: pointer;
  color: #072f4f;
  display: inline-block;
  letter-spacing: normal;
  padding: 6px 14px;
  transition: 125ms ease;
}

@media (hover:hover) and (pointer:fine) {
  .program_finder_pills_item_text:hover {
    background-color: #00b2a9;
  }
}

.program_counter {
  --bg_color: #f3f4f5;
  --inner_bg_color: #fff;
  --title_color: #0B263B;
  --body_border_top_color: #f3f4f5;
  --count_color: #0B263B;
  --count_subtitle_color: #0B263B;
  --link_color: #0B263B;
  --link_icon_color: #C8102E;
  --link_active_bg_color: #930a28;
}

.program_counter.is_enabled {
  --bg_color: #C8102E;
  --count_color: #C8102E;
  --link_color: #fff;
  --link_icon_color: #fff;
}

.program_counter {
  background-color: var(--bg_color);
  border-radius: 4px;
  display: none;
  padding: 4px;
  transition: .25s ease;
}

@media only screen and (min-width:992px) {
  .program_counter {
    display: block;
  }
}

.program_counter_title_wrap {
  background-color: var(--inner_bg_color);
  padding: 20px;
  transition: .25s ease;
}

@media only screen and (min-width:992px) {
  .program_counter_title_wrap {
    padding: 25px 35px;
  }
}

.program_counter_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--title_color);
  margin: 0;
  transition: .25s ease;
}

.program_counter_body {
  background-color: var(--inner_bg_color);
  border-top: 1px solid var(--body_border_top_color);
  padding: 15px 20px;
  transition: .25s ease;
}

@media only screen and (min-width:992px) {
  .program_counter_body {
    padding: 25px 35px;
  }
}

.program_counter_count {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  font-size: 3.75rem;
  color: var(--count_color);
  line-height: 1;
  transition: .25s ease;
}

@media only screen and (min-width:992px) {
  .program_counter_count {
    font-size: 80px;
    font-size: 5rem;
  }
}

.program_counter_count_subtitle {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  color: var(--count_subtitle_color);
  letter-spacing: normal;
  transition: .25s ease;
}

.program_counter_btn {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--link_color);
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  letter-spacing: normal;
  padding: 25px 35px;
  text-align: left;
  transition: .25s ease;
}

.program_counter_btn:disabled {
  cursor: not-allowed;
}

.program_counter_btn:focus:not(:disabled),
.program_counter_btn:hover:not(:disabled) {
  background-color: var(--link_active_bg_color);
}

.program_counter_btn .icon {
  color: var(--link_icon_color);
  margin-left: 10px;
}

.program_counter_dock {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: #C8102E;
  color: #fff;
  opacity: 0;
  padding: 16px 0;
  transition: .25s opacity ease,.25s visibility ease .25s;
  visibility: hidden;
}

.program_counter_dock.is_active {
  opacity: 1;
  transition: .25s opacity ease;
  visibility: visible;
}

@media only screen and (min-width:992px) {
  .program_counter_dock {
    display: none;
  }
}

.program_counter_dock_inner {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.program_counter_dock_count {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  font-size: 1.875rem;
  min-width: 52px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.program_counter_dock_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  font-size: .875rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 6px;
}

.program_counter_dock_btn {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 5px;
  color: #C8102E;
  cursor: pointer;
  -ms-flex: 1;
  flex: 1;
  margin-left: 13px;
  padding: 12px 20px;
}

.program_results {
  background-color: #f3f4f5;
  padding-bottom: 70px;
  padding-top: 60px;
}

.program_results_head {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: -10px;
}

@media only screen and (min-width:992px) {
  .program_results_head {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.program_results_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 0 0 10px;
}

@media only screen and (min-width:768px) {
  .program_results_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.program_results_title span {
  color: #C8102E;
}

.program_results_clear {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  -ms-flex-align: center;
  align-items: center;
  color: #072f4f;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  line-height: 14px;
  margin: 0 0 10px;
}

@media only screen and (min-width:768px) {
  .program_results_clear {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.program_results_clear .icon {
  margin-left: 7px;
  margin-top: -3px;
}

.program_results_clear .symbol {
  height: 14px;
  width: 15px;
  fill: #C8102E;
}

.program_results_content {
  margin-top: 25px;
}

.program_finder_programs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  overflow: hidden;
  margin-bottom: -10px;
}

.program_finder_program {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  margin: 0 -15px 10px;
  padding: 9px 0;
}

@media only screen and (min-width:992px) {
  .program_finder_program {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.program_finder_program:not(:last-child) {
  border-bottom: 1px solid #CCC;
}

.program_finder_program>* {
  padding: 0 15px;
}

@media only screen and (min-width:992px) {
  .program_finder_program>* {
    margin-bottom: 0;
    width: 55%;
  }

  .program_finder_program>:first-child {
    width: 45%;
  }
}

.program_finder_program_link {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  color: #C8102E;
  transition: .25s ease;
}

@media only screen and (min-width:768px) {
  .program_finder_program_link {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.program_finder_program_link:focus,
.program_finder_program_link:hover {
  color: #072f4f;
}

.program_finder_program_link:focus .icon,
.program_finder_program_link:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.program_finder_program_link .icon {
  position: relative;
  color: #072f4f;
  display: inline-block;
  margin-left: 2px;
  transition: .25s ease;
}

.program_finder_program_link .icon svg {
  height: 12px;
  width: 12px;
}

.program_finder_program_text {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
}

.program_finder_program_text .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media only screen and (min-width:992px) {
  .program_finder_program_text .label {
    display: none;
  }
}

.program_finder_search {
  padding-bottom: 50px;
}

.program_finder_search_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .program_finder_search_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.program_finder_search_content {
  margin-top: 15px;
}

.program_finder_search_form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  margin-bottom: -15px;
}

@media only screen and (min-width:992px) {
  .program_finder_search_form {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.program_finder_search_form>* {
  margin-bottom: 15px;
}

.program_finder_search_input {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  width: 100%;
  height: 60px;
  padding: 20px;
  border: 1px solid #CCC;
  border-radius: 5px;
  -ms-flex: 1;
  flex: 1;
}

@media only screen and (min-width:768px) {
  .program_finder_search_input {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.52380952;
  }
}

.program_finder_search_input:active,
.program_finder_search_input:focus {
  outline: 0;
}

@media only screen and (min-width:992px) {
  .program_finder_search_input {
    -ms-flex: none;
    flex: none;
    height: 80px;
    max-width: 100%;
    width: 700px;
    padding: 20px 34px;
  }
}

.program_finder_search_submit {
  height: 60px;
  background-color: #C8102E;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 10px 24px;
  transition: .25s ease;
}

@media only screen and (min-width:992px) {
  .program_finder_search_submit {
    height: 80px;
    margin-left: 10px;
  }
}

.program_finder_search_submit:focus,
.program_finder_search_submit:hover {
  background-color: #000;
}

.program_results_block {
  margin: 20px 0;
}

@media only screen and (min-width:992px) {
  .program_results_block {
    margin: 40px 0;
  }
}

.program_results_header {
  margin: 20px 0;
}

@media only screen and (min-width:768px) {
  .program_results_header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.program_results_meta {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
  margin-bottom: 12px;
}

.program_results_meta .accent {
  color: #C8102E;
}

.view_controls {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
}

@media print {
  .view_controls {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:992px) {
  .view_controls {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.no-js .view_controls {
  display: none!important;
  visibility: hidden;
}

.view_control {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
}

.view_control .accent {
  display: inline-block;
  box-shadow: inset 0 -1px 0 #072f4f;
  color: #C8102E;
  text-decoration: none;
  transition: color .25s,box-shadow .25s;
}

.view_control .icon {
  margin-right: 10px;
}

.view_control .icon .symbol {
  width: 15px;
  height: 15px;
  fill: #072f4f;
}

@media only screen and (min-width:992px) {
  .view_control .icon .symbol {
    width: 18px;
    height: 18px;
  }
}

.view_control:focus .accent,
.view_control:hover .accent {
  box-shadow: inset 0 -1px 0 #264db6;
  color: #333;
}

.view_control_set {
  display: block;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width:499px) {
  .view_grid .program_card {
    max-width: 288px;
    margin: 10px auto;
  }
}

@media screen and (min-width:500px) {
  .view_grid .program_card {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:768px) {
  .view_grid .program_card {
    width: 233px;
  }
}

@media only screen and (min-width:992px) {
  .view_grid .program_card {
    width: 293px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width:1200px) {
  .view_grid .program_card {
    width: 262px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width:500px) and (max-width:767px) {
  .view_grid .program_card:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (min-width:768px) and (max-width:1199px) {
  .view_grid .program_card:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media only screen and (min-width:1200px) {
  .view_grid .program_card:nth-child(4n+4) {
    margin-right: 0;
  }
}

.view_grid .program_card .program_card_thumbnail {
  display: none!important;
  visibility: hidden;
}

.view_grid .program_card .program_card_header {
  position: inherit;
  padding: inherit;
}

.view_list .program_set.more .program_card:first-child {
  padding-top: 20px;
}

@media only screen and (min-width:768px) {
  .view_list .program_set.more .program_card:first-child {
    padding-top: 40px;
  }
}

.view_list .program_card {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  border-bottom: 1px solid #a7a8aa;
  border-radius: 0;
  color: #333;
}

@media only screen and (min-width:768px) {
  .view_list .program_card {
    padding-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .view_list .program_card {
    padding-bottom: 40px;
  }
}

.view_list .program_card:first-child {
  padding-top: 0;
}

.view_list .program_card .program_card_inner {
  padding-top: 0;
}

.view_list .program_card .program_card_background,
.view_list .program_card .program_card_background_fallback {
  display: none!important;
  visibility: hidden;
}

.view_list .program_card .program_card_group {
  position: static;
  min-height: inherit;
  bottom: auto;
  background-color: transparent!important;
  display: block;
  padding: 0;
  -ms-transform: translateY(0)!important;
  transform: translateY(0)!important;
}

.view_list .program_card .program_card_label {
  position: static;
  background-image: none;
  padding: 0;
}

.view_list .program_card .program_card_label_inner {
  border-bottom-width: 0;
}

@media screen and (min-width:500px) {
  .view_list .program_card .program_card_header {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 30px;
  }
}

.view_list .program_card .program_card_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  color: #333;
  margin: 10px 0 20px;
}

@media only screen and (min-width:768px) {
  .view_list .program_card .program_card_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

@media screen and (min-width:500px) {
  .view_list .program_card .program_card_title {
    margin: 10px 0;
  }
}

.view_list .program_card .program_card_thumbnail {
  display: block;
  float: right;
  height: 80px;
  width: 80px;
  position: relative;
  background: #264db6;
  border-radius: 50%;
  margin: 0 0 20px 20px;
  overflow: hidden;
}

.view_list .program_card .program_card_thumbnail picture {
  display: block;
}

.view_list .program_card .program_card_thumbnail img {
  display: block;
  width: 100%;
}

@media screen and (min-width:500px) {
  .view_list .program_card .program_card_thumbnail {
    float: none;
    margin: 0 20px 0 0;
  }
}

@media only screen and (min-width:768px) {
  .view_list .program_card .program_card_thumbnail {
    height: 100px;
    width: 100px;
    margin-right: 25px;
  }
}

.view_list .program_card .program_card_thumbnail img {
  display: block;
}

.view_list .program_card .program_card_content {
  color: #072f4f;
}

@media screen and (min-width:500px) {
  .view_list .program_card .program_card_content {
    margin-bottom: 20px;
  }
}

.view_list .program_card .program_card_info {
  margin: 0 0 20px;
  padding: 7px 0 7px 48px;
}

@media screen and (min-width:500px) {
  .view_list .program_card .program_card_info {
    display: inline-block;
    width: 48%;
    max-width: 400px;
    margin-bottom: 0;
    padding-right: 10px;
  }
}

.view_list .program_card .program_card_info .icon {
  position: absolute;
  height: 32px;
  width: 32px;
  top: 50%;
  left: 0;
  background: #f3f4f5;
  border-radius: 50%;
  display: inline-block;
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
  vertical-align: middle;
}

.view_list .program_card .program_card_info .icon .symbol {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  bottom: 2px;
  fill: #264db6!important;
}

@media screen and (min-width:500px) {
  .view_grid .program_set {
    max-width: 586px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

@media only screen and (min-width:768px) {
  .view_grid .program_set {
    max-width: none;
  }
}

.related_programs_block {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

@media only screen and (min-width:768px) {
  .related_programs_block {
    padding-top: 40px;
  }
}

@media only screen and (min-width:992px) {
  .related_programs_block {
    padding-top: 60px;
  }
}

@media only screen and (min-width:768px) {
  .related_programs_block {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .related_programs_block {
    padding-bottom: 60px;
  }
}

.in_content_callouts .related_programs_block,
.sidebar .related_programs_block {
  border-radius: 5px;
}

@media only screen and (min-width:992px) {
  .sidebar .related_programs_block {
    margin-left: -20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .related_programs_block {
    margin-left: -30px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .related_programs_block .carousel_count {
    display: block!important;
    margin-top: 20px;
    visibility: visible;
  }
}

@media print {
  .related_programs_block {
    display: none!important;
    visibility: hidden;
  }
}

.in_content_callouts .related_programs_inner {
  padding: 0 20px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .related_programs_inner {
    padding: 0 30px;
  }
}

.sidebar .related_programs_inner {
  padding: 0 20px;
}

@media only screen and (min-width:768px) {
  .sidebar .related_programs_inner {
    padding: 0 30px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .related_programs_inner {
    padding: 0 20px;
  }
}

.related_programs_header {
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .related_programs_header {
    margin-bottom: 30px;
  }

  .sidebar .related_programs_header {
    margin-bottom: 20px;
  }
}

.related_programs_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .related_programs_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .related_programs_title {
    font-size: 24px;
  }
}

.related_programs_carousel {
  margin-bottom: 20px;
}

@media only screen and (min-width:992px) {
  .related_programs_carousel {
    margin-bottom: 20px;
  }

  .sidebar .related_programs_carousel {
    margin-bottom: 0;
  }
}

@media only screen and (min-width:1200px) {
  .related_programs_carousel {
    margin-bottom: 30px;
  }
}

.no-js .related_programs_carousel {
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

@media print {
  .no-js .related_programs_carousel {
    overflow: auto;
    white-space: normal;
  }
}

@media screen and (max-width:629px) {
  .related_programs_carousel .fs-carousel-viewport {
    max-width: 288px;
  }
}

@media only screen and (min-width:768px) {
  .related_programs_carousel .fs-carousel-viewport {
    max-width: 586px;
  }
}

@media only screen and (min-width:992px) {
  .related_programs_carousel .fs-carousel-viewport {
    max-width: 904px;
  }
}

@media only screen and (min-width:1200px) {
  .related_programs_carousel .fs-carousel-viewport {
    max-width: none;
  }
}

@media print {
  .related_programs_carousel .fs-carousel-canister {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.in_content_callouts .related_programs_carousel .fs-carousel-item,
.sidebar .related_programs_carousel .fs-carousel-item {
  transition: opacity .25s ease;
}

@media print {
  .related_programs_carousel .fs-carousel-item {
    width: 48%!important;
    height: 488px!important;
    float: none;
  }
}

.related_programs_carousel .fs-carousel-item:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (min-width:992px) {
  .related_programs_carousel .fs-carousel-item:not(:last-child) {
    margin-right: 20px;
  }

  .sidebar .related_programs_carousel .fs-carousel-item:not(:last-child) {
    margin-right: 10px;
  }
}

@media only screen and (min-width:1200px) {
  .related_programs_carousel .fs-carousel-item:not(:last-child) {
    margin-right: 25px;
  }
}

.in_content_callouts .related_programs_carousel .fs-carousel-item_previous,
.sidebar .related_programs_carousel .fs-carousel-item_previous {
  opacity: .5;
}

.in_content_callouts .related_programs_carousel .fs-carousel-item_next,
.sidebar .related_programs_carousel .fs-carousel-item_next {
  opacity: .5;
}

@media only screen and (max-width:767px) {
  .related_programs_carousel .fs-carousel-controls {
    display: none!important;
    visibility: hidden;
  }
}

.related_programs_carousel .carousel_count {
  margin-top: 18px;
}

.program_card {
  overflow: hidden;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  transform: translate3d(0,0,0);
}

.no-js .program_card {
  width: 288px;
  display: inline-block;
  margin-right: 20px;
}

.program_card_link {
  display: block;
  color: inherit;
}

.program_card_inner {
  position: relative;
  padding-top: 169.44444444%;
}

.program_card_background_fallback {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.program_card_background_fallback img {
  display: none;
}

.program_card_background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
}

.program_card_label {
  background-color: #000;
  background: linear-gradient(to bottom,#000,rgba(0,0,0,0));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#00000000', GradientType=0);
  position: absolute;
  top: -15px;
  right: 0;
  left: 0;
  padding: 50px 30px;
}

.program_card_label_inner {
  display: block;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,.2);
}

.program_card_group {
  position: absolute;
  min-height: 240px;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  background-color: rgba(200,16,46,.8);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 30px 40px 30px;
  transition: transform .35s;
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.csstransforms3d .program_card_group {
  transform: translate3d(0,250px,0);
}

.program_card_link:focus .program_card_group,
.program_card_link:hover .program_card_group {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.csstransforms3d .program_card_link:focus .program_card_group,
.csstransforms3d .program_card_link:hover .program_card_group {
  transform: translate3d(0,0,0);
}

.program_card_header {
  width: 100%;
}

.fs-carousel-item .program_card_header,
.view_grid .program_card_header {
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  padding: 0 30px;
}

.program_card_title {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.52380952;
  margin: 0 0 12px;
}

.program_card_content {
  width: 100%;
}

.fs-carousel-item .program_card_content,
.view_grid .program_card_content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px 30px;
}

.program_card_description {
  line-height: 1.5em;
}

.program_card_info {
  position: relative;
  margin: 12px 0;
  padding-left: 22px;
}

.program_card_info .icon {
  display: inline-block;
  position: absolute;
  width: 14px;
  top: -2px;
  left: 0;
}

.program_card_info .icon .symbol_location {
  width: 10px;
  height: 15px;
}

.program_card_info .icon .symbol_star {
  width: 14px;
  height: 14px;
}

.program_card_info.location .icon {
  left: 2px;
}

.related_programs_block.theme_white {
  background-color: #fff;
  color: #333;
}

.related_programs_block.theme_blue_dark {
  background-color: #072f4f;
  color: #fff;
}

.program_card.theme_blue_light .program_card_group {
  background-color: rgba(38,77,182,.8);
}

.program_card.theme_blue_dark .program_card_group {
  background-color: rgba(7,47,79,.8);
}

.program_card.theme_red_brand .program_card_group {
  background-color: rgba(200,16,46,.8);
}

.program_card.theme_red_light .program_card_group {
  background-color: rgba(200,16,46,.8);
}

.program_card.theme_green_dark .program_card_group {
  background-color: rgba(2,166,171,.8);
  color: #333;
}

.program_card.theme_green_dark .program_card_info .icon .symbol {
  fill: #333;
}

.program_card.theme_green_light .program_card_group {
  background-color: rgba(95,198,77,.8);
  color: #333;
}

.program_card.theme_green_light .program_card_info .icon .symbol {
  fill: #333;
}

.program_card.theme_pink .program_card_group {
  background-color: rgba(153,30,102,.8);
}

.program_card.theme_yellow .program_card_group {
  background-color: rgba(244,218,64,.8);
  color: #333;
}

.program_card.theme_yellow .program_card_info .icon .symbol {
  fill: #333;
}

.program_card.theme_orange .program_card_group {
  background-color: rgba(239,86,45,.8);
  color: #333;
}

.program_card.theme_orange .program_card_info .icon .symbol {
  fill: #333;
}

.program_card.theme_gray_dark .program_card_group {
  background-color: rgba(51,51,51,.8);
}

.filter_block {
  padding: 30px 0;
}

@media print {
  .filter_block {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .filter_block {
    padding: 50px 0;
  }
}

.page_feature .filter_block {
  padding-top: 0;
}

@media only screen and (min-width:768px) {
  .filter_fieldset_search_inner {
    display: -ms-flexbox;
    display: flex;
  }
}

.filter_select_wrap {
  margin: 0 0 20px;
}

@media only screen and (min-width:768px) {
  .filter_select_wrap {
    float: left;
    margin: 0 20px 0 0;
  }
}

@media only screen and (min-width:992px) {
  .filter_select_wrap {
    margin-right: 24px;
  }
}

@media only screen and (min-width:768px) {
  .filter_select_wrap .fs-dropdown {
    display: inline-block;
  }
}

@media only screen and (min-width:768px) {
  .filter_select_wrap .base_dropdown.fs-dropdown .fs-dropdown-selected {
    padding-right: 80px;
  }
}

.filter_select_label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.filter_select_label.focusable:active,
.filter_select_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.filter_select_label.focusable:active,
.filter_select_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.filter_search_wrap {
  position: relative;
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 14px 0;
}

@media only screen and (min-width:768px) {
  .filter_search_wrap {
    float: left;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0;
    padding: 8px 0 7px;
  }
}

.filter_search_label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.filter_search_label.focusable:active,
.filter_search_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.filter_search_label.focusable:active,
.filter_search_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.filter_search_input {
  font-size: 16px;
  font-size: 1rem;
  display: block;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  width: calc(100% - 61px);
  background-color: transparent;
  border-width: 0;
  margin: 0 68px 0 20px;
}

[data-whatinput=mouse] .filter_search_input:focus,
[data-whatinput=touch] .filter_search_input:focus {
  outline: 0;
}

@media only screen and (min-width:768px) {
  .filter_search_input {
    font-size: 21px;
    font-size: 1.3125rem;
    width: calc(100% - 97px);
    margin-right: 97px;
    padding: 20px 10px;
  }
}

.filter_search_input:-ms-input-placeholder {
  opacity: 1;
  text-overflow: ellipsis;
}

.filter_search_input::placeholder {
  opacity: 1;
  text-overflow: ellipsis;
}

.filter_search_input[placeholder] {
  opacity: 1;
  text-overflow: ellipsis;
}

.filter_search_button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  margin: auto 0;
  padding: 0 15px;
}

@media only screen and (min-width:768px) {
  .filter_search_button {
    padding: 0 28px;
  }
}

.filter_search_button .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.filter_search_button .label.focusable:active,
.filter_search_button .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.filter_search_button .label.focusable:active,
.filter_search_button .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.filter_search_button .symbol_search {
  width: 15px;
  height: 15px;
  transition: fill .25s;
}

@media only screen and (min-width:768px) {
  .filter_search_button .symbol_search {
    width: 21px;
    height: 21px;
  }
}

@media only screen and (min-width:768px) {
  .filter_fieldset_alpha,
  .filter_fieldset_bar {
    margin-top: 28px;
  }
}

@media only screen and (min-width:1200px) {
  .filter_alpha_nav,
  .filter_bar_nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}

.filter_alpha_label,
.filter_bar_label {
  display: block;
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  font-weight: 400;
  margin: 0 0 10px;
}

@media only screen and (min-width:768px) {
  .filter_alpha_label,
  .filter_bar_label {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

@media only screen and (min-width:1200px) {
  .filter_alpha_label,
  .filter_bar_label {
    display: inline-block;
    margin-right: 18px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width:768px) {
  .filter_alpha_select_wrap,
  .filter_bar_select_wrap {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (max-width:767px) {
  .alpha_nav_list {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (max-width:767px) {
  .bar_nav_list {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:1200px) {
  .bar_nav_list {
    display: inline-block;
  }
}

.filter_alpha_item,
.filter_bar_item {
  display: inline-block;
  border-radius: 3px;
  transition: color .25s;
}

.filter_alpha_item:not(:last-child),
.filter_bar_item:not(:last-child) {
  margin-right: 1px;
}

button.filter_alpha_item,
button.filter_bar_item {
  cursor: pointer;
}

.filter_alpha_item.active,
.filter_bar_item.active {
  pointer-events: none;
  background-color: #264db6;
  color: #fff;
}

.filter_alpha_letter,
.filter_bar_option {
  display: inline-block;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  letter-spacing: 2px;
  line-height: 1;
  font-size: 16px;
  font-size: 1rem;
  padding: 7px 4px 5px;
  text-transform: uppercase;
}

@media only screen and (min-width:992px) {
  .filter_alpha_letter,
  .filter_bar_option {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.filter_block.theme_white .filter_search_wrap {
  background-color: #f3f4f5;
}

.filter_block.theme_white .filter_search_input:-ms-input-placeholder {
  color: #333;
}

.filter_block.theme_white .filter_search_input::placeholder {
  color: #333;
}

.filter_block.theme_white .filter_search_input[placeholder] {
  color: #333;
}

.filter_block.theme_white .filter_search_button {
  color: #333;
}

.filter_block.theme_white .filter_search_button .symbol_search {
  fill: #072f4f;
}

.filter_block.theme_white .filter_search_button:focus .symbol_search,
.filter_block.theme_white .filter_search_button:hover .symbol_search {
  fill: #264db6;
}

.filter_block.theme_white .filter_alpha_item:focus,
.filter_block.theme_white .filter_alpha_item:hover,
.filter_block.theme_white .filter_bar_item:focus,
.filter_block.theme_white .filter_bar_item:hover {
  color: #C8102E;
}

.filter_block.theme_white .filter_alpha_item.active,
.filter_block.theme_white .filter_bar_item.active {
  background-color: #264db6;
  color: #fff;
}

.filter_block.theme_gray {
  background-color: #f3f4f5;
}

.filter_block.theme_gray .filter_search_wrap {
  background-color: #072f4f;
  color: #fff;
}

.filter_block.theme_gray .filter_search_input:-ms-input-placeholder {
  color: #fff;
}

.filter_block.theme_gray .filter_search_input::placeholder {
  color: #fff;
}

.filter_block.theme_gray .filter_search_input[placeholder] {
  color: #fff;
}

.filter_block.theme_gray .filter_search_button {
  color: #fff;
}

.filter_block.theme_gray .filter_search_button .symbol_search {
  fill: #fff;
}

.filter_block.theme_gray .filter_search_button:focus .symbol_search,
.filter_block.theme_gray .filter_search_button:hover .symbol_search {
  fill: #C8102E;
}

.filter_block.theme_gray .filter_alpha_item:focus,
.filter_block.theme_gray .filter_alpha_item:hover,
.filter_block.theme_gray .filter_bar_item:focus,
.filter_block.theme_gray .filter_bar_item:hover {
  color: #C8102E;
}

.filter_block.theme_gray .filter_alpha_item.active,
.filter_block.theme_gray .filter_bar_item.active {
  background-color: #264db6;
  color: #fff;
}

.section_table_block {
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .section_table_block {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .section_table_block {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width:1200px) {
  .section_table_block {
    margin-bottom: 80px;
  }
}

.section_table_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  color: #333;
  margin: 0 0 16px;
}

@media only screen and (min-width:768px) {
  .section_table_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:992px) {
  .section_table_title {
    margin-bottom: 26px;
  }
}

.section_table_body_inner .table_wrapper {
  margin: 0;
}

.section_table_body_inner table {
  font-size: 18px;
  font-size: 1.125rem;
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  color: #333;
  line-height: 1.22222222;
}

.section_table_body_inner table tr {
  position: relative;
}

.section_table_body_inner table tr:nth-child(even) {
  background-color: #f3f4f5;
}

.section_table_body_inner table tr.minimized td {
  padding-bottom: 0;
}

.section_table_body_inner table tr.minimized .section_table_cell {
  height: 90px;
  overflow: hidden;
}

.section_table_body_inner table tr.minimized .section_table_toggle:before {
  opacity: 1;
}

.section_table_body_inner table tr.maximized .section_table_cell {
  height: auto;
}

.section_table_body_inner table tr.maximized .section_table_toggle:before {
  opacity: 0;
}

.section_table_body_inner table .toggle_row td {
  padding-bottom: 60px;
}

.section_table_body_inner table td,
.section_table_body_inner table th {
  position: relative;
  padding-right: 14px;
  padding-left: 14px;
  vertical-align: top;
}

@media print {
  .section_table_body_inner table td,
  .section_table_body_inner table th {
    border-bottom: 1px solid #a7a8aa;
  }
}

.section_table_body_inner table td:first-child,
.section_table_body_inner table th:first-child {
  padding-left: 60px;
}

@media print {
  .section_table_body_inner table td:first-child,
  .section_table_body_inner table th:first-child {
    padding-left: 0;
  }
}

@media print {
  .section_table_body_inner table td:last-child,
  .section_table_body_inner table th:last-child {
    padding-right: 0;
  }
}

.section_table_body_inner table th,
.section_table_body_inner table thead td {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  font-size: .8125rem;
  background-color: #072f4f;
  color: #fff;
  letter-spacing: 2.36px;
  line-height: 1.3;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}

.section_table_body_inner table td {
  padding-top: 0;
  padding-bottom: 20px;
}

.section_table_body_inner table tfoot {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: italic;
  font-weight: 400;
}

.section_table_body_inner table tfoot td {
  padding-left: 14px;
  padding-right: 14px;
}

@media only screen and (min-width:992px) {
  .section_table_body_inner table tfoot td {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width:992px) {
  .section_table_body_inner .table_wrapper_overflow table tfoot td {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.section_table_button {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  background-color: #C8102E;
  color: #fff;
  white-space: nowrap;
}

.section_table_button:focus,
.section_table_button:hover {
  background-color: #333;
}

.support_block {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

@media only screen and (min-width:768px) {
  .support_block {
    padding-top: 40px;
  }
}

@media only screen and (min-width:992px) {
  .support_block {
    padding-top: 60px;
  }
}

@media only screen and (min-width:768px) {
  .support_block {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .support_block {
    padding-bottom: 60px;
  }
}

.in_content_callouts .support_block,
.sidebar .support_block {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .support_block,
  .sidebar .support_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .support_block,
  .sidebar .support_block {
    margin-bottom: 40px;
  }
}

.sidebar .support_block {
  border-radius: 5px;
}

@media only screen and (min-width:992px) {
  .sidebar .support_block {
    background: 0 0;
    color: #000;
    padding: 0;
  }
}

@media print and (min-width:768px) {
  .support_block .support_card,
  .support_block .support_card.fs-carousel-item {
    width: 48%!important;
    width: calc(50% - 10px)!important;
    float: left;
    margin: 30px 0;
  }
}

@media print and (min-width:768px) {
  .support_block .support_card.fs-carousel-item:nth-child(odd),
  .support_block .support_card:nth-child(odd) {
    clear: left;
  }
}

@media print and (min-width:768px) {
  .support_block .support_card.fs-carousel-item:nth-child(even),
  .support_block .support_card:nth-child(even) {
    float: right;
  }
}

.in_content_callouts .support_block .support_card,
.in_content_callouts .support_block .support_card.fs-carousel-item,
.sidebar .support_block .support_card,
.sidebar .support_block .support_card.fs-carousel-item {
  transition: opacity .25s ease;
}

.in_content_callouts .support_block .support_card.fs-carousel-item.fs-carousel-item_next,
.in_content_callouts .support_block .support_card.fs-carousel-item.fs-carousel-item_previous,
.in_content_callouts .support_block .support_card.fs-carousel-item_next,
.in_content_callouts .support_block .support_card.fs-carousel-item_previous,
.sidebar .support_block .support_card.fs-carousel-item.fs-carousel-item_next,
.sidebar .support_block .support_card.fs-carousel-item.fs-carousel-item_previous,
.sidebar .support_block .support_card.fs-carousel-item_next,
.sidebar .support_block .support_card.fs-carousel-item_previous {
  opacity: .5;
}

.no-js .support_block .support_card,
.no-js .support_block .support_card.fs-carousel-item {
  display: inline-block;
  width: 320px;
  margin: 40px 10px 20px;
  vertical-align: top;
  white-space: normal;
}

@media only screen and (min-width:768px) {
  .no-js .support_block .support_card,
  .no-js .support_block .support_card.fs-carousel-item {
    width: 600px;
  }
}

@media print {
  .no-js .support_block .support_card,
  .no-js .support_block .support_card.fs-carousel-item {
    margin: 30px 0;
  }
}

.in_content_callouts .support_inner {
  padding: 0 20px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .support_inner {
    padding: 0 30px;
  }
}

@media only screen and (min-width:1200px) {
  .in_content_callouts .support_inner {
    padding: 0 50px;
  }
}

.sidebar .support_inner {
  padding: 0 20px;
}

@media only screen and (min-width:768px) {
  .sidebar .support_inner {
    padding: 0 30px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_inner {
    padding: 0;
  }
}

.support_header {
  margin-bottom: 20px;
}

.sidebar .support_header {
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .support_header {
    margin-bottom: 50px;
  }
}

.support_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin: 10px 0 12px;
}

@media only screen and (min-width:768px) {
  .support_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_title {
    font-size: 24px;
    margin-top: 0;
  }
}

@media only screen and (min-width:992px) {
  .in_content_callouts .support_title {
    margin-top: 0;
  }
}

@media only screen and (min-width:768px) {
  .support_title {
    margin-top: 20px;
  }
}

.support_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin: 12px 0;
}

@media only screen and (min-width:768px) {
  .support_description {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.52380952;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_description {
    font-size: 16px;
  }
}

.support_description p {
  margin: 0;
}

.support_carousel {
  margin-bottom: 10px;
}

@media print and (min-width:768px) {
  .support_carousel {
    overflow: hidden;
  }
}

.no-js .support_carousel {
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

@media print {
  .no-js .support_carousel {
    overflow: auto;
    white-space: normal;
  }
}

@media only screen and (max-width:991px) {
  .support_carousel .fs-carousel-viewport {
    max-width: 600px;
  }
}

.support_carousel .fs-carousel-canister {
  overflow: visible;
}

@media print and (min-width:768px) {
  .support_carousel .fs-carousel-canister {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media print {
  .support_carousel .fs-carousel-item {
    width: auto!important;
    height: auto!important;
    float: none;
  }
}

@media only screen and (max-width:1199px) {
  .support_carousel .fs-carousel-item:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (min-width:1200px) {
  .support_carousel .fs-carousel-item:not(:last-child) {
    margin-right: 30px;
  }
}

@media only screen and (max-width:767px) {
  .support_carousel .fs-carousel-controls {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .support_carousel .fs-carousel-controls {
    width: calc(740px - 30px);
  }
}

@media only screen and (min-width:992px) {
  .in_content_callouts .support_carousel .fs-carousel-controls {
    width: 616px;
  }
}

@media only screen and (min-width:1200px) {
  .in_content_callouts .support_carousel .fs-carousel-controls {
    width: 750px;
  }
}

@media only screen and (min-width:768px) {
  .sidebar .support_carousel .fs-carousel-controls {
    width: calc(740px - 30px);
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_carousel .fs-carousel-controls {
    width: 210px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .support_carousel .fs-carousel-controls {
    width: 260px;
  }
}

@media only screen and (min-width:992px) {
  .in_content_callouts .support_carousel .fs-carousel-control {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_carousel .fs-carousel-control {
    width: 32px;
    height: 32px;
  }

  .sidebar .support_carousel .fs-carousel-control .icon {
    width: 12px;
    height: 18px;
  }

  .sidebar .support_carousel .fs-carousel-control .icon .symbol {
    width: 12px;
    height: 18px;
  }
}

.support_carousel .carousel_count {
  margin-top: 18px;
}

.support_block.theme_grey {
  background: #333;
  color: #fff;
}

@media only screen and (min-width:992px) {
  .sidebar .support_block.theme_grey {
    background: 0 0;
    color: #000;
  }
}

.support_block.theme_blue {
  background-color: #072f4f;
  color: #fff;
}

@media only screen and (min-width:992px) {
  .sidebar .support_block.theme_blue {
    background: 0 0;
    color: #000;
  }
}

.testimonial {
  background: #333;
  margin-bottom: 0;
}

.in_content_callouts .testimonial {
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .testimonial {
    margin-bottom: 40px;
  }
}

.sidebar .testimonial {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .sidebar .testimonial {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .sidebar .testimonial {
    margin-bottom: 40px;
  }
}

.in_content_callouts .testimonial,
.sidebar .testimonial {
  border-radius: 5px;
}

.layout_program_detail .testimonial {
  margin-top: 0;
  margin-bottom: 0;
}

.testimonial_inner {
  position: relative;
  padding: 40px 0;
}

.testimonial_inner:after {
  clear: both;
  content: "";
  display: table;
}

.in_content_callouts .testimonial_inner {
  padding: 40px 20px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .testimonial_inner {
    padding: 40px 30px;
  }
}

@media only screen and (min-width:1200px) {
  .in_content_callouts .testimonial_inner {
    padding: 60px 30px;
  }
}

.sidebar .testimonial_inner {
  padding: 40px 20px;
}

@media only screen and (min-width:992px) {
  .sidebar .testimonial_inner {
    padding: 40px 20px 30px;
  }
}

@media only screen and (min-width:768px) {
  .testimonial_inner {
    padding: 75px 0;
  }
}

@media only screen and (min-width:992px) {
  .testimonial_inner {
    padding: 110px 0 100px;
  }
}

.testimonial_icon_wrapper {
  height: 80px;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background: #5fc64d;
  border-radius: 50%;
  color: #fff;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -ms-transform: translate(0,-40%) scale(.5);
  transform: translate(0,-40%) scale(.5);
}

.in_content_callouts .testimonial_icon_wrapper {
  left: 20px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .testimonial_icon_wrapper {
    left: 30px;
  }
}

.sidebar .testimonial_icon_wrapper {
  left: 20px;
}

@media only screen and (min-width:992px) {
  .testimonial_icon_wrapper {
    -ms-transform: translate(0,-40%) scale(1);
    transform: translate(0,-40%) scale(1);
  }

  .in_content_callouts .testimonial_icon_wrapper,
  .sidebar .testimonial_icon_wrapper {
    -ms-transform: translate(0,-40%) scale(.5);
    transform: translate(0,-40%) scale(.5);
  }
}

@media only screen and (min-width:1200px) {
  .in_content_callouts .testimonial_icon_wrapper {
    -ms-transform: translate(0,-40%) scale(.7);
    transform: translate(0,-40%) scale(.7);
  }
}

.testimonial_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.testimonial_icon svg {
  height: 25px;
  width: 30px;
  display: block;
  fill: #fff;
}

.testimonial_image {
  max-width: 365px;
  width: 30%;
  float: right;
  border-radius: 5px;
  margin-top: -60px;
  margin-bottom: 20px;
  margin-left: 20px;
}

@media only screen and (min-width:992px) {
  .testimonial_image {
    margin-top: -140px;
    margin-left: 40px;
  }

  .in_content_callouts .testimonial_image {
    margin-top: -80px;
    margin-left: 20px;
  }

  .sidebar .testimonial_image {
    display: none;
  }
}

@media only screen and (min-width:1200px) {
  .testimonial_image {
    width: 100%;
    margin-left: 60px;
  }

  .in_content_callouts .testimonial_image {
    max-width: 250px;
    margin-left: 40px;
    margin-bottom: 40px;
  }
}

.termonial_quote {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  color: #fff;
  font-weight: 400;
  margin-bottom: 30px!important;
}

@media only screen and (min-width:768px) {
  .termonial_quote {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .termonial_quote {
    font-size: 24px;
  }
}

@media only screen and (min-width:992px) {
  .in_content_callouts .termonial_quote {
    font-size: 22px;
    line-height: 1.5;
  }
}

@media only screen and (min-width:768px) {
  .sidebar .termonial_quote {
    font-size: 24px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .termonial_quote {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px!important;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .termonial_quote {
    font-size: 18px;
  }
}

@media only screen and (min-width:768px) {
  .termonial_quote {
    line-height: 1.375;
  }
}

@media only screen and (min-width:1200px) {
  .termonial_quote {
    margin-bottom: 48px!important;
  }
}

.testimonial_name {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  display: block;
  color: #5fc64d;
  margin: 0 0 3px;
}

@media only screen and (min-width:768px) {
  .testimonial_name {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.testimonial_field {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  display: block;
  color: #fff;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (min-width:992px) {
  .sidebar .testimonial_field {
    font-size: 14px;
    letter-spacing: 0;
  }
}

.accordion_title {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.16666667;
}

@media only screen and (min-width:768px) {
  .accordion_title {
    font-size: 48px;
    font-size: 3rem;
  }
}

.accordion.theme_red_brand .accordion_title,
.accordion_title.theme_red_brand {
  background-color: #fff;
  color: #C8102E;
}

.accordion.theme_orange_red .accordion_title,
.accordion_title.theme_orange_red {
  background-color: #fff;
  color: #df3226;
}

.accordion.theme_teal .accordion_title,
.accordion_title.theme_teal {
  background-color: #fff;
  color: #187b79;
}

.accordion.theme_purple .accordion_title,
.accordion_title.theme_purple {
  background-color: #fff;
  color: #9450b2;
}

.accordion_item {
  background: #f3f4f5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.accordion.theme_program .accordion_item {
  background-color: #fff;
  border: 1px solid #a7a8aa;
  border-radius: 4px;
}

.accordion.theme_program_alt .accordion_item {
  background-color: #fff;
  border-top: 1px solid #CCC;
  margin-bottom: 0;
}

.accordion.theme_red_brand .accordion_item {
  background-color: #C8102E;
  color: #fff;
}

.accordion.theme_red_brand .accordion_item ::-moz-selection {
  background-color: #930a28;
  color: #fff;
}

.accordion.theme_red_brand .accordion_item ::selection {
  background-color: #930a28;
  color: #fff;
}

.accordion.theme_orange_red .accordion_item {
  background-color: #df3226;
  color: #fff;
}

.accordion.theme_orange_red .accordion_item ::-moz-selection {
  background-color: #B2B2B2;
  color: #000;
}

.accordion.theme_orange_red .accordion_item ::selection {
  background-color: #B2B2B2;
  color: #000;
}

.accordion.theme_teal .accordion_item {
  background-color: #187b79;
  color: #fff;
}

.accordion.theme_teal .accordion_item ::-moz-selection {
  background-color: #B2B2B2;
  color: #000;
}

.accordion.theme_teal .accordion_item ::selection {
  background-color: #B2B2B2;
  color: #000;
}

.accordion.theme_purple .accordion_item {
  background-color: #9450b2;
  color: #fff;
}

.accordion.theme_purple .accordion_item ::-moz-selection {
  background-color: #B2B2B2;
  color: #000;
}

.accordion.theme_purple .accordion_item ::selection {
  background-color: #B2B2B2;
  color: #000;
}

.accordion_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 20px 70px 20px 20px;
}

@media only screen and (min-width:768px) {
  .accordion_item_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.accordion_item_title:focus {
  outline: 0;
}

.accordion_item.is_open .accordion_item_title {
  outline: 0;
}

@media print {
  .accordion_item_title {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width:992px) {
  .accordion_item_title {
    padding: 32px 70px 32px 30px;
  }

  .accordion.theme_program .accordion_item_title {
    padding-left: 37px;
    padding-right: 37px;
  }
}

.accordion.theme_program_alt .accordion_item_title {
  padding-left: 0;
  padding-right: 30px;
}

.accordion_item_icon_wrapper {
  height: 36px;
  width: 36px;
  position: absolute;
  top: 50%;
  right: 20px;
  background: #293035;
  border-radius: 50%;
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
  transition: background .25s;
}

.fs-swap-active .accordion_item_icon_wrapper {
  background-color: #C8102E;
}

.accordion.theme_purple .fs-swap-active .accordion_item_icon_wrapper,
.accordion.theme_red_brand .fs-swap-active .accordion_item_icon_wrapper,
.accordion.theme_teal .fs-swap-active .accordion_item_icon_wrapper {
  background: #293035;
}

.no-js .accordion_item_icon_wrapper {
  display: none!important;
  visibility: hidden;
}

.accordion_item_title:focus .accordion_item_icon_wrapper,
.accordion_item_title:hover .accordion_item_icon_wrapper {
  background: #C8102E;
}

.accordion.theme_purple .accordion_item_title:focus .accordion_item_icon_wrapper,
.accordion.theme_purple .accordion_item_title:hover .accordion_item_icon_wrapper,
.accordion.theme_red_brand .accordion_item_title:focus .accordion_item_icon_wrapper,
.accordion.theme_red_brand .accordion_item_title:hover .accordion_item_icon_wrapper,
.accordion.theme_teal .accordion_item_title:focus .accordion_item_icon_wrapper,
.accordion.theme_teal .accordion_item_title:hover .accordion_item_icon_wrapper {
  background: #293035;
}

.fs-swap-active .accordion_item_icon_wrapper,
.is_open .accordion_item_icon_wrapper {
  background: #C8102E;
}

.accordion.theme_purple .fs-swap-active .accordion_item_icon_wrapper,
.accordion.theme_purple .is_open .accordion_item_icon_wrapper,
.accordion.theme_red_brand .fs-swap-active .accordion_item_icon_wrapper,
.accordion.theme_red_brand .is_open .accordion_item_icon_wrapper,
.accordion.theme_teal .fs-swap-active .accordion_item_icon_wrapper,
.accordion.theme_teal .is_open .accordion_item_icon_wrapper {
  background: #293035;
}

.fs-swap-active .accordion_item_icon_wrapper .accordion_item_icon:first-child,
.is_open .accordion_item_icon_wrapper .accordion_item_icon:first-child {
  display: none;
}

.fs-swap-active .accordion_item_icon_wrapper .accordion_item_icon:last-child,
.is_open .accordion_item_icon_wrapper .accordion_item_icon:last-child {
  display: block;
}

@media only screen and (min-width:992px) {
  .accordion.theme_program .accordion_item_icon_wrapper {
    right: 37px;
  }
}

.accordion.theme_program_alt .accordion_item_icon_wrapper {
  right: 0;
  height: 22px;
  width: 22px;
  background-color: #C8102E;
}

.accordion_item_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.accordion_item_icon svg {
  height: 18px;
  width: 18px;
  display: block;
  fill: #fff;
}

.accordion.theme_program_alt .accordion_item_icon svg {
  height: 15px;
  width: 10px;
}

.accordion_item_description {
  padding: 0 20px 20px;
}

@media print {
  .accordion_item_description {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width:992px) {
  .accordion_item_description {
    padding: 0 32px 30px;
  }

  .accordion.theme_program .accordion_item_description {
    padding-left: 37px;
    padding-right: 37px;
  }
}

.fs-swap-enabled .accordion_item_description {
  display: none;
}

@media print {
  .fs-swap-enabled .accordion_item_description {
    display: block;
  }
}

.fs-swap-active .accordion_item_description {
  display: block;
}

.accordion.theme_program_alt .accordion_item_description {
  padding-left: 0;
  padding-right: 0;
}

.accordion_requirement_header {
  border-bottom: 3px solid #c6c8cc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.accordion_requirement_title {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  color: #C8102E;
  font-weight: 700;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .accordion_requirement_title {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.accordion_requirement_caption {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  color: #0b263b;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .accordion_requirement_caption {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.accordion_step_title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .accordion_step_body {
    padding: 0 30px;
  }
}

@media print {
  .accordion_step_table tr {
    border-bottom: 1px solid #a7a8aa;
  }
}

.accordion_item_title_head {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  margin: 0 -15px -5px;
}

@media only screen and (min-width:992px) {
  .accordion_item_title_head {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.accordion_item_title_head>* {
  margin-bottom: 5px;
  padding: 0 15px;
}

@media only screen and (min-width:992px) {
  .accordion_item_title_head>* {
    width: 27.5%;
  }

  .accordion_item_title_head>:first-child {
    width: 45%;
  }
}

@media only screen and (min-width:992px) {
  .accordion_item_title.is_open .accordion_item_title_col_head.show_if_inactive {
    display: none;
  }
}

.accordion_item_title_col_head.show_if_active {
  display: none;
}

@media only screen and (min-width:992px) {
  .accordion_item_title.is_open .accordion_item_title_col_head.show_if_active {
    display: block;
  }
}

.accordion_item_title_head_subtitle {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
}

.accordion_item_title_head_text_inactive {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-size: .875rem;
  margin: 0;
}

@media only screen and (min-width:992px) {
  .accordion_item_title_head_text_inactive {
    padding-right: 57px;
    text-align: right;
  }
}

blockquote {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 0;
  margin-left: 0;
}

@media only screen and (min-width:768px) {
  blockquote {
    margin-top: 40px;
  }
}

@media only screen and (min-width:992px) {
  blockquote {
    margin-top: 60px;
  }
}

@media only screen and (min-width:768px) {
  blockquote {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  blockquote {
    margin-bottom: 60px;
  }
}

blockquote p {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin-top: 0!important;
  margin-bottom: 0!important;
}

@media only screen and (min-width:768px) {
  blockquote p {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

@media print {
  .quote_content {
    border-width: 0;
  }
}

.quote_content p {
  display: inline-block;
  vertical-align: top;
}

@media screen and (min-width:500px) {
  .quote_content p {
    width: calc(100% - 56px);
    padding-left: 20px;
  }
}

@media print,only screen and (min-width:768px) {
  .quote_content p {
    width: calc(100% - 76px);
    padding-left: 40px;
  }
}

.quote_icon {
  height: 40px;
  width: 40px;
  position: relative;
  background: #264db6;
  color: #fff;
  display: inline-block;
  line-height: 1;
  vertical-align: top;
}

@media print {
  .quote_icon {
    border: 1px solid #000;
  }
}

@media screen and (max-width:499px) {
  .quote_icon {
    margin-bottom: 10px;
  }
}

@media print,only screen and (min-width:768px) {
  .quote_icon {
    height: 66px;
    width: 66px;
  }
}

.quote_icon::before {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  position: absolute;
  top: 70%;
  left: 50%;
  content: "\201C";
  font-size: 42px;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

@media print,only screen and (min-width:768px) {
  .quote_icon::before {
    top: 65%;
    font-size: 52px;
  }
}

.button_set_grid {
  position: relative;
  z-index: 1;
}

@media print {
  .button_set_grid {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:1200px) {
  .button_set_grid .button_set_inner:after {
    clear: both;
    content: "";
    display: table;
  }
}

.button_set_grid .button_set_inner .button_item {
  margin-bottom: 25px;
}

@media only screen and (min-width:1200px) {
  .button_set_grid .button_set_inner .button_item {
    width: 48%;
    width: calc(50% - 12px);
  }
}

@media only screen and (min-width:1200px) {
  .button_set_grid .button_set_inner .button_item:nth-child(even) {
    float: right;
    clear: none;
  }
}

@media only screen and (min-width:1200px) {
  .button_set_grid .button_set_inner .button_item:nth-child(odd) {
    float: left;
    clear: both;
  }
}

.button_set_grid .button_set_inner .button_item:nth-child(n+1) .button_item_link {
  background-color: #C8102E!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #c8102e;
  color: #fff;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+1) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item:nth-child(n+1) .button_item_link:hover {
  background-color: #333!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #333;
  color: #fff;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+2) .button_item_link {
  background-color: #264db6!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #264db6;
  color: #fff;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+2) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item:nth-child(n+2) .button_item_link:hover {
  background-color: #072f4f!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #072f4f;
  color: #fff;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+3) .button_item_link {
  background-color: #5fc64d!important;
  color: #fff!important;
  fill: #fff!important;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+3) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item:nth-child(n+3) .button_item_link:hover {
  background-color: #02a6ab!important;
  color: #fff!important;
  fill: #fff!important;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+4) .button_item_link {
  background-color: #333!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #333;
  color: #fff;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item:nth-child(n+4) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item:nth-child(n+4) .button_item_link:hover {
  background-color: #930a28!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #930a28;
  color: #fff;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item .button_item_link {
  display: block;
  background-color: #C8102E;
  border-radius: 5px;
  color: #fff;
}

.button_set_grid .button_set_inner .button_item .button_item_link:focus,
.button_set_grid .button_set_inner .button_item .button_item_link:hover {
  background-color: #333;
}

.button_set_grid .button_set_inner .button_item .button_item_link:focus .symbol_arrow_right,
.button_set_grid .button_set_inner .button_item .button_item_link:hover .symbol_arrow_right {
  fill: #fff;
}

[data-whatinput=keyboard] .button_set_grid .button_set_inner .button_item .button_item_link:focus .button_tooltip,
[data-whatinput=keyboard] .button_set_grid .button_set_inner .button_item .button_item_link:hover .button_tooltip {
  opacity: 1;
  pointer-events: auto;
  -ms-transform: translateX(15px) translateY(-50%);
  transform: translateX(15px) translateY(-50%);
  visibility: visible;
}

@media only screen and (min-width:1200px) {
  [data-whatinput=keyboard] .button_set_grid .button_set_inner .button_item .button_item_link:focus .button_tooltip,
  [data-whatinput=keyboard] .button_set_grid .button_set_inner .button_item .button_item_link:hover .button_tooltip {
    -ms-transform: translateX(20px) translateY(-50%);
    transform: translateX(20px) translateY(-50%);
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .symbol_arrow_right {
  transition: fill .25s;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner {
  position: relative;
  padding: 20px;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner {
    padding: 35px 25px;
  }
}

@media only screen and (min-width:992px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner {
    padding: 35px 80px;
  }
}

@media only screen and (min-width:1200px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner {
    padding: 35px 80px;
  }
}

@media only screen and (min-width:992px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group {
    text-align: center;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  display: block;
  margin: 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title:focus .icon,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .label {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .symbol_arrow_right {
  width: 14px;
  height: 14px;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  margin: 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label:focus .icon,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .label {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .label {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .icon {
  top: 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_label .symbol_arrow_right {
  width: 14px;
  height: 14px;
  fill: #fff;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_help {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 0;
  right: 35px;
  bottom: 0;
  margin: auto 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_help:focus,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_help:hover {
  z-index: 1;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  color: #333;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .label.focusable:active,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .label.focusable:active,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .icon {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  display: block;
  line-height: 34px;
  margin-bottom: -2px;
  vertical-align: middle;
}

@media only screen and (max-width:991px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon {
    display: none!important;
    visibility: hidden;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item_icon .icon {
  color: #333;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
  position: absolute;
  width: 310px;
  top: 50%;
  left: 65px;
  z-index: 5;
  background-color: #f3f4f5;
  border: 2px solid #264db6;
  border-radius: 5px;
  color: #333;
  opacity: 0;
  padding: 10px 30px;
  pointer-events: none;
  -ms-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  transition: opacity .4s,transform .4s,visibility .4s;
  visibility: hidden;
}

@media only screen and (max-width:991px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:1200px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
    width: 375px;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip:before {
  display: block;
  position: absolute;
  width: 80px;
  height: 100%;
  top: 0;
  right: 100%;
  content: "";
}

.button_item_help:focus .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip,
.button_item_help:hover .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip,
.touchevents .button_item_help.fs-swap-active .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
  opacity: 1;
  pointer-events: auto;
  -ms-transform: translateX(15px) translateY(-50%);
  transform: translateX(15px) translateY(-50%);
  visibility: visible;
}

@media only screen and (min-width:1200px) {
  .button_item_help:focus .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip,
  .button_item_help:hover .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip,
  .touchevents .button_item_help.fs-swap-active .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
    -ms-transform: translateX(20px) translateY(-50%);
    transform: translateX(20px) translateY(-50%);
  }
}

@media (pointer:coarse) {
  .button_item_help.fs-swap-active .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
    opacity: 1;
    pointer-events: auto;
    -ms-transform: translateX(15px) translateY(-50%);
    transform: translateX(15px) translateY(-50%);
    visibility: visible;
  }
}

@media screen and (min-width:1200px) and (pointer:coarse) {
  .button_item_help.fs-swap-active .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip {
    -ms-transform: translateX(20px) translateY(-50%);
    transform: translateX(20px) translateY(-50%);
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  list-style: none;
  margin-bottom: 30px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_description ul {
  list-style: none;
  padding-left: 20px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_description ul li {
  margin: 10px 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_description ul li:before {
  width: 5px;
  height: 5px;
  background: #C8102E;
  border-radius: 50%;
  content: "";
  display: block;
  float: left;
  margin: 12px 0 0 -15px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration_wrap {
  position: absolute;
  top: 50%;
  right: 100%;
  right: calc(100% + 8px);
  margin-top: -11px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle {
  position: relative;
  display: block;
  width: 23px;
  height: 23px;
  -ms-transform: rotate(-150deg) skewX(-30deg) scale(1,.866);
  transform: rotate(-150deg) skewX(-30deg) scale(1,.866);
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle:after,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle:before {
  position: absolute;
  background-color: inherit;
  content: "";
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle:after,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle:before {
  border-top-right-radius: 4px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle:before {
  -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle:after {
  -ms-transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.large {
  background-color: #264db6;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.large,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.large:after,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.large:before {
  width: 23px;
  height: 23px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.small {
  background-color: #f3f4f5;
  -ms-transform: rotate(-150deg) skewX(-30deg) scale(1,.866) translateX(21px) translateY(25px);
  transform: rotate(-150deg) skewX(-30deg) scale(1,.866) translateX(21px) translateY(25px);
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.small,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.small:after,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_tooltip_decoration .triangle.small:before {
  width: 23px;
  height: 23px;
}

@media only screen and (min-width:992px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle {
    display: none!important;
    visibility: hidden;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle {
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 15px 0;
  padding: 0 20px;
  text-align: left;
  transition: color .25s;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle:after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (min-width:992px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle {
    display: none!important;
    visibility: hidden;
  }
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle:focus,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle:hover {
  color: #C8102E;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle:focus .button_toggle_icon_wrap,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle:hover .button_toggle_icon_wrap {
  background-color: #C8102E;
}

.no-js .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle {
  cursor: default;
  pointer-events: none;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_handle .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  float: left;
  display: block;
  letter-spacing: 0;
  padding: 8px 0;
  margin-right: 20px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
  text-align: center;
  float: right;
  background-color: #333;
  transition: background .25s;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .label.focusable:active,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .label.focusable:active,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .icon {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  display: block;
  line-height: 34px;
  margin-bottom: -2px;
  vertical-align: middle;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_icon_wrap .icon {
  color: #fff;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  list-style: none;
  margin-bottom: 30px;
  padding: 0 20px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description ul {
  list-style: none;
  padding-left: 20px;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description ul li {
  margin: 10px 0;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description ul li:before {
  width: 5px;
  height: 5px;
  background: #C8102E;
  border-radius: 50%;
  content: "";
  display: block;
  float: left;
  margin: 12px 0 0 -15px;
}

@media only screen and (min-width:768px) {
  .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.button_toggle.js-bound .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description {
  display: none;
}

.button_toggle.js-toggle_active .button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_toggle_description {
  display: block;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+2) .button_item_link {
  background-color: #5fc64d;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+2) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+2) .button_item_link:hover {
  background-color: #02a6ab;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+3) .button_item_link {
  background-color: #264db6;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+3) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+3) .button_item_link:hover {
  background-color: #072f4f;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+4) .button_item_link {
  background-color: #991e66;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+4) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+4) .button_item_link:hover {
  background-color: #C8102E;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+5) .button_item_link {
  background-color: #f4da40;
}

.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+5) .button_item_link:focus,
.button_set_grid .button_set_inner .button_item .button_item_link .button_item_link_inner .button_item_group .button_item_title .button_item:nth-child(4n+5) .button_item_link:hover {
  background-color: #C8102E;
}

.button_set_block {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width:768px) {
  .button_set_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .button_set_block {
    margin-bottom: 40px;
  }
}

.full_width_callouts .button_set_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .button_set_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .button_set_block {
    margin-top: 60px;
  }
}

@media only screen and (min-width:768px) {
  .full_width_callouts .button_set_block {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .button_set_block {
    margin-bottom: 60px;
  }
}

@media print {
  .button_set_block {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:1200px) {
  .button_set_inner:after {
    clear: both;
    content: "";
    display: table;
  }
}

.button_item {
  margin-bottom: 25px;
}

@media only screen and (min-width:1200px) {
  .button_item:nth-child(n+2) {
    float: left;
    width: 48%;
    width: calc(50% - 12px);
  }

  .full_width_callouts .button_item:nth-child(n+2),
  .in_content_callouts .button_item:nth-child(n+2) {
    margin-bottom: 0;
  }

  .sidebar .button_item:nth-child(n+2) {
    width: 100%;
  }
}

@media only screen and (min-width:1200px) {
  .button_item:nth-child(2n+3) {
    float: right;
  }
}

@media only screen and (min-width:1200px) {
  .button_item:nth-child(even) {
    clear: both;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .button_item:last-child {
    margin-bottom: 0;
  }
}

.button_item_link {
  display: block;
  background-color: #C8102E;
  border-radius: 5px;
  color: #fff;
}

.button_item_link:focus,
.button_item_link:hover {
  background-color: #333;
}

.button_item_link:focus .symbol_arrow_right,
.button_item_link:hover .symbol_arrow_right {
  fill: #fff;
}

[data-whatinput=keyboard] .button_item_link:focus .button_tooltip,
[data-whatinput=keyboard] .button_item_link:hover .button_tooltip {
  opacity: 1;
  pointer-events: auto;
  -ms-transform: translateX(15px) translateY(-50%);
  transform: translateX(15px) translateY(-50%);
  visibility: visible;
}

@media only screen and (min-width:1200px) {
  [data-whatinput=keyboard] .button_item_link:focus .button_tooltip,
  [data-whatinput=keyboard] .button_item_link:hover .button_tooltip {
    -ms-transform: translateX(20px) translateY(-50%);
    transform: translateX(20px) translateY(-50%);
  }
}

.button_item_link .symbol_arrow_right {
  transition: fill .25s;
}

.button_item_link_inner {
  position: relative;
  padding: 20px;
}

@media only screen and (min-width:768px) {
  .button_item_link_inner {
    padding: 35px 25px;
  }
}

@media only screen and (min-width:992px) {
  .button_item_link_inner {
    padding: 35px 80px;
  }

  .sidebar .button_item_link_inner {
    padding: 20px;
  }
}

@media only screen and (min-width:992px) {
  .button_item:first-child .button_item_link_inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sidebar .button_item:first-child .button_item_link_inner {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width:1200px) {
  .button_item:first-child .button_item_link_inner {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media only screen and (min-width:992px) {
  .button_item_group {
    text-align: center;
  }
}

.button_item_title {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  display: block;
  margin: 0;
}

.button_item_title .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .button_item_title .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.button_item_title .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.button_item_title .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.button_item_title .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.button_item_title:focus .icon,
.button_item_title:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.button_item_title .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .button_item_title .label {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

@media only screen and (min-width:992px) {
  .button_item:first-child .button_item_title .label {
    font-size: 34px;
    font-size: 2.125rem;
  }

  .sidebar .button_item:first-child .button_item_title .label {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.button_item_title .symbol_arrow_right {
  width: 14px;
  height: 14px;
  fill: #fff;
}

@media only screen and (min-width:992px) {
  .button_item:first-child .button_item_title .symbol_arrow_right {
    width: 22px;
    height: 22px;
  }
}

.button_item_label {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  margin: 0;
}

.button_item_label .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .button_item_label .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.button_item_label .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.button_item_label .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.button_item_label .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.button_item_label:focus .icon,
.button_item_label:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.button_item_label .label {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
}

@media only screen and (min-width:768px) {
  .button_item_label .label {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.button_item_label .icon {
  top: 0;
}

.button_item_label .symbol_arrow_right {
  width: 14px;
  height: 14px;
  fill: #fff;
}

.button_item_help {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 0;
  right: 35px;
  bottom: 0;
  margin: auto 0;
}

.button_item_help:focus,
.button_item_help:hover {
  z-index: 1;
}

@media only screen and (min-width:992px) {
  .sidebar .button_item_help {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: 10px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.button_item_icon {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  color: #333;
}

.button_item_icon .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.button_item_icon .label.focusable:active,
.button_item_icon .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_item_icon .label.focusable:active,
.button_item_icon .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_item_icon .icon {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  display: block;
  line-height: 34px;
  margin-bottom: -2px;
  vertical-align: middle;
}

@media only screen and (max-width:991px) {
  .button_item_icon {
    display: none!important;
    visibility: hidden;
  }
}

.button_item_icon .icon {
  color: #333;
}

.button_tooltip {
  position: absolute;
  width: 310px;
  top: 50%;
  left: 65px;
  z-index: 5;
  background-color: #f3f4f5;
  border: 2px solid #264db6;
  border-radius: 5px;
  color: #333;
  opacity: 0;
  padding: 10px 30px;
  pointer-events: none;
  -ms-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  transition: opacity .4s,transform .4s,visibility .4s;
  visibility: hidden;
}

@media only screen and (max-width:991px) {
  .button_tooltip {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .button_tooltip,
  .sidebar .button_tooltip {
    left: auto;
    right: 100px;
  }
}

@media only screen and (min-width:1200px) {
  .button_tooltip {
    width: 375px;
  }
}

.button_tooltip:before {
  display: block;
  position: absolute;
  width: 80px;
  height: 100%;
  top: 0;
  right: 100%;
  content: "";
}

.button_item_help:focus .button_tooltip,
.button_item_help:hover .button_tooltip,
.touchevents .button_item_help.fs-swap-active .button_tooltip {
  opacity: 1;
  pointer-events: auto;
  -ms-transform: translateX(15px) translateY(-50%);
  transform: translateX(15px) translateY(-50%);
  visibility: visible;
}

@media only screen and (min-width:1200px) {
  .button_item_help:focus .button_tooltip,
  .button_item_help:hover .button_tooltip,
  .touchevents .button_item_help.fs-swap-active .button_tooltip {
    -ms-transform: translateX(20px) translateY(-50%);
    transform: translateX(20px) translateY(-50%);
  }
}

@media (pointer:coarse) {
  .button_item_help.fs-swap-active .button_tooltip {
    opacity: 1;
    pointer-events: auto;
    -ms-transform: translateX(15px) translateY(-50%);
    transform: translateX(15px) translateY(-50%);
    visibility: visible;
  }
}

@media screen and (min-width:1200px) and (pointer:coarse) {
  .button_item_help.fs-swap-active .button_tooltip {
    -ms-transform: translateX(20px) translateY(-50%);
    transform: translateX(20px) translateY(-50%);
  }
}

.button_tooltip_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  list-style: none;
  margin-bottom: 30px;
}

.button_tooltip_description ul {
  list-style: none;
  padding-left: 20px;
}

.button_tooltip_description ul li {
  margin: 10px 0;
}

.button_tooltip_description ul li:before {
  width: 5px;
  height: 5px;
  background: #C8102E;
  border-radius: 50%;
  content: "";
  display: block;
  float: left;
  margin: 12px 0 0 -15px;
}

.button_tooltip_decoration_wrap {
  position: absolute;
  top: 50%;
  right: 100%;
  right: calc(100% + 8px);
  margin-top: -11px;
}

@media only screen and (min-width:992px) {
  .full_width_callouts .button_tooltip_decoration_wrap,
  .sidebar .button_tooltip_decoration_wrap {
    right: auto;
    left: calc(100% + 8px);
    -ms-transform: rotate(180deg) translateY(20px);
    transform: rotate(180deg) translateY(20px);
  }
}

.button_tooltip_decoration .triangle {
  position: relative;
  display: block;
  width: 23px;
  height: 23px;
  -ms-transform: rotate(-150deg) skewX(-30deg) scale(1,.866);
  transform: rotate(-150deg) skewX(-30deg) scale(1,.866);
}

.button_tooltip_decoration .triangle:after,
.button_tooltip_decoration .triangle:before {
  position: absolute;
  background-color: inherit;
  content: "";
}

.button_tooltip_decoration .triangle,
.button_tooltip_decoration .triangle:after,
.button_tooltip_decoration .triangle:before {
  border-top-right-radius: 4px;
}

.button_tooltip_decoration .triangle:before {
  -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}

.button_tooltip_decoration .triangle:after {
  -ms-transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.button_tooltip_decoration .triangle.large {
  background-color: #264db6;
}

.button_tooltip_decoration .triangle.large,
.button_tooltip_decoration .triangle.large:after,
.button_tooltip_decoration .triangle.large:before {
  width: 23px;
  height: 23px;
}

.button_tooltip_decoration .triangle.small {
  background-color: #f3f4f5;
  -ms-transform: rotate(-150deg) skewX(-30deg) scale(1,.866) translateX(21px) translateY(25px);
  transform: rotate(-150deg) skewX(-30deg) scale(1,.866) translateX(21px) translateY(25px);
}

.button_tooltip_decoration .triangle.small,
.button_tooltip_decoration .triangle.small:after,
.button_tooltip_decoration .triangle.small:before {
  width: 23px;
  height: 23px;
}

@media only screen and (min-width:992px) {
  .button_toggle {
    display: none!important;
    visibility: hidden;
  }
}

.button_toggle_handle {
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 15px 0;
  padding: 0 20px;
  text-align: left;
  transition: color .25s;
}

.button_toggle_handle:after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (min-width:768px) {
  .button_toggle_handle {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (min-width:992px) {
  .button_toggle_handle {
    display: none!important;
    visibility: hidden;
  }
}

.button_toggle_handle:focus,
.button_toggle_handle:hover {
  color: #C8102E;
}

.button_toggle_handle:focus .button_toggle_icon_wrap,
.button_toggle_handle:hover .button_toggle_icon_wrap {
  background-color: #C8102E;
}

.no-js .button_toggle_handle {
  cursor: default;
  pointer-events: none;
}

.button_toggle_handle .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  float: left;
  display: block;
  letter-spacing: 0;
  padding: 8px 0;
  margin-right: 20px;
}

.button_toggle_icon_wrap {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
  text-align: center;
  float: right;
  background-color: #333;
  transition: background .25s;
}

.button_toggle_icon_wrap .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.button_toggle_icon_wrap .label.focusable:active,
.button_toggle_icon_wrap .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_toggle_icon_wrap .label.focusable:active,
.button_toggle_icon_wrap .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.button_toggle_icon_wrap .icon {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  display: block;
  line-height: 34px;
  margin-bottom: -2px;
  vertical-align: middle;
}

.button_toggle_icon_wrap .icon {
  color: #fff;
}

.button_toggle_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  list-style: none;
  margin-bottom: 30px;
  padding: 0 20px;
}

.button_toggle_description ul {
  list-style: none;
  padding-left: 20px;
}

.button_toggle_description ul li {
  margin: 10px 0;
}

.button_toggle_description ul li:before {
  width: 5px;
  height: 5px;
  background: #C8102E;
  border-radius: 50%;
  content: "";
  display: block;
  float: left;
  margin: 12px 0 0 -15px;
}

@media only screen and (min-width:768px) {
  .button_toggle_description {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.button_toggle.js-bound .button_toggle_description {
  display: none;
}

.button_toggle.js-toggle_active .button_toggle_description {
  display: block;
}

.button_item:nth-child(4n+2) .button_item_link {
  background-color: #5fc64d;
}

.button_item:nth-child(4n+2) .button_item_link:focus,
.button_item:nth-child(4n+2) .button_item_link:hover {
  background-color: #02a6ab;
}

.button_item:nth-child(4n+3) .button_item_link {
  background-color: #264db6;
}

.button_item:nth-child(4n+3) .button_item_link:focus,
.button_item:nth-child(4n+3) .button_item_link:hover {
  background-color: #072f4f;
}

.button_item:nth-child(4n+4) .button_item_link {
  background-color: #991e66;
}

.button_item:nth-child(4n+4) .button_item_link:focus,
.button_item:nth-child(4n+4) .button_item_link:hover {
  background-color: #C8102E;
}

.button_item:nth-child(4n+5) .button_item_link {
  background-color: #f4da40;
}

.button_item:nth-child(4n+5) .button_item_link:focus,
.button_item:nth-child(4n+5) .button_item_link:hover {
  background-color: #C8102E;
}

.career_facts {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .career_facts {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .career_facts {
    margin-bottom: 40px;
  }
}

.career_facts_header {
  margin-bottom: 16px;
}

.career_facts_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #4E4E4E;
  font-size: 16px;
  line-height: 1.1875;
  margin: 0;
  text-transform: uppercase;
}

.career_facts_items {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width:500px) {
  .career_facts_items {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

.career_fact_item {
  border-top: 1px solid #006BC0;
  margin-top: 40px;
  padding-top: 24px;
}

@media screen and (min-width:500px) {
  .career_fact_item {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media screen and (min-width:740px) {
  .career_fact_item {
    width: calc(50% - 28px);
    margin-right: 14px;
    margin-left: 14px;
  }
}

.career_fact_item:first-child {
  margin-top: 0;
}

@media screen and (min-width:500px) {
  .career_fact_item:nth-child(2) {
    margin-top: 0;
  }
}

.career_fact_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  color: #0B263B;
  font-size: 18px;
  line-height: 1.33333333;
  margin: 0 0 16px;
}

@media screen and (min-width:740px) {
  .career_fact_item_title {
    font-size: 21px;
    line-height: 1.23809524;
  }
}

@media only screen and (min-width:320px) {
  .career_facts_item_body {
    display: -ms-flexbox;
    display: flex;
    margin: 0 -10px;
  }
}

@media screen and (min-width:500px) {
  .career_facts_item_body {
    display: block;
    margin: 0;
  }
}

@media only screen and (min-width:992px) {
  .career_facts_item_body {
    display: -ms-flexbox;
    display: flex;
    margin: 0 -10px;
  }
}

@media only screen and (min-width:320px) {
  .career_fact {
    width: 50%;
    padding: 0 10px;
  }
}

@media screen and (min-width:500px) {
  .career_fact {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (min-width:992px) {
  .career_fact {
    width: 50%;
    padding: 0 10px;
  }
}

.career_fact:first-child {
  margin-bottom: 16px;
}

@media only screen and (min-width:320px) {
  .career_fact:first-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width:500px) {
  .career_fact:first-child {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width:992px) {
  .career_fact:first-child {
    margin-bottom: 0;
  }
}

.career_fact_value {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  color: #076BC3;
  font-size: 18px;
  line-height: 1.33333333;
}

@media screen and (min-width:740px) {
  .career_fact_value {
    font-size: 21px;
    line-height: 1.23809524;
  }
}

.career_fact_label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #0C263C;
  font-size: 16px;
  line-height: 1.5;
}

.course_info_heading {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .course_info_heading {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.cover_image_block {
  margin: 20px 0 18px;
}

.full_width_callouts .cover_image_block,
.in_content_callouts .cover_image_block,
.sidebar .cover_image_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .cover_image_block,
  .in_content_callouts .cover_image_block,
  .sidebar .cover_image_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .full_width_callouts .cover_image_block,
  .in_content_callouts .cover_image_block,
  .sidebar .cover_image_block {
    margin-bottom: 40px;
  }
}

.cover_image_figure {
  display: block;
}

.cover_image_figure picture {
  display: block;
}

.cover_image_figure img {
  display: block;
  width: 100%;
}

.cover_image_image {
  border-radius: 5px;
}

.cover_image_image,
.typography .cover_image_image {
  max-width: none;
}

.cover_image_caption {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: italic;
  font-weight: 400;
  margin: 8px 0;
}

.cover_image_caption p {
  margin: 0;
}

.image-grid-component::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width:450px) {
  .image-grid-component .fs-carousel-canister {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: normal;
    justify-content: normal;
    gap: 10px 10px;
  }

  .image-grid-component .image-grid-link {
    -ms-flex-preferred-size: unset;
    flex-basis: unset;
  }

  .image-grid-component .image-grid-image {
    max-width: 374px;
    max-height: 214px;
    min-width: 256px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media screen and (min-width:451px) and (max-width:600px) {
  .image-grid-component .fs-carousel-canister {
    display: block;
  }

  .image-grid-component .image-grid-link {
    -ms-flex-preferred-size: unset;
    flex-basis: unset;
  }
}

@media screen and (min-width:601px) {
  .image-grid-component .fs-carousel-canister {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px 10px;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .image-grid-component .image-grid-image {
    max-width: 374px;
    max-height: 214px;
    min-width: 256px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .image-grid-component .image-grid-link {
    -ms-flex-preferred-size: 49%;
    flex-basis: 49%;
    max-width: 100%;
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .image-grid-component .image-grid-image {
    min-width: 146px;
  }
}

.related_programs {
  overflow: hidden;
  background-color: #f3f4f5;
}

.related_programs_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .related_programs_title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

.related_programs_cta {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #C8102E;
  color: #fff;
  cursor: pointer;
  display: none;
  margin-right: 0;
  transition: background .25s,color .25s;
}

@media only screen and (min-width:992px) {
  .related_programs_cta {
    display: block;
  }
}

.related_programs_cta:focus,
.related_programs_cta:hover {
  background-color: #333;
}

.related_programs_cta_mobile_wrap {
  margin: 20px auto 0;
  text-align: center;
}

@media only screen and (min-width:992px) {
  .related_programs_cta_mobile_wrap {
    display: none;
  }
}

.related_programs_cta.is_mobile {
  display: inline-block;
  margin-bottom: 0;
}

.social_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .social_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .social_block {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:768px) {
  .social_block {
    border: 2px solid #C8102E;
    border-radius: 5px;
  }

  .full_width_callouts .social_block {
    border: none;
    border-radius: 0;
  }
}

@media print {
  .social_block {
    display: none!important;
    visibility: hidden;
  }
}

.no-js .social_block {
  display: none!important;
  visibility: hidden;
}

.full_width_callouts .social_block_inner {
  border: 2px solid #C8102E;
  border-radius: 5px;
}

.social_tabs {
  background-color: #C8102E;
  color: #fff;
  list-style: none;
  margin: 0;
  padding: 6px 10px;
}

.social_tabs:after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (max-width:767px) {
  .social_tabs {
    border-radius: 5px 5px 0 0;
  }
}

@media only screen and (min-width:768px) {
  .social_tabs {
    padding: 10px 10px 20px;
  }
}

.social_tab {
  display: block;
  position: relative;
  float: left;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 10px;
}

@media only screen and (min-width:768px) {
  .social_tab {
    padding: 30px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .social_tab {
    width: 47px;
    padding: 15px;
  }
}

.social_tab:after {
  position: absolute;
  display: block;
  width: 0;
  height: 4px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f3f4f5;
  content: "";
  margin: 0 auto;
  transition: width .4s;
}

.social_tab:focus:after,
.social_tab:hover:after {
  width: 30px;
}

.social_tab.fs-swap-enabled {
  cursor: pointer;
}

.social_tab.fs-swap-active:after {
  width: 30px;
}

.social_tab .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  pointer-events: none;
}

.social_tab .symbol {
  fill: #fff;
}

.social_tab .symbol_social_twitter {
  width: 28px;
  height: 23px;
}

.social_tab .symbol_social_instagram {
  width: 28px;
  height: 28px;
}

.social_tab .symbol_social_youtube {
  width: 28px;
  height: 20px;
}

.social_tab .symbol_social_facebook {
  width: 28px;
  height: 28px;
}

.social_tab .symbol_social_linkedin {
  width: 28px;
  height: 28px;
}

.social_tab .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.social_tab .label.focusable:active,
.social_tab .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.social_tab .label.focusable:active,
.social_tab .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.social_panels {
  padding-top: 20px;
}

@media only screen and (min-width:768px) {
  .social_panels {
    padding: 30px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .social_panels {
    padding: 10px;
  }
}

.social_panels iframe {
  max-width: 100%;
}

.social_panel {
  display: block;
}

.social_panel.fs-swap-enabled {
  display: none;
}

.social_panel.fs-swap-active {
  display: block;
}

.social_panel .youtube_profile {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
}

.social_panel .youtube_profile iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-height:675px) {
  .twitter_wrapper iframe {
    height: 350px!important;
  }
}

.instagram_wrapper {
  position: relative;
  padding-top: 100%;
}

.instagram_wrapper iframe {
  position: absolute;
  top: 0;
}

.topic_block {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .topic_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .topic_block {
    margin-bottom: 40px;
  }
}

.topic_block_title {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.16666667;
  color: #C8102E;
  margin: 30px 0;
}

@media only screen and (min-width:768px) {
  .topic_block_title {
    font-size: 48px;
    font-size: 3rem;
  }
}

.topic_row {
  position: relative;
  padding: 20px 0;
}

@media only screen and (min-width:768px) {
  .topic_row {
    padding: 30px 0;
  }
}

@media print,only screen and (min-width:992px) {
  .topic_row {
    padding: 50px 0;
  }

  .sidebar .topic_row {
    padding: 30px 0;
  }
}

@media only screen and (min-width:1200px) {
  .topic_row {
    padding: 76px 0 60px;
  }
}

.topic_row:not(:last-child) {
  border-bottom: 1px solid #f3f4f5;
}

.topic_row:first-child {
  padding-top: 16px;
}

@media only screen and (min-width:992px) {
  .sidebar .topic_row:first-child {
    padding-top: 0;
  }
}

.topic_row:last-child {
  padding-bottom: 16px;
}

.topic_row_inner:after {
  clear: both;
  content: "";
  display: table;
}

.topic_figure {
  display: block;
  float: right;
  overflow: hidden;
  width: 100px;
  border-radius: 5px;
  margin: 3px 0 20px 20px;
}

.topic_figure picture {
  display: block;
}

.topic_figure img {
  display: block;
  width: 100%;
}

@media screen and (min-width:500px) {
  .topic_figure {
    width: 32%;
    margin-left: 30px;
  }
}

@media print,only screen and (min-width:768px) {
  .topic_figure {
    float: left;
    width: 30.5%;
    margin: 0 30px 0 0;
  }
}

@media only screen and (min-width:992px) {
  .topic_figure {
    width: 34.25%;
  }

  .sidebar .topic_figure {
    width: 100%;
    float: none;
    margin-bottom: 16px;
  }
}

@media print,only screen and (min-width:1200px) {
  .topic_figure {
    width: 33.75%;
  }

  .sidebar .topic_figure {
    width: 100%;
  }
}

@media only screen and (min-width:1200px) {
  .topic_figure {
    margin-right: 40px;
  }
}

@media print,screen and (min-width:500px) {
  .topic_wrapper {
    overflow: hidden;
  }
}

.topic_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 0 0 6px;
}

@media only screen and (min-width:768px) {
  .topic_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.layout_program_detail .sidebar_cell .topic_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  color: #0B263B;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .layout_program_detail .sidebar_cell .topic_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.layout_program_detail .in_content_callouts .topic_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #4E4E4E;
  font-size: 16px;
  line-height: 1.1875;
  margin: 0;
  text-transform: uppercase;
}

.topic_description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  margin: 6px 0;
}

.topic_description p {
  margin: 0;
}

.topic_description a {
  color: #C8102E;
  transition: color .25s;
}

.topic_description a:focus,
.topic_description a:hover {
  color: inherit;
}

.topic_links {
  margin-top: 24px;
}

@media print {
  .topic_links {
    display: none!important;
    visibility: hidden;
  }
}

.topic_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #C8102E;
  color: #fff;
  margin-bottom: 0;
}

@media screen and (max-width:499px) {
  .topic_link {
    display: block;
    margin: 10px 0;
    text-align: center;
  }
}

@media screen and (min-width:500px) {
  .topic_link:not(:last-child) {
    margin-right: 25px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .topic_link:not(:last-child) {
    margin-bottom: 8px;
  }
}

.topic_link:focus,
.topic_link:hover {
  background-color: #333;
}

@media print {
  .fs-carousel-canister {
    width: 100%!important;
    -ms-transform: none!important;
    transform: none!important;
  }
}

@media print {
  .fs-carousel-controls {
    display: none!important;
    visibility: hidden;
  }
}

.fs-carousel-control {
  cursor: default;
  pointer-events: none;
}

.fs-carousel-control.fs-carousel-visible {
  cursor: pointer;
  pointer-events: auto;
}

.base_controls .fs-carousel-controls {
  position: absolute;
  width: 100vw;
  height: 80px;
  top: 50%;
  left: 50%;
  pointer-events: none;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.base_controls .fs-carousel-control {
  overflow: hidden;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  border-radius: 5px;
  color: transparent;
  font-size: 0;
  opacity: 0;
  pointer-events: none;
  transition: background .25s,opacity .25s,visibility .25s;
  visibility: hidden;
}

.base_controls .fs-carousel-control .icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 24px;
}

.base_controls .fs-carousel-control .symbol {
  display: block;
  width: 16px;
  height: 24px;
  transition: fill .25s;
}

.base_controls .fs-carousel-control.fs-carousel-visible {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.base_controls .fs-carousel-control.fs-carousel-control_previous {
  left: -5px;
}

.base_controls .fs-carousel-control.fs-carousel-control_previous .icon {
  left: 5px;
}

.base_controls .fs-carousel-control.fs-carousel-control_next {
  right: -5px;
}

.base_controls .fs-carousel-control.fs-carousel-control_next .icon {
  right: 5px;
}

.base_controls_white .fs-carousel-control {
  background-color: #fff;
  box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);
  color: #C8102E;
}

.base_controls_white .fs-carousel-control .symbol {
  fill: #C8102E;
}

.base_controls_white .fs-carousel-control:focus .symbol,
.base_controls_white .fs-carousel-control:hover .symbol {
  fill: #333;
}

.base_controls_red_light .fs-carousel-control {
  background-color: #C8102E;
  color: #fff;
}

.base_controls_red_light .fs-carousel-control .symbol {
  fill: #fff;
}

.base_controls_red_light .fs-carousel-control:focus,
.base_controls_red_light .fs-carousel-control:hover {
  background-color: #333;
}

@media print {
  .fs-carousel-pagination {
    display: none!important;
    visibility: hidden;
  }
}

.carousel_count {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  overflow: hidden;
  text-align: center;
}

@media print,only screen and (min-width:768px) {
  .carousel_count {
    display: none!important;
    visibility: hidden;
  }
}

.no-js .carousel_count {
  display: none!important;
  visibility: hidden;
}

.carousel_count_inner {
  display: inline-block;
  position: relative;
}

.carousel_count_inner:after,
.carousel_count_inner:before {
  position: absolute;
  width: 600px;
  height: 1px;
  top: 50%;
  background-color: rgba(151,151,151,.3);
  content: "";
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carousel_count_inner:before {
  right: 100%;
  margin-right: 18px;
}

.carousel_count_inner:after {
  left: 100%;
  margin-left: 18px;
}

[data-whatinput=mouse] .fs-dropdown:focus,
[data-whatinput=touch] .fs-dropdown:focus {
  outline: 0;
}

.fs-dropdown .fs-dropdown-selected {
  position: relative;
}

.base_dropdown.fs-dropdown {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
}

.base_dropdown.fs-dropdown .fs-dropdown-selected {
  font-size: 16px;
  font-size: 1rem;
  position: relative;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  border-radius: 5px;
  border-width: 0;
  line-height: 1;
  padding: 14px 40px 14px 20px;
}

@media only screen and (min-width:768px) {
  .base_dropdown.fs-dropdown .fs-dropdown-selected {
    font-size: 21px;
    font-size: 1.3125rem;
    padding: 30px 55px 29px 30px;
  }
}

.base_dropdown.fs-dropdown .fs-dropdown-selected:after {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 20px;
  bottom: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  content: "";
  display: block;
  margin: auto 0;
}

@media only screen and (min-width:768px) {
  .base_dropdown.fs-dropdown .fs-dropdown-selected:after {
    right: 30px;
    border-width: 10px 8px 0 8px;
  }
}

.base_dropdown.fs-dropdown .fs-dropdown-options {
  border-radius: 0 0 5px 5px;
  border-width: 0;
  padding: 10px 0;
}

.base_dropdown.fs-dropdown .fs-dropdown-item {
  padding: 6px 15px;
  transition: background-color .25s;
}

@media only screen and (min-width:768px) {
  .base_dropdown.fs-dropdown .fs-dropdown-item {
    padding: 6px 30px;
  }
}

.base_dropdown.fs-dropdown .fs-dropdown-item:active,
.base_dropdown.fs-dropdown .fs-dropdown-item:focus,
.base_dropdown.fs-dropdown .fs-dropdown-item:hover {
  background-color: rgba(0,0,0,.1);
}

.theme_red_light.fs-dropdown .fs-dropdown-selected {
  background-color: #C8102E;
  color: #fff;
}

.theme_red_light.fs-dropdown .fs-dropdown-options {
  background-color: #C8102E;
  box-shadow: 0 -3px 0 #C8102E;
  color: #fff;
}

.theme_red_light.fs-dropdown .fs-dropdown-item {
  background-color: transparent;
  color: inherit;
}

.theme_red_light.fs-dropdown .fs-dropdown-item:active,
.theme_red_light.fs-dropdown .fs-dropdown-item:focus,
.theme_red_light.fs-dropdown .fs-dropdown-item:hover {
  background-color: #333;
}

.fs-checkbox {
  margin-bottom: 5px;
}

.fs-checkbox:after {
  clear: both;
  content: "";
  display: table;
}

.fs-checkbox-marker {
  margin-right: 10px;
}

.fs-checkbox-flag {
  position: absolute;
  top: 0;
  left: 0;
}

@media print {
  .fs-lightbox-element {
    display: block;
  }
}

.fs-lightbox {
  background: rgba(255,255,255,.75);
}

[data-whatinput=mouse] .fs-lightbox:focus,
[data-whatinput=touch] .fs-lightbox:focus {
  outline: 0;
}

.fs-lightbox .fs-lightbox-container {
  background: 0 0;
}

.fs-lightbox .fs-lightbox-content {
  background: 0 0;
  padding: 20px;
}

.fs-lightbox .fs-viewer-element,
.fs-lightbox .fs-viewer-element .fs-viewer-wrapper {
  background: 0 0;
}

.fs-lightbox .fs-lightbox-video_wrapper {
  margin-right: auto!important;
  margin-left: auto!important;
}

.fs-lightbox .fs-lightbox-tools {
  width: 100%!important;
  background: #333;
}

.fs-lightbox .fs-viewer-controls {
  width: 100%;
}

.fs-lightbox .fs-viewer-control_zoom_out {
  display: none;
}

.fs-lightbox .fs-viewer-control_zoom_in {
  display: none;
}

.fs-lightbox .fs-lightbox-position {
  margin: 0;
}

.fs-lightbox .fs-lightbox-caption {
  text-align: center;
}

.fs-lightbox .caption {
  margin: 0;
}

.fs-navigation-overlay-nav.fs-navigation-enabled {
  width: 100%;
  height: 100vh;
  background-color: #C8102E;
  color: #fff;
  opacity: 1;
  pointer-events: none;
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: transform .4s;
  transition-timing-function: cubic-bezier(.645,.045,.355,1);
  visibility: visible;
}

.csstransforms3d .fs-navigation-overlay-nav.fs-navigation-enabled {
  transform: translate3d(0,-100%,0);
}

.fs-navigation-overlay-nav.fs-navigation-open {
  pointer-events: auto;
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.csstransforms3d .fs-navigation-overlay-nav.fs-navigation-open {
  transform: translate3d(0,0,0);
}

.fs-navigation-overlay-content.fs-navigation-open:before {
  display: none!important;
  visibility: hidden;
  content: normal;
}

.linked_list {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

@media only screen and (min-width:768px) {
  .linked_list {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .linked_list {
    margin-bottom: 40px;
  }
}

@media print {
  .linked_list {
    display: none!important;
    visibility: hidden;
  }
}

.full_width_callouts .linked_list {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .linked_list {
    margin-top: 40px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .linked_list {
    margin-top: 60px;
  }
}

@media only screen and (min-width:768px) {
  .full_width_callouts .linked_list {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .linked_list {
    margin-bottom: 60px;
  }
}

.full_width_callouts .linked_list:first-child {
  margin-top: 0;
}

.linked_list_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  color: #C8102E;
  margin: 5px 0;
}

@media only screen and (min-width:768px) {
  .linked_list_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.linked_list_group {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #333;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width:768px) {
  .linked_list_group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.linked_list_item {
  border-bottom: 2px solid #f3f4f5;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width:768px) {
  .linked_list_item {
    width: calc(50% - 10px);
  }
}

@media only screen and (min-width:1200px) {
  .linked_list_item {
    width: calc(50% - 12px);
  }
}

@media only screen and (min-width:1200px) {
  .linked_list.size_large .linked_list_item {
    width: calc(33% - 16px);
  }
}

@media only screen and (min-width:992px) {
  .linked_list.size_sidebar .linked_list_item {
    width: 100%;
  }
}

.linked_list_link {
  display: block;
  color: inherit;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 10px 0;
}

.linked_list_link .icon {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  top: -2px;
  margin-right: -22px;
  margin-left: 10px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: transform .25s;
}

.linked_list_link .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.linked_list_link:focus .icon,
.linked_list_link:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.linked_list_link .symbol_arrow_right {
  fill: #C8102E;
}

.linked_list_link:focus,
.linked_list_link:hover {
  color: #C8102E;
}

.linked_list_icon {
  display: block;
  float: left;
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border: 1px solid #a7a8aa;
  border-radius: 100%;
  margin-right: 18px;
}

@media only screen and (min-width:992px) {
  .sidebar .linked_list_icon {
    margin-right: 10px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .linked_list_icon {
    margin-right: 14px;
  }
}

.linked_list_icon_image {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.linked_list_text {
  display: inline-block;
  padding-right: 22px;
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 10px;
}

.pagination_block {
  padding-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .pagination_block {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .pagination_block {
    padding-bottom: 60px;
  }
}

.pagination:after {
  clear: both;
  content: "";
  display: table;
}

.typography .pagination a {
  box-shadow: none;
}

.pagination_arrow {
  height: 32px;
  width: 32px;
  position: relative;
  background: #C8102E;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  transition: background .25s;
}

.pagination_arrow:focus,
.pagination_arrow:hover {
  background: #333;
}

.pagination_arrow_left {
  float: left;
  margin-right: 10px;
}

@media screen and (min-width:500px) {
  .pagination_arrow_left {
    float: none;
  }
}

.pagination_arrow_right {
  float: right;
  margin-left: 10px;
}

@media screen and (min-width:500px) {
  .pagination_arrow_right {
    float: none;
  }
}

.pagination_label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.pagination_label.focusable:active,
.pagination_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.pagination_label.focusable:active,
.pagination_label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.pagination_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.pagination_icon svg {
  height: 18px;
  width: 14px;
  fill: #fff;
}

.pagination_link {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  box-shadow: none;
  color: #333;
  display: none;
  padding: 4px 5px;
  vertical-align: middle;
  transition: box-shadow .25s,color .25;
}

@media screen and (min-width:500px) {
  .pagination_link {
    display: inline-block;
  }
}

.pagination_link:focus,
.pagination_link:hover {
  color: #264db6;
}

.pagination_link.active {
  box-shadow: 0 1px 0 0 #264db6;
  color: #264db6;
}

@media print {
  .share_block {
    display: none!important;
    visibility: hidden;
  }
}

.no-js .share_block {
  display: none!important;
  visibility: hidden;
}

.share_label {
  display: inline-block;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  position: relative;
  top: -17px;
  letter-spacing: 0;
  line-height: 1;
  margin-right: 12px;
}

.share_links {
  display: inline-block;
}

.share_links:after {
  clear: both;
  content: "";
  display: table;
}

.share_link {
  display: block;
  position: relative;
  float: left;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  transition: background .25s;
}

.share_link:not(:last-child) {
  margin-right: 10px;
}

.share_link.facebook {
  background-color: #3B5998;
  color: #fff;
}

.share_link.twitter {
  background-color: #55ACEE;
  color: #fff;
}

.share_link.linkedin {
  background-color: #0073B0;
  color: #fff;
}

.share_link .label {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.share_link .label.focusable:active,
.share_link .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.share_link .label.focusable:active,
.share_link .label.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.share_link .symbol {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  fill: #fff;
  margin: auto;
}

.share_link .symbol_share_facebook {
  width: 11px;
  height: 24px;
}

.share_link .symbol_share_twitter {
  width: 21px;
  height: 17px;
}

.share_link:focus,
.share_link:hover {
  background-color: #C8102E;
}

.support_card {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  color: #333;
}

@media only screen and (min-width:992px) {
  .sidebar .support_card.style_text {
    background: #f3f4f5;
    border-radius: 5px;
  }
}

@media print {
  .support_card {
    max-width: 600px;
    border: 1px solid #000;
    margin: 30px auto;
  }
}

.support_card_inner {
  padding: 20px 15px;
}

.sidebar .support_card_inner {
  padding: 20px 15px;
}

@media only screen and (min-width:992px) {
  .sidebar .support_card.style_text .support_card_inner {
    background: #f3f4f5;
    border-radius: 5px;
    padding: 20px 30px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .support_card.style_text .support_card_inner {
    padding: 30px 36px;
  }
}

@media only screen and (min-width:768px) {
  .support_card_inner {
    padding: 40px;
  }
}

.support_card_header {
  margin-bottom: 15px;
}

.support_card_label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  margin-bottom: 10px;
}

@media only screen and (min-width:992px) {
  .sidebar .support_card_label {
    font-size: 14px;
    letter-spacing: 0;
  }
}

.support_card_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 16px 0 8px;
}

@media only screen and (min-width:768px) {
  .support_card_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

@media only screen and (min-width:992px) {
  .support_card_title {
    margin-top: 10px;
  }

  .sidebar .support_card_title {
    font-size: 20px;
  }
}

.support_card_subtitle {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  margin: 0;
}

.support_card_description {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  margin: 10px 0;
}

@media only screen and (min-width:768px) {
  .support_card_description {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

.sidebar .support_card_description {
  line-height: 1.3;
}

@media only screen and (min-width:992px) {
  .sidebar .support_card_description {
    font-size: 16px;
  }
}

.support_card_description p {
  margin: 0;
}

@media print {
  .support_card_links {
    display: none!important;
    visibility: hidden;
  }
}

.support_card_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #264db6;
  color: #fff;
  margin-bottom: 0;
  transition: background-color .25s;
}

@media screen and (max-width:500px) {
  .support_card_link {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width:500px) {
  .support_card_link:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_card_link {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .sidebar .support_card_link:last-of-type {
    margin-bottom: 0;
  }
}

.support_card_link:focus,
.support_card_link:hover {
  background-color: #333;
}

.support_card_figure {
  overflow: hidden;
  border-radius: 100%;
}

@media print,only screen and (max-width:767px) {
  .support_card_figure {
    position: absolute;
    width: 72px;
    height: 72px;
    top: 0;
    right: 15px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media print {
  .support_card_figure {
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@media only screen and (min-width:768px) {
  .support_card_figure {
    float: right;
    width: 120px;
    height: 120px;
    margin: -72px 0 10px 10px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .support_card_figure {
    display: none;
  }
}

.support_card_image {
  width: 100%;
}

.support_cards_background_fallback {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.support_card_background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media print {
  .support_card_background {
    display: none!important;
    visibility: hidden;
  }
}

.support_card_background:after {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: rgba(0,0,0,0);
  background: linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.7));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
  content: "";
}

.support_card.style_text .support_card_label {
  color: #264db6;
}

.support_card.style_text .support_card_links {
  margin-top: 20px;
}

.support_card.style_image {
  overflow: hidden;
  background-color: #000;
  color: #fff;
}

.support_card.style_image .support_card_inner {
  position: relative;
  padding-top: 200px;
}

@media print {
  .support_card.style_image .support_card_inner {
    padding: 20px 15px;
  }
}

.support_card.style_image .support_card_label {
  margin-bottom: 6px;
}

.support_card.style_image .support_card_title {
  margin-top: 6px;
}

@media print,only screen and (max-width:767px) {
  .support_card.has_image .support_card_label {
    padding-right: 84px;
  }
}

.wysiwyg_block.section_label .typography h3 {
  font-size: 24px;
  margin: 0;
  text-decoration: underline;
  text-transform: uppercase;
}

.back_button_block {
  margin: 20px 0;
}

@media print {
  .back_button_block {
    display: none!important;
    visibility: hidden;
  }
}

.back_button_link {
  display: block;
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  background-color: #C8102E;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.back_button_link:focus,
.back_button_link:hover {
  background-color: #333;
}

.sidebar_callout {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
}

@media only screen and (min-width:768px) {
  .sidebar_callout {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .sidebar_callout {
    margin-bottom: 40px;
  }
}

@media print {
  .sidebar_callout {
    margin: 30px 0;
  }
}

.sidebar_callout_inner {
  position: relative;
  padding: 20px;
}

@media print {
  .sidebar_callout_inner {
    padding: 0;
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .sidebar_callout_inner {
    padding: 30px 20px;
  }
}

@media only screen and (min-width:992px) {
  .sidebar_callout_inner {
    padding: 20px 20px 28px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar_callout_inner {
    padding: 25px 25px 30px;
  }
}

.sidebar_callout_background_fallback {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .2;
}

.js .sidebar_callout_background_fallback {
  display: none!important;
  visibility: hidden;
}

.sidebar_callout_background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .2;
}

.sidebar_callout_content {
  position: relative;
}

.sidebar_callout_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  margin: 0 0 14px;
}

@media only screen and (min-width:768px) {
  .sidebar_callout_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.sidebar_callout_description {
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
}

@media only screen and (min-width:768px) {
  .sidebar_callout_description {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

@media only screen and (min-width:992px) {
  .sidebar_callout_description {
    font-size: 16px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar_callout_description {
    font-size: 18px;
  }
}

.sidebar_callout_description p {
  margin: 0;
}

.sidebar_callout_footer {
  overflow: hidden;
  margin-top: 50px;
}

@media only screen and (min-width:992px) {
  .sidebar .sidebar_callout_footer {
    margin-top: 20px;
  }
}

@media only screen and (min-width:1200px) {
  .sidebar .sidebar_callout_footer {
    margin-top: 25px;
  }
}

@media print {
  .sidebar_callout_footer {
    display: none!important;
    visibility: hidden;
  }
}

.sidebar_callout_link {
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  display: block;
  float: left;
}

.sidebar_callout.theme_red_brand {
  background-color: #C8102E;
  color: #fff;
}

.sidebar_callout .sidebar_callout_link {
  background-color: #fff;
  color: #333;
}

.sidebar_callout .sidebar_callout_link:focus,
.sidebar_callout .sidebar_callout_link:hover {
  background-color: #333;
  color: #fff;
}

.contact_card {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f3f4f5;
  border-radius: 5px;
  margin-top: 50px;
  padding: 20px;
}

@media only screen and (min-width:768px) {
  .contact_card {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .contact_card {
    margin-bottom: 40px;
  }
}

.full_width_callouts .contact_card {
  background: 0 0;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card {
    margin-top: 20px;
    margin-bottom: 20px;
    background: #f3f4f5;
  }
}

@media only screen and (min-width:768px) and only screen and (min-width:768px) {
  .full_width_callouts .contact_card {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) and only screen and (min-width:768px) {
  .full_width_callouts .contact_card {
    margin-bottom: 40px;
  }
}

.layout_program_detail .contact_card {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 25px;
}

@media print {
  .contact_card {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }
}

@media print and only screen and (min-width:768px) {
  .contact_card {
    margin-top: 40px;
  }
}

@media print and only screen and (min-width:768px) {
  .contact_card {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:768px) {
  .contact_card {
    margin-top: 60px;
  }

  .in_content_callouts .contact_card {
    padding: 40px 30px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .contact_card,
  .in_content_callouts .contact_card {
    margin-left: 0;
  }
}

@media only screen and (min-width:1200px) {
  .contact_card {
    padding: 24px 24px 24px 30px;
  }

  .sidebar .contact_card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .full_width_callouts .contact_card,
  .in_content_callouts .contact_card {
    margin-left: 0;
  }
}

.full_width_callouts .contact_card_inner {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f3f4f5;
  border-radius: 5px;
  padding: 20px;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card_inner {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card_inner {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card_inner {
    margin: 0;
    padding: 75px 0;
  }
}

@media only screen and (min-width:1200px) {
  .full_width_callouts .contact_card_inner {
    padding: 100px 0;
  }
}

.contact_card_figure {
  overflow: hidden;
  float: right;
  position: relative;
  top: -64px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin: 0 0 10px 10px;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_figure {
    float: none;
    position: static;
    top: auto;
    margin: -60px 0 10px 0;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .contact_card_figure {
    width: 120px;
    height: 120px;
    top: -100px;
  }
}

.full_width_callouts .contact_card_figure {
  top: -50px;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card_figure {
    width: 120px;
    height: 120px;
    top: 0;
    margin-top: -60px;
  }
}

@media only screen and (min-width:1200px) {
  .full_width_callouts .contact_card_figure {
    width: 180px;
    height: 180px;
    position: absolute;
    right: 80px;
    margin-top: 0;
  }
}

.layout_program_detail .contact_card_figure {
  z-index: 4;
}

@media print {
  .contact_card_figure {
    top: 0;
  }
}

.contact_card_image {
  display: block;
  width: 100%;
  height: auto;
}

.contact_card_name {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.14285714;
  margin: 6px 0;
}

.in_content_callouts .contact_card_name {
  font-size: 24px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .contact_card_name {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.full_width_callouts .contact_card_name {
  font-size: 24px;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card_name {
    font-size: 40px;
  }
}

.contact_card_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  font-size: .75rem;
  color: #C8102E;
  letter-spacing: 2px;
  margin: 6px 0;
  text-transform: uppercase;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_title {
    font-size: 11px;
    margin-top: 10px;
  }
}

.full_width_callouts .contact_card_title {
  white-space: nowrap;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .contact_card_body {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: -10px;
  }
}

@media screen and (min-width:600px) {
  .full_width_callouts .contact_card_body {
    margin-top: 30px;
  }
}

@media only screen and (min-width:768px) {
  .full_width_callouts .contact_card_body {
    margin-top: 30px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .contact_card_body {
    padding-right: 60px;
  }
}

@media only screen and (min-width:1200px) {
  .full_width_callouts .contact_card_body {
    padding-right: 0;
  }
}

.contact_card_items {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.contact_card_items a {
  display: inline-block;
  color: inherit;
  box-shadow: inset 0 -1px #264db6;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .contact_card_items {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width:1200px) {
  .in_content_callouts .contact_card_items {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width:600px) {
  .full_width_callouts .contact_card_items {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .contact_card_items {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (min-width:1200px) {
  .full_width_callouts .contact_card_items {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.contact_card_item {
  margin: 18px 0;
}

.contact_card_item:after {
  clear: both;
  content: "";
  display: table;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_item {
    margin: 8px 0;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .contact_card_item {
    width: 50%;
    margin: 18px 0 0;
  }

  .in_content_callouts .contact_card_item:nth-of-type(1),
  .in_content_callouts .contact_card_item:nth-of-type(2) {
    margin-top: 0;
  }
}

@media only screen and (min-width:1200px) {
  .in_content_callouts .contact_card_item {
    width: 45%;
  }
}

@media screen and (min-width:600px) {
  .full_width_callouts .contact_card_item {
    width: 50%;
    margin: 18px 0 0;
  }

  .full_width_callouts .contact_card_item:nth-of-type(1),
  .full_width_callouts .contact_card_item:nth-of-type(2) {
    margin-top: 0;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .contact_card_item {
    width: 28%;
  }

  .full_width_callouts .contact_card_item:nth-of-type(3) {
    margin-top: 0;
  }
}

@media only screen and (min-width:1200px) {
  .full_width_callouts .contact_card_item {
    width: 22%;
  }

  .full_width_callouts .contact_card_item.fax,
  .full_width_callouts .contact_card_item.phone {
    width: 186px;
  }

  .full_width_callouts .contact_card_item:nth-of-type(4) {
    margin-top: 0;
  }
}

.contact_card_item:first-child {
  margin-top: 14px;
}

.contact_card_item:last-child {
  margin-bottom: 0;
}

.full_width_callouts .contact_card_item.address,
.in-content_callouts .contact_card_item.address {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_item.address .contact_card_label {
    width: 1px;
    height: 1px;
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
  }

  .sidebar .contact_card_item.address .contact_card_label.focusable:active,
  .sidebar .contact_card_item.address .contact_card_label.focusable:focus {
    width: auto;
    height: auto;
    position: static;
    clip: auto;
    margin: 0;
    overflow: visible;
  }

  .sidebar .contact_card_item.address .contact_card_label.focusable:active,
  .sidebar .contact_card_item.address .contact_card_label.focusable:focus {
    width: auto;
    height: auto;
    position: static;
    clip: auto;
    margin: 0;
    overflow: visible;
  }
}

@media screen and (min-width:600px) {
  .full_width_callouts .contact_card_item.address {
    width: 50%;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .contact_card_item.address {
    width: 28%;
  }
}

@media only screen and (min-width:1200px) {
  .full_width_callouts .contact_card_item.address {
    width: 22%;
  }
}

.contact_card_label {
  display: inline-block;
  float: left;
  overflow: hidden;
  position: relative;
  width: 32px;
  height: 32px;
  top: -5px;
  background-color: #264db6;
  border-radius: 100%;
  margin-right: 16px;
}

.full_width_callouts .contact_card_label,
.in_content_callouts .contact_card_label,
.sidebar .contact_card_label {
  top: 0;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_label {
    width: 100%;
    height: auto;
    display: block;
    background: 0 0;
    border-radius: 0;
    clear: both;
    float: none;
    line-height: 1;
  }
}

@media print {
  .contact_card_label {
    border: 1px solid #000;
  }
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_label .symbol_wrap {
    width: 1px;
    height: 1px;
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
  }

  .sidebar .contact_card_label .symbol_wrap.focusable:active,
  .sidebar .contact_card_label .symbol_wrap.focusable:focus {
    width: auto;
    height: auto;
    position: static;
    clip: auto;
    margin: 0;
    overflow: visible;
  }

  .sidebar .contact_card_label .symbol_wrap.focusable:active,
  .sidebar .contact_card_label .symbol_wrap.focusable:focus {
    width: auto;
    height: auto;
    position: static;
    clip: auto;
    margin: 0;
    overflow: visible;
  }
}

.contact_card_label .symbol {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  fill: #fff;
  margin: auto;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_label .symbol {
    fill: #264db6;
  }
}

@media print {
  .contact_card_label .symbol {
    fill: #000;
  }
}

.contact_card_label .label_text {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  color: #fff;
}

.contact_card_label .label_text.focusable:active,
.contact_card_label .label_text.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

.contact_card_label .label_text.focusable:active,
.contact_card_label .label_text.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
}

@media only screen and (min-width:992px) {
  .sidebar .contact_card_label .label_text {
    width: auto;
    height: auto;
    position: relative;
    clip: initial;
    font-size: 14px;
    font-weight: 700;
    overflow: visible;
    color: #000;
  }
}

.contact_card_label .symbol_location {
  width: 10px;
  height: 12px;
}

.contact_card_label .symbol_phone {
  width: 12px;
  height: 12px;
}

.contact_card_label .symbol_fax {
  width: 14px;
  height: 14px;
}

.contact_card_label .symbol_email {
  width: 11px;
  height: 9px;
}

.contact_card_text {
  overflow: hidden;
}

.meta_block {
  margin-top: 5px;
  margin-bottom: 60px;
}

.in_content_callouts .meta_block,
.sidebar .meta_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .meta_block,
  .sidebar .meta_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .meta_block,
  .sidebar .meta_block {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .in_content_callouts .meta_content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.meta_item {
  margin: 0 0 22px;
}

.meta_item_label {
  display: block;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 10px;
}

.meta_item_title {
  display: block;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  color: #C8102E;
  margin-top: 10px;
}

@media only screen and (min-width:768px) {
  .meta_item_title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.quick_links_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.quick_links_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  color: #0B263B;
  margin: 0;
}

@media only screen and (min-width:768px) {
  .quick_links_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.quick_links_content {
  margin-bottom: 50px;
}

.quick_links_item {
  border-bottom: 1px solid #a7a8aa;
  padding: 19px 0;
}

.quick_links_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.1875;
  letter-spacing: normal;
  margin: 0;
}

.quick_links_item_link {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  color: #0B263B;
}

.quick_links_item_link .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .quick_links_item_link .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.quick_links_item_link .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.quick_links_item_link .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.quick_links_item_link .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.quick_links_item_link:focus .icon,
.quick_links_item_link:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.quick_links_item_link .symbol {
  fill: #C8102E;
}

.quick_links_item_link:focus,
.quick_links_item_link:hover {
  color: #C8102E;
}

.related_news_block {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width:768px) {
  .related_news_block {
    padding: 75px 0;
  }
}

.in_content_callouts .related_news_block,
.sidebar .related_news_block {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

@media only screen and (min-width:768px) {
  .in_content_callouts .related_news_block,
  .sidebar .related_news_block {
    margin-top: 40px;
  }
}

@media only screen and (min-width:768px) {
  .in_content_callouts .related_news_block,
  .sidebar .related_news_block {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:992px) {
  .full_width_callouts .related_news_header,
  .in_content_callouts .related_news_header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.related_news_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  font-size: .6875rem;
  color: #C8102E;
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media only screen and (min-width:768px) {
  .full_width_callouts .related_news_title,
  .in_content_callouts .related_news_title {
    font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}

.related_news_content {
  margin-bottom: 50px;
}

@media only screen and (min-width:992px) {
  .full_width_callouts .related_news_content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.related_news_item {
  border-bottom: 1px solid #a7a8aa;
  padding: 30px 0;
}

@media only screen and (min-width:992px) {
  .full_width_callouts .related_news_item {
    width: 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }
}

.related_news_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
  letter-spacing: normal;
  margin: 0;
}

.related_news_item_link {
  display: inline-block;
  padding-right: 18px;
  vertical-align: middle;
  color: #333;
}

.related_news_item_link .label {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width:768px) {
  .related_news_item_link .label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.related_news_item_link .icon {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-right: -18px;
  margin-left: 6px;
}

.related_news_item_link .symbol_arrow_right {
  width: 12px;
  height: 12px;
}

.related_news_item_link .icon {
  width: 12px;
  height: 12px;
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: fill .25s,transform .25s;
}

.related_news_item_link:focus .icon,
.related_news_item_link:hover .icon {
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.related_news_item_link .symbol {
  fill: #C8102E;
}

.related_news_item_link:focus,
.related_news_item_link:hover {
  color: #C8102E;
}

@media print {
  .related_news_footer {
    display: none!important;
    visibility: hidden;
  }
}

.related_news_back_link {
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: block;
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  background: #C8102E;
  color: #fff;
  padding: 21px 20px;
}

.related_news_back_link:focus,
.related_news_back_link:hover {
  background: #333;
}

@media only screen and (min-width:992px) {
  .full_width_callouts .related_news_back_link,
  .in_content_callouts .related_news_back_link {
    display: none;
  }
}

.related_news_view_all {
  text-align: center;
  transition: background .25s,border .25s,box-shadow .25s,color .25s,transform .25s;
  display: block;
  overflow: hidden;
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
  display: none;
  background: #C8102E;
  color: #fff;
  padding: 12px 20px;
}

.related_news_view_all:focus,
.related_news_view_all:hover {
  background: #333;
}

@media only screen and (min-width:992px) {
  .full_width_callouts .related_news_view_all,
  .in_content_callouts .related_news_view_all {
    display: block;
  }
}

@media only screen and (min-width:768px) {
  .home_edge .home_feature_background,
  .home_edge .home_feature_fallback,
  .js .home_edge .home_feature_background,
  .js .home_edge .home_feature_fallback {
    position: absolute;
    height: 100%;
    margin-top: 0!important;
  }

  .home_edge .home_feature_inner,
  .js .home_edge .home_feature_inner {
    min-height: calc(100vh - 182px);
  }

  .home_edge .home_feature .container,
  .js .home_edge .home_feature .container {
    min-height: calc(100vh - 182px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0!important;
  }

  .home_edge .home_feature_wrapper,
  .js .home_edge .home_feature_wrapper {
    padding: 100px 0;
  }

  .home_edge .home_feature_label,
  .js .home_edge .home_feature_label {
    margin-bottom: .6em;
  }

  .home_edge .home_feature_description,
  .js .home_edge .home_feature_description {
    position: static;
    height: auto!important;
    margin: auto!important;
  }

  .home_edge .home_feature_description_inner,
  .js .home_edge .home_feature_description_inner {
    position: static;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .home_edge .feature_cards,
  .js .home_edge .feature_cards {
    margin: auto!important;
  }
}

@media print {
  * {
    background: 0 0!important;
    box-shadow: none!important;
    color: #444!important;
    text-shadow: none!important;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  a {
    text-decoration: underline;
  }

  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100%!important;
  }

  .print {
    display: block;
  }
}

.clear_both {
  clear: both!important;
}

.text-center {
  text-align: center!important;
}

.strong {
  font-weight: 700!important;
}

.d-block {
  display: block!important;
}

.d-inline {
  display: inline!important;
}

.d-inline-block {
  display: inline-block!important;
}

em.search_result_highlight {
  font-style: normal;
  background-color: rgba(200,200,192,.75);
  color: #000;
}

.course_info_inner {
  border-top: 4px solid #C8102E;
}

.event-controls {
  text-align: right;
  padding: 20px;
}

.event_card_meta {
  color: #000;
}

.event_card_title {
  color: #264db6;
}

.home_feature_body {
  overflow: hidden;
}

.text-right {
  text-align: right;
}

.virutal-tour {
  -ms-flex-direction: row;
  flex-direction: row;
}

div.virtual-tour>div {
  width: 50%;
}

@media screen and (max-width:991px) {
  .fs-dropdown-options {
    position: relative;
  }

  div.virtual-tour {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  div.virtual-tour>div {
    width: 100%;
    max-width: 400px;
  }
}

.academic_card_more_link .academic_card_group {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.section_table_title_for_credit {
  float: left;
}

.section_table_button_non_credit_view_sections {
  margin-bottom: 10px;
}

.header_link_view_all {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
  line-height: 1.3;
  margin: 10px 0;
}

@media print {
  .header_link_view_all {
    display: none!important;
    visibility: hidden;
  }
}

@media only screen and (min-width:768px) {
  .header_link_view_all {
    font-size: 18px;
    font-size: 1.125rem;
    float: right;
    margin: 0;
  }
}

.header_link_view_all_link {
  border-bottom: 1px solid #072f4f;
  color: #C8102E;
  text-decoration: none;
}

.header_link_view_all_link:focus,
.header_link_view_all_link:hover {
  border-bottom-color: #264db6;
  color: #333;
}

.meta_item_title a {
  color: #C8102E;
}

.meta_item_title a:visited {
  color: #264db6;
}

.meta_item_footnote {
  display: block;
}

.sidebar .linked_list_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
}

.accordion_item_description>hr {
  background: #a7a8aa;
}

.accordion_item_description>h4 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .accordion_item_description>h4 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

@media only screen and (min-width:768px) {
  .accordion_item_description>h4 {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.25;
  }
}

.accordion_controls {
  text-align: right;
  font-size: 2rem;
  padding-right: 5px;
}

.accordion_control_button {
  cursor: pointer;
  color: #C8102E;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.typography .h1 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.125;
}

@media only screen and (min-width:768px) {
  .typography .h1 {
    font-size: 48px;
    font-size: 3rem;
  }
}

@media only screen and (min-width:992px) {
  .typography .h1 {
    font-size: 64px;
    font-size: 4rem;
  }
}

.typography .h2 {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.16666667;
}

@media only screen and (min-width:768px) {
  .typography .h2 {
    font-size: 48px;
    font-size: 3rem;
  }
}

.typography .h3 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .typography .h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

.typography .h4 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .typography .h4 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.typography .h5 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .typography .h5 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.typography .h6 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
}

.h1 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.125;
}

@media only screen and (min-width:768px) {
  .h1 {
    font-size: 48px;
    font-size: 3rem;
  }
}

@media only screen and (min-width:992px) {
  .h1 {
    font-size: 64px;
    font-size: 4rem;
  }
}

.h2 {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.16666667;
}

@media only screen and (min-width:768px) {
  .h2 {
    font-size: 48px;
    font-size: 3rem;
  }
}

.h3 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

.h4 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .h4 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.25;
  }
}

.h5 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
}

@media only screen and (min-width:768px) {
  .h5 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.h6 {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.1875;
}

.directory_item_coordinator {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  color: #C8102E;
}

.red_light {
  color: #930a28;
}

.red_light .symbol,
svg.red_light {
  fill: #930a28;
}

.red_dark {
  color: #930a28;
}

.red_dark .symbol,
svg.red_dark {
  fill: #930a28;
}

.red_brand {
  color: #C8102E;
}

.red_brand .symbol,
svg.red_brand {
  fill: #C8102E;
}

.blue_dark {
  color: #072f4f;
}

.blue_dark .symbol,
svg.blue_dark {
  fill: #072f4f;
}

.blue_light {
  color: #264db6;
}

.blue_light .symbol,
svg.blue_light {
  fill: #264db6;
}

.teal_accent {
  color: #02A6AB;
}

.teal_accent .symbol,
svg.teal_accent {
  fill: #02A6AB;
}

.green_light {
  color: #5fc64d;
}

.green_light .symbol,
svg.green_light {
  fill: #5fc64d;
}

.pink {
  color: #991e66;
}

.pink .symbol,
svg.pink {
  fill: #991e66;
}

.orange {
  color: #ef562d;
}

.orange .symbol,
svg.orange {
  fill: #ef562d;
}

.yellow {
  color: #f4da40;
}

.yellow .symbol,
svg.yellow {
  fill: #f4da40;
}

.black {
  color: #000;
}

.black .symbol,
svg.black {
  fill: #000;
}

.gray_dark {
  color: #333;
}

.gray_dark .symbol,
svg.gray_dark {
  fill: #333;
}

.gray_medium {
  color: #a7a8aa;
}

.gray_medium .symbol,
svg.gray_medium {
  fill: #a7a8aa;
}

.gray_light {
  color: #f3f4f5;
}

.gray_light .symbol,
svg.gray_light {
  fill: #f3f4f5;
}

.white {
  color: #fff;
}

.white .symbol,
svg.white {
  fill: #fff;
}

.base_theme_blue_light {
  background-color: #264db6!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_blue_dark {
  background-color: #072f4f!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_red_brand {
  background-color: #C8102E!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_red_dark {
  background-color: #930a28!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_green_light {
  background-color: #5fc64d!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_green_dark {
  background-color: #02a6ab!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_pink {
  background-color: #991e66!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_yellow {
  background-color: #f4da40!important;
  color: #264db6!important;
  fill: #264db6!important;
}

.base_theme_gray_dark {
  background-color: #333!important;
  color: #fff!important;
  fill: #fff!important;
}

.base_theme_gray_medium {
  background-color: #a7a8aa!important;
  color: #264db6!important;
  fill: #264db6!important;
}

.science_math .program_card_group {
  background-color: rgba(147,10,40,.85);
  color: #fff;
  fill: #fff;
}

.public_social_services .program_card_group {
  background-color: rgba(200,16,46,.85);
  color: #fff;
  fill: #fff;
}

.liberal_studies .program_card_group {
  background-color: rgba(7,47,79,.85);
  color: #fff;
  fill: #fff;
}

.health .program_card_group {
  background-color: rgba(38,77,182,.85);
  color: #fff;
  fill: #fff;
}

.engineering_technology .program_card_group {
  background-color: rgba(2,166,171,.85);
  color: #333;
  fill: #333;
}

.education .program_card_group {
  background-color: rgba(153,30,102,.85);
  color: #fff;
  fill: #fff;
}

.culinary_hospitality .program_card_group {
  background-color: rgba(95,198,77,.85);
  color: #333;
  fill: #333;
}

.creative_arts .program_card_group {
  background-color: rgba(239,86,45,.85);
  color: #333;
  fill: #333;
}

.business .program_card_group {
  background-color: rgba(244,218,64,.85);
  color: #333;
  fill: #333;
}

.type_program .academic_card_inner .academic_card_group {
  background-color: rgba(147,10,40,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.type_program .academic_card_inner .academic_card_label {
  color: #fff;
}

.academic_card_inner.science_math {
  background-color: rgba(147,10,40,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.science_math .academic_card_group {
  background-color: rgba(147,10,40,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.public_social_services {
  background-color: rgba(200,16,46,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.public_social_services .academic_card_group {
  background-color: rgba(200,16,46,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.liberal_studies {
  background-color: rgba(7,47,79,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.liberal_studies .academic_card_group {
  background-color: rgba(7,47,79,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.health {
  background-color: rgba(38,77,182,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.health .academic_card_group {
  background-color: rgba(38,77,182,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.engineering_technology {
  background-color: rgba(2,166,171,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.engineering_technology .academic_card_group {
  background-color: rgba(2,166,171,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.education {
  background-color: rgba(153,30,102,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.education .academic_card_group {
  background-color: rgba(153,30,102,.8)!important;
  color: #fff!important;
  fill: #fff!important;
}

.academic_card_inner.culinary_hospitality {
  background-color: rgba(95,198,77,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.culinary_hospitality .academic_card_group {
  background-color: rgba(95,198,77,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.creative_arts {
  background-color: rgba(239,86,45,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.creative_arts .academic_card_group {
  background-color: rgba(239,86,45,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.business {
  background-color: rgba(244,218,64,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.academic_card_inner.business .academic_card_group {
  background-color: rgba(244,218,64,.8)!important;
  color: #333!important;
  fill: #333!important;
}

.div-table {
  width: auto;
}

.div-table-row.div-table-head.header-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.div-table-sub {
  padding-left: 10px;
}

.header-text {
  position: relative;
  z-index: 8;
}

.div-table-head {
  background-color: #eee;
}

.div-table-row {
  display: -ms-flexbox;
  display: flex;
  width: auto;
  clear: both;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0 10px;
}

.div-table-col {
  float: left;
  display: table-column;
  width: 223px;
  margin: 10px 0;
}

a.mc3-home-button-inner {
  margin: 0 3px;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  text-decoration: none;
  cursor: pointer;
}

.test-circle {
  z-index: 2;
  width: 52px;
  height: 52px;
  margin-right: 10px;
  border-radius: 25px;
  background: #fff;
  float: left;
  box-shadow: 2px 2px 8px 1px rgba(0,0,0,.33);
}

.table-row-text {
  font-size: 18;
}

#help-desk>div.accordion {
  display: none;
}

@media only screen and (max-width:991px) {
  .crl_button_it,
  .crl_button_support {
    padding: 10px 0 10px 35px;
  }
}

@media only screen and (max-width:646px) {
  .div-table-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .table-row-text {
    font-size: 24px;
  }

  .div-table-col {
    width: 100%;
  }

  .mc3-home-button-inner {
    margin: 0 0 0 30px;
  }

  .crl_button_it,
  .crl_button_support {
    margin-right: 9vw;
    margin-left: -35px;
  }

  #help-desk>div.accordion {
    display: block;
  }

  #it-resources {
    display: none;
  }
}

@media screen and (max-width:500px) {
  #instruction-grouping {
    -ms-flex-direction: column!important;
    flex-direction: column!important;
  }

  #text-area {
    width: 100%!important;
  }

  #image-area {
    width: 100%!important;
  }
}

#instruction-grouping {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border: 1px solid #333;
  padding: 20px;
  margin: 20px 0;
}

#text-area {
  width: 50%;
  margin-right: 10px;
}

#image-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
}

#image-area img {
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 5px;
}

.event_label {
  background-color: #C8102E!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #c8102e;
  color: #fff;
  fill: #fff;
}

.arts_culture .event_label {
  background-color: #264db6!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #264db6;
  color: #fff;
  fill: #fff;
}

.admissions .event_label {
  background-color: #072f4f!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #072f4f;
  color: #fff;
  fill: #fff;
}

.academics .event_label {
  background-color: #C8102E!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #c8102e;
  color: #fff;
  fill: #fff;
}

.student_life .event_label {
  background-color: #930a28!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #930a28;
  color: #fff;
  fill: #fff;
}

.alumni .event_label {
  background-color: #5fc64d!important;
  color: #fff!important;
  fill: #fff!important;
}

.faculty_staff .event_label {
  background-color: #02a6ab!important;
  color: #fff!important;
  fill: #fff!important;
}

.college-wide .event_label {
  background-color: #991e66!important;
  color: #fff!important;
  fill: #fff!important;
}

.community_events .event_label {
  background-color: #f4da40!important;
  color: #264db6!important;
  fill: #264db6!important;
}

.athletics .event_label {
  background-color: #333!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #333;
  color: #fff;
  fill: #fff;
}

#month .today .day a.ep-view-event {
  color: #fff;
  font-weight: 700;
}

.filter_bar_option {
  border: 2px solid #264db6;
  border-radius: 3px;
}

.feature_filter_select_inner_item {
  display: block;
  margin: 10px 0;
}

@media only screen and (min-width:992px) {
  .feature_filter_select_inner_item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0;
  }
}

@media only screen and (min-width:992px) {
  .feature_filter_select_inner_item:not(:last-child) {
    margin-right: 20px;
  }
}

#month .day a.ep-view-event {
  color: #C8102E;
  text-decoration: underline;
}

.typography a#month .day a.ep-view-event {
  box-shadow: none;
}

table#month {
  width: 100%;
}

.EventFinder .feature_filter_block_inner {
  padding-top: 20px;
}

.typography .accordion_item_title {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.33333333;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 20px 70px 20px 20px;
}

@media only screen and (min-width:768px) {
  .typography .accordion_item_title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

.typography .accordion_item_title:focus {
  outline: 0;
}

.accordion_item.is_open .typography .accordion_item_title {
  outline: 0;
}

@media print {
  .typography .accordion_item_title {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width:992px) {
  .typography .accordion_item_title {
    padding: 32px 70px 32px 30px;
  }

  .accordion.theme_program .typography .accordion_item_title {
    padding-left: 37px;
    padding-right: 37px;
  }
}

.accordion.theme_program_alt .typography .accordion_item_title {
  padding-left: 0;
  padding-right: 30px;
}

.program_set {
  max-width: 72vw;
}

.program_card.area_of_study {
  width: 23vw!important;
  height: 23vw;
  padding-bottom: 30%;
  margin-bottom: 10px!important;
  float: left;
}

@media screen and (min-width:1585px) {
  .program_card.area_of_study {
    max-width: 19vw!important;
    max-height: 19vh;
  }
}

.csstransforms3d .program_card.area_of_study .program_card_group {
  transform: translate3d(0,0,0);
}

@media screen and (max-width:700px) {
  .program_card.area_of_study .program_card_group {
    padding: 5px!important;
  }
}

.program_card.area_of_study .program_card_group .program_card_title {
  text-align: center;
  font-size: 32px;
  font-weight: 600!important;
  letter-spacing: 4px;
}

@media screen and (max-width:700px) {
  .program_card.area_of_study .program_card_group .program_card_title {
    padding: 2px!important;
    font-size: 16px;
  }
}

@media screen and (max-width:768px) {
  .program_card.area_of_study .program_card_group .program_card_title {
    letter-spacing: normal;
  }
}

.program_card_header hr {
  display: none;
}

.program_card_link:focus .program_card_header hr,
.program_card_link:hover .program_card_header hr {
  display: block;
}

.program_card_description {
  padding-bottom: 20px;
}

.admission_card:nth-child(3n) {
  background-color: #264db6!important;
}

.admission_card:nth-child(3n+1) {
  background-color: #C8102E!important;
}

.admission_card:nth-child(3n+1) .admission_card_link:focus,
.admission_card:nth-child(3n+1) .admission_card_link:hover {
  background-color: #5fc64d!important;
}

.admission_card:nth-child(3n+2) {
  background-color: #333!important;
}

.admission_card:nth-child(3n+2) .admission_card_link:focus,
.admission_card:nth-child(3n+2) .admission_card_link:hover {
  background-color: #5fc64d!important;
}

.facet_display {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
}

.facet_display .active_facets {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 10px 0;
}

.facet_display .crl_button_1.clear_all {
  cursor: pointer;
  background-color: #a7a8aa;
}

@media only screen and (max-width:991px) {
  .facet_display .crl_button_1.clear_all {
    margin: 16px 0;
  }
}

.facet_display .crl_button_1.clear_all .icon {
  padding-left: 8px;
  padding-left: .5rem;
}

.facet_display .crl_button_1.clear_all .symbol {
  width: 12px;
  height: 12px;
  fill: #333;
  vertical-align: baseline;
}

.filter_facet_label {
  display: block;
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.71428571;
  font-weight: 400;
  margin: 0 0 10px;
}

@media only screen and (min-width:768px) {
  .filter_facet_label {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.77777778;
  }
}

@media only screen and (min-width:1200px) {
  .filter_facet_label {
    display: inline-block;
    margin-right: 18px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width:992px) {
  .filter_facet_label {
    margin-right: 18px;
  }
}

@media only screen and (max-width:1199px) {
  .filter_facet_label {
    padding-top: 10px;
  }
}

.crl_button_7 {
  cursor: pointer;
  display: inline-block;
  margin-left: 3rem;
  position: relative;
}

@media only screen and (min-width:992px) {
  .crl_button_7:nth-of-type(1) {
    margin-left: 0;
  }
}

.typography a.crl_button_7 {
  box-shadow: none;
}

.crl_button_7 .label {
  border-bottom: solid #a7a8aa 1px;
}

.crl_button_7 .icon {
  padding-left: 8px;
  padding-left: .5rem;
}

.crl_button_7 .symbol {
  width: 12px;
  height: 12px;
  fill: #02A6AB;
  vertical-align: baseline;
}

@media only screen and (max-width:991px) {
  .crl_button_7 {
    display: table;
  }
}

a.crl_button_4 .symbol_close,
button.crl_button_4 .symbol_close {
  width: 12px;
  height: 12px;
}

.alpha_nav_list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.WorkforceFinder .feature_filter_block {
  background: 0 0;
  padding: 0;
}

.js-course-table-data a {
  color: #C8102E;
}

.SmartBoard_Container {
  width: 100%;
  display: block;
  position: relative;
  background: #F4DA40;
  color: #333;
  transform: translateZ(0);
  z-index: 200;
}

.fs-navigation-lock .SmartBoard_Container {
  display: none;
}

.typography a.crl_button_1,
.typography a.crl_button_2,
.typography a.crl_button_3,
.typography a.crl_button_4,
.typography a.crl_button_5,
.typography a.crl_button_6,
.typography a.crl_button_7,
.typography a.crl_button_8 {
  box-shadow: none;
}

.typography .text_message_enroll table tr,
.typography .text_message_login table tr {
  background-color: transparent;
}

.typography .text_message_enroll table td,
.typography .text_message_login table td {
  padding: 0;
}

.typography .text_message_enroll table td .fs-checkbox,
.typography .text_message_login table td .fs-checkbox {
  display: inline-block;
}

.typography .text_message_enroll table p,
.typography .text_message_login table p {
  margin: 0;
  padding: 0;
}

.typography .text_message_enroll table .fs-checkbox-label,
.typography .text_message_login table .fs-checkbox-label {
  display: inline;
}

.area_of_study_grid .program_card.area_of_study {
  margin: 0!important;
  margin-bottom: 10px!important;
}

.area_of_study_grid .flex-grid {
  display: -ms-flexbox;
  display: flex;
}

.area_of_study_grid .flex-grid .col {
  -ms-flex: 1;
  flex: 1;
}

.area_of_study_grid .flex-grid-thirds {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.area_of_study_grid .flex-grid-thirds .col {
  width: 32%!important;
}

@media (max-width:400px) {
  .area_of_study_grid .flex-grid,
  .area_of_study_grid .flex-grid-thirds {
    display: block;
  }

  .area_of_study_grid .flex-grid .col,
  .area_of_study_grid .flex-grid-thirds .col {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.area_of_study_grid .flex-grid {
  margin: 0 0 20px 0;
}

.floating-box {
  font-family: proxima-nova,"Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  font-size: 1.3125rem;
  display: none;
  position: fixed;
  right: -118px;
  top: 25%;
  z-index: 2000;
  transition-duration: .3s;
  overflow: auto;
  overflow: initial;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2);
}

.floating-box.open {
  right: 0;
}

@media (max-height:800px) {
  .floating-box {
    top: 200px;
  }
}

@media only screen and (max-width:991px),print {
  .floating-box {
    display: none!important;
    visibility: hidden;
  }
}

.floating-box ul {
  list-style: none;
  margin: 0 0 10px -25px;
}

.floating-box ul li {
  display: block;
  margin-right: 1em;
  padding-top: .5em;
}

.floating-box ul li svg.symbol {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 10px;
  fill: #fff;
}

.floating-box ul li a {
  color: #fff;
}

.style_guide_icon .symbol {
  width: 15px;
  height: 15px;
  color: #000;
  fill: #000;
}

._ba_manual {
  display: none;
}

.pointer {
  cursor: pointer;
}

#__ba_panel {
  display: none;
}

svg.symbol {
  width: 16px;
  height: 16px;
}

#challenger-countdown {
  display: none;
}

#challenger-countdown.challenger-countdown-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
}

#challenger-countdown.challenger-countdown-flex .challenger-countdown-number {
  font-size: 4em;
  font-weight: 700;
}

#challenger-countdown.challenger-countdown-flex .challenger-countdown-number#challenger-countdown-seconds {
  font-size: 2.5em;
  padding-bottom: .2em;
}

@media only screen and (max-width:767px) {
  #challenger-countdown.challenger-countdown-flex .challenger-countdown-number#challenger-countdown-seconds {
    font-size: 1.5em;
    padding-bottom: .12em;
  }
}

#challenger-countdown.challenger-countdown-flex .challenger-countdown-words {
  color: #333;
  font-size: 1em;
}

@media only screen and (max-width:767px) {
  #challenger-countdown.challenger-countdown-flex .challenger-countdown-number {
    font-size: 2em;
  }

  #challenger-countdown.challenger-countdown-flex .challenger-countdown-words {
    font-size: .6em;
  }
}

#challenger-countdown-days {
  color: #264db6;
}

#challenger-countdown-hours {
  color: #02A6AB;
}

#challenger-countdown-minutes {
  color: #5fc64d;
}

#challenger-countdown-seconds {
  color: #991e66;
}

.symbol.white {
  fill: #fff;
}

.symbol.red_brand {
  fill: #C8102E;
}

.symbol.black {
  fill: #000;
}

.symbol.blue_dark {
  fill: #072f4f;
}

.symbol.teal {
  fill: #00b2a9;
}

.symbol.purple {
  fill: #9450b2;
}

.symbol.yellow {
  fill: #f4da40;
}

.symbol.orange {
  fill: #ef562d;
}

@media screen and (max-width:768px) {
  .theme_kb img {
    max-width: 90%;
  }
}

.main_nav_lg .main_nav_link:hover {
  text-decoration: underline 2px #C8102E;
  transition: all .25s ease;
}

.main_nav_lg .main_nav_link {
  text-decoration: underline 2px #fff;
  text-underline-offset: 7px;
}

.home_new_feature .fs-carousel-pagination {
  bottom: 25px;
}

.home_feature_new_wrapper .container {
  margin: 0;
}

@media only screen and (min-width:768px) {
  .featured_events_title {
    -ms-flex: 1 2 auto;
    flex: 1 2 auto;
  }
}

.featured_events_all_link {
  margin: 8px 5px 5px 0;
}

#optional-events-btn {
  background: #265db6!important;
}

#optional-events-btn:hover {
  background: #333!important;
}

img#full-width-img {
  max-width: 100%!important;
}

.flex-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: start;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  gap: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-item-align: center;
  align-self: center;
}

.grid_card {
  padding: 10px;
}

.grid_view_3x3 .grid_card {
  width: 30%;
}

.grid_view_4x4 .grid_card {
  width: 22%;
}

.grid_card .grid_card_header {
  opacity: 100%;
}

.grid_card .grid_card_header .grid_card_description {
  opacity: 100%;
}

.grid_card .grid_card_thumbnail {
  width: 100%;
  max-width: 100%;
}

.grid_card .grid_card_header {
  position: absolute;
  bottom: 0;
  left: 50%;
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
  width: 100%;
  padding: 5px;
  text-align: center;
  line-height: 1.2rem;
}

.theme_transparent .grid_card .grid_card_header {
  background-color: rgba(200,16,46,0);
  fill: #fff;
  color: #fff;
}

.theme_red_light .grid_card .grid_card_header {
  background-color: rgba(200,16,46,.6);
  color: #fff;
  fill: #fff;
}

.theme_red_dark .grid_card .grid_card_header {
  background-color: rgba(147,10,40,.6);
  color: #fff;
  fill: #fff;
}

.theme_gray_dark .grid_card .grid_card_header {
  background-color: rgba(51,51,51,.6);
  color: #fff;
  fill: #fff;
}

.theme_accent_teal .grid_card .grid_card_header {
  background-color: rgba(2,166,171,.6);
  color: #333;
  fill: #333;
}

.theme_bright_blue .grid_card .grid_card_header {
  background-color: rgba(38,77,182,.6);
  color: #fff;
  fill: #fff;
}

.theme_accent_pink .grid_card .grid_card_header {
  background-color: rgba(153,30,102,.6);
  color: #fff;
  fill: #fff;
}

.theme_bright_orange .grid_card .grid_card_header {
  background-color: rgba(239,86,45,.6);
  color: #333;
  fill: #333;
}

.theme_bright_yellow .grid_card .grid_card_header {
  background-color: rgba(244,218,64,.6);
  color: #333;
  fill: #333;
}

.theme_dark_blue .grid_card .grid_card_header {
  background-color: rgba(7,47,79,.6);
  color: #fff;
  fill: #fff;
}

.theme_medium_gray .grid_card .grid_card_header {
  background-color: rgba(167,168,170,.6);
  color: #333;
  fill: #333;
}

.theme_light_gray .grid_card .grid_card_header {
  background-color: rgba(243,244,245,.6);
  color: #333;
  fill: #333;
}

.theme_bright_green .grid_card .grid_card_header {
  background-color: rgba(95,198,77,.6);
  color: #333;
  fill: #333;
}

.grid_card .grid_card_inner {
  position: relative;
}

.theme_transparent .grid_card:hover .grid_card_header {
  background-color: rgba(200,16,46,0);
  fill: #fff;
  color: #fff;
}

.theme_red_light .grid_card:hover .grid_card_header {
  background-color: #C8102E!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #c8102e;
  color: #fff;
  fill: #fff;
}

.theme_red_dark .grid_card:hover .grid_card_header {
  background-color: #930a28!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #930a28;
  color: #fff;
  fill: #fff;
}

.theme_gray_dark .grid_card:hover .grid_card_header {
  background-color: #333!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #333;
  color: #fff;
  fill: #fff;
}

.theme_accent_teal .grid_card:hover .grid_card_header {
  background-color: #02a6ab;
  color: #333;
  fill: #333;
}

.theme_bright_blue .grid_card:hover .grid_card_header {
  background-color: #264db6!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #264db6;
  color: #fff;
  fill: #fff;
}

.theme_accent_pink .grid_card:hover .grid_card_header {
  background-color: #991e66;
  color: #fff;
  fill: #fff;
}

.theme_bright_orange .grid_card:hover .grid_card_header {
  background-color: #ef562d;
  color: #333;
  fill: #333;
}

.theme_bright_yellow .grid_card:hover .grid_card_header {
  background-color: #f4da40;
  color: #333;
  fill: #333;
}

.theme_dark_blue .grid_card:hover .grid_card_header {
  background-color: #072f4f!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #072f4f;
  color: #fff;
  fill: #fff;
}

.theme_medium_gray .grid_card:hover .grid_card_header {
  background-color: #a7a8aa!important;
  color: #264db6!important;
  fill: #264db6!important;
  background-color: #a7a8aa;
  color: #333;
  fill: #333;
}

.theme_light_gray .grid_card:hover .grid_card_header {
  background-color: #f3f4f5;
  color: #333;
  fill: #333;
}

.theme_bright_green .grid_card:hover .grid_card_header {
  background-color: #5fc64d;
  color: #333;
  fill: #333;
}

@media screen and (max-width:550px) {
  .grid_card:hover .grid_card_header {
    height: 100%;
  }
}